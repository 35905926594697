import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Paper } from '@mui/material';
import axios from 'axios';
import Header1 from '../Header/Header1';
import Footer from '../Footer/Footer';


const API_URL = process.env.REACT_APP_API_URL;




function CreateSubscriptions({ userId, onLogout }) {
  const [formData, setFormData] = useState({
    plan_number: '',
    plan_name: '',
    plan_type: '',
    sections_included: '',
    post_count: '',
    request_count: '',
    price: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();


    axios
      .post(`${API_URL}/create_subscriptions/${userId}/`, formData)
      .then((response) => {
        showAlert()
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  };

  const showAlert = () => {
    const alertDiv = document.createElement('div');
    alertDiv.textContent = 'Subscription created successfully';
    alertDiv.style.position = 'fixed';
    alertDiv.style.top = '10px';
    alertDiv.style.left = '50%';
    alertDiv.style.transform = 'translateX(-50%)';
    alertDiv.style.padding = '10px 20px';
    alertDiv.style.backgroundColor = 'green';
    alertDiv.style.color = 'white';
    alertDiv.style.borderRadius = '5px';
    alertDiv.style.zIndex = '9999';
    alertDiv.style.fontSize = '16px';

    document.body.appendChild(alertDiv);

    // Auto remove the alert after 3 seconds
    setTimeout(() => {
      alertDiv.remove();
    }, 3000);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'black' }}>
      {/* Header */}
      <Header1 userId={userId} onLogout={onLogout} />

      {/* Main Content Area */}
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px', marginBottom: '40px' }}>
        <Paper
          sx={{
            padding: 4,
            width: '100%',
            maxWidth: 700,
            background: 'linear-gradient(45deg, #6a1b9a, #2196f3)',
            borderRadius: 2,
            boxShadow: 8,
          }}
        >
          {/* Title Inside Paper Box */}
          <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
            <Typography variant="h4" color="white" fontWeight="bold">
              CREATE SUBSCRIPTION
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Plan Number */}
              {/* <Grid item xs={12} md={6}>
                <TextField
                  label="Plan Number"
                  variant="outlined"
                  name="plan_number"
                  value={formData.plan_number}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid> */}

              {/* Plan Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Plan Name"
                  variant="outlined"
                  name="plan_name"
                  value={formData.plan_name}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Plan Type */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Plan Type"
                  variant="outlined"
                  name="plan_type"
                  value={formData.plan_type}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Sections Included */}
              {/* <Grid item xs={12} md={6}>
                <TextField
                  label="Sections Included"
                  variant="outlined"
                  type="number"
                  name="sections_included"
                  value={formData.sections_included}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid> */}

              {/* Post Count */}
              {/* <Grid item xs={12} md={6}>
                <TextField
                  label="Number of Posts"
                  variant="outlined"
                  type="number"
                  name="post_count"
                  value={formData.post_count}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid> */}

              <Grid item xs={12} md={6}>
                <TextField
                  label="Number of Posts"
                  variant="outlined"
                  type="number" // Number input
                  name="post_count"
                  value={formData.post_count}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    inputProps: {
                      sx: {
                        '::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0 },
                        '::-webkit-inner-spin-button': { '-webkit-appearance': 'none', margin: 0 },
                        'appearance': 'textfield', // For Firefox and others
                      },
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Request Count */}
              {/* <Grid item xs={12} md={6}>
                <TextField
                  label="Number of Requests"
                  variant="outlined"
                  type="number"
                  name="request_count"
                  value={formData.request_count}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid> */}

              {/* Price */}
              {/* <Grid item xs={12}>
                <TextField
                  label="Price"
                  variant="outlined"
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  fullWidth
                  required
                  inputProps={{ step: '0.01' }}
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid> */}

              <Grid item xs={12}>
                <TextField
                  label="Price"
                  variant="outlined"
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  fullWidth
                  required
                  inputProps={{ step: '0.01' }} // Allows decimal values
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    inputProps: {
                      sx: {
                        '::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0 },
                        '::-webkit-inner-spin-button': { '-webkit-appearance': 'none', margin: 0 },
                        'appearance': 'textfield', // For Firefox and others
                      },
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>


              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    background: 'linear-gradient(45deg, #8e24aa, #2196f3)',
                    padding: '12px',
                    fontSize: '16px',
                    color: 'white',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #7b1fa2, #1976d2)',
                      boxShadow: 8,
                    },
                  }}
                >
                  Create Subscription
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>

      {/* Footer */}
      {/* <Footer/> */}
    </Box>
  );
}

export default CreateSubscriptions;
