import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, Alert, Button, Card, CardContent, CardActions, Grid } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

function ReportedPosts() {
  const [reportedPosts, setReportedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetching reported posts from the API
  useEffect(() => {
    const fetchReportedPosts = async () => {
      try {
        const response = await axios.get(`${API_URL}/list_reported_posts/`);
        setReportedPosts(response.data);  // Assuming response data contains user_name and post_title
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch reported posts.');
        setLoading(false);
      }
    };

    fetchReportedPosts();
  }, []);

  // Function to mark the report as inactive
  const handleReportInactive = async (reportId) => {
    try {
      await axios.patch(`${API_URL}/report_inactive/${reportId}/`);
      window.alert('Report Deleted Successfully')
      window.location.reload()
      
      // Update the UI to reflect the change
    //   setReportedPosts(prevPosts => prevPosts.map(post =>
    //     post.id === reportId ? { ...post, is_active: false } : post
    //   ));
    } catch (err) {
      setError('Failed to mark the report as inactive.');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        Reported Posts
      </Typography>

      {reportedPosts.length === 0 ? (
        <Typography>No reported posts available.</Typography>
      ) : (
        <Grid container spacing={2}>
          {reportedPosts.map((reportedPost) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={reportedPost.id}>
              <Card sx={{ borderRadius: '8px', boxShadow: 3, display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    Post Name: {reportedPost.topic_name}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    <strong>User Name:</strong> {reportedPost.user_name} {reportedPost.user_surname}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleReportInactive(reportedPost.id)}  // Call the mark inactive function
                    sx={{
                      width: '100px',  // Set a fixed width for the button for better alignment
                      padding: '8px',
                      fontWeight: 'bold',
                      backgroundColor: reportedPost.is_active ? 'blue' : 'gray',  // Make inactive buttons gray
                    }}
                  >
                    Mark as Inactive
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default ReportedPosts;
