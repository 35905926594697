import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  createTheme,
  ThemeProvider,
  Card,
  CardContent,
  Snackbar,
  FormHelperText,
} from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

function EmvVerifiedSection({ userId }) {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([])

  const [formData, setFormData] = useState({
    user_id: userId,
    aadhar: '',
    pan: '',
    bank_account: '',
    aadhar_file: null,
    pan_file: null,
    bank_account_file: null,
    ifsc: '',
    interview_slots: [],
    terms_accepted: false,
  });

  const validateAadhar = (aadhar) => {
    // Aadhaar number should be a 12-digit number
    const regex = /^[2-9]{1}[0-9]{11}$/;
    return regex.test(aadhar);
  };

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;
    
    if (type === 'file') {
      const file = files[0];
      
      // Check if the file exists
      if (file) {
        const fileSizeInKB = file.size / 1024; // Convert file size from bytes to KB
  
        // Validate file size (500 KB)
        if (fileSizeInKB > 200) {
          setError(['File size should not exceed 500 KB']);
          event.target.value = ''; // Clear the file input
          return;
        }
  
        // If file is valid, update the formData
        setFormData({
          ...formData,
          [name]: file, // Save the file object
        });
      }
    } else {
      // For non-file input types (checkbox, text, etc.)
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  
    // Clear any errors when input changes
    setError([]);
  };
  


  const validatePan = (pan) => {
    // PAN number should be of format: AAAAA9999A (5 alphabets, 4 digits, 1 alphabet)
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return regex.test(pan);
  };


  const validateBankAccount = (bankAccount) => {
    // Bank account number should be a 10-18 digit number
    const regex = /^\d{10,18}$/;
    return regex.test(bankAccount);
  };


  const validateIfsc = (ifsc) => {
    // IFSC code should be in the format: XXXX0XXXXX (4 letters, a 0, and 6 digits)
    const regex = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
    return regex.test(ifsc);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();


    const errors = [];


    if (!validateAadhar(formData.aadhar)) {
      errors.push('Invalid Aadhaar number. It should be a 12-digit number.');
    }


    if (!validatePan(formData.pan)) {
      errors.push('Invalid PAN number. It should be in the format AAAAA9999A.');
    }


    if (!validateBankAccount(formData.bank_account)) {
      errors.push('Invalid Bank Account number. It should be between 10 to 18 digits.');
    }


    if (!validateIfsc(formData.ifsc)) {
      errors.push('Invalid IFSC code. It should be in the format XXXX0XXXXX.');
    }


    // If there are errors, show them and don't submit the form
    if (errors.length > 0) {
      setError(errors); // Update error state to display messages
      return;
    }


    setLoading(true);


    const formDataToSubmit = new FormData();
    for (const key in formData) {
      if (formData[key] instanceof File) {
        formDataToSubmit.append(key, formData[key]);  // Append file fields
      } else if (Array.isArray(formData[key])) {
        formDataToSubmit.append('interview_slots', JSON.stringify(formData[key])); // Serialize array to JSON string
      } else {
        formDataToSubmit.append(key, formData[key]);  // Handle other fields
      }
    }


    try {
      const response = await axios.post(`${API_URL}/create_user_verification/?user_id=${userId}`, formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });


      if (response.data.success) {
        showAlert();
        window.location.reload();
        //    setFormData(response.data.data);  // Update the form with the submitted data
        //    setSubmittedData(response.data.data);  // Store the data after successful submission
      } else {
        alert('Verification failed. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again later.');
    }

    setLoading(false);
  };

  const showAlert = () => {
    const alertDiv = document.createElement('div');
    alertDiv.textContent = 'Applied for verification successfully!';
    alertDiv.style.position = 'fixed';
    alertDiv.style.top = '10px';
    alertDiv.style.left = '50%';
    alertDiv.style.transform = 'translateX(-50%)';
    alertDiv.style.padding = '10px 20px';
    alertDiv.style.backgroundColor = 'green';
    alertDiv.style.color = 'white';
    alertDiv.style.borderRadius = '5px';
    alertDiv.style.zIndex = '9999';
    alertDiv.style.fontSize = '16px';

    document.body.appendChild(alertDiv);

    // Auto remove the alert after 3 seconds
    setTimeout(() => {
        alertDiv.remove();
    }, 3000);
};

  return (
    <div>
      {/* Title */}
                         <Box sx={{ textAlign: 'center'}}>
                         <Typography
  variant="h2"
  sx={{
    fontWeight: 'bold',
    color: 'white',
    fontSize: {
      xs: '1.5rem', // Font size for extra small screens (mobile)
      sm: '2rem',   // Font size for small screens (tablet)
      md: '2rem',   // Font size for medium screens (desktop)
      lg: '2rem',   // Font size for large screens (larger desktop)
    },
  }}
>
  Stage 2: KYC verification
</Typography>

                                        {/* <Typography variant="subtitle1" sx={{ color: 'white', mt: 1 }}>
                                            Unlock premium features and resources tailored for your needs
                                        </Typography> */}
                                    </Box>
    
                                    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          maxWidth: '900px', // Maximum width for larger screens
          width: '100%', // Ensure it takes up full width on smaller screens
          margin: '0 auto', // Centers the form horizontally
          padding: { xs: 2, sm: 3 }, // Responsive padding
        }}
      >
        {/* Aadhar Number Field */}
        <TextField
          fullWidth
          label="Aadhar Number"
          name="aadhar"
          value={formData.aadhar}
          onChange={handleChange}
          margin="normal"
          required
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{ style: { color: '#ffffff' } }}
        />
        {/* Display error if Aadhaar is invalid */}
        {error && error.some((e) => e.includes('Aadhaar')) && (
          <Typography color="error" variant="body2">
            {error.find((e) => e.includes('Aadhaar'))}
          </Typography>
        )}
        <FormHelperText sx={{ color: 'text.secondary' }}>
          Upload your Aadhar copy in Pdf format. Max allowed file size: 200KB
        </FormHelperText>
        <input
          type="file"
          name="aadhar_file"
          onChange={handleChange}
          accept="application/pdf,image/*"
          required
        />

        {/* PAN Number Field */}
        <TextField
          fullWidth
          label="Pan Number"
          name="pan"
          value={formData.pan}
          onChange={handleChange}
          margin="normal"
          required
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{ style: { color: '#ffffff' } }}
        />
        {/* Display error if PAN is invalid */}
        {error && error.some((e) => e.includes('PAN')) && (
          <Typography color="error" variant="body2">
            {error.find((e) => e.includes('PAN'))}
          </Typography>
        )}
        <FormHelperText sx={{ color: 'text.secondary' }}>
        Upload your Pan card copy in Pdf format. Max allowed file size: 200KB
        </FormHelperText>
        <input
          type="file"
          name="pan_file"
          onChange={handleChange}
          accept="application/pdf,image/*"
          required
        />

        {/* Bank Account Number Field */}
        <TextField
          fullWidth
          label="Bank Account Number"
          name="bank_account"
          value={formData.bank_account}
          onChange={handleChange}
          margin="normal"
          required
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{ style: { color: '#ffffff' } }}
        />
        {/* Display error if Bank Account is invalid */}
        {error && error.some((e) => e.includes('Bank Account')) && (
          <Typography color="error" variant="body2">
            {error.find((e) => e.includes('Bank Account'))}
          </Typography>
        )}
        <FormHelperText sx={{ color: 'text.secondary' }}>
        Upload your Bank Passbook copy in Pdf format. Max allowed file size: 200KB
        </FormHelperText>
        <input
          type="file"
          name="bank_account_file"
          onChange={handleChange}
          accept="application/pdf,image/*"
          required
        />

        {/* IFSC Code Field */}
        <TextField
          fullWidth
          label="IFSC Code"
          name="ifsc"
          value={formData.ifsc}
          onChange={handleChange}
          margin="normal"
          required
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{ style: { color: '#ffffff' } }}
        />
        {/* Display error if IFSC is invalid */}
        {error && error.some((e) => e.includes('IFSC')) && (
          <Typography color="error" variant="body2">
            {error.find((e) => e.includes('IFSC'))}
          </Typography>
        )}

        {/* Terms and Conditions */}
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.terms_accepted}
              onChange={handleChange}
              name="terms_accepted"
              sx={{
                color: '#ffffff', // Default color when unchecked
                '&.Mui-checked': {
                  color: '#ffffff', // Change the checkbox color when checked (border and check mark)
                },
                '& .MuiSvgIcon-root': {
                  borderColor: 'white', // Set the border color to white
                },
                '&:hover': {
                  backgroundColor: 'transparent', // Remove hover effect background
                },
              }}
            />
          }
          label={
            <span style={{ color: '#ffffff' }}>
              I agree to the{' '}
              <Link to="/terms-of-service" style={{ textDecoration: 'none', color: '#1E90FF' }}>
                Terms of Service
              </Link>
              ,{' '}
              <Link to="/privacy-policy" style={{ textDecoration: 'none', color: '#1E90FF' }}>
                Privacy Policy
              </Link>
              , and{' '}
              <Link to="/payments-policy" style={{ textDecoration: 'none', color: '#1E90FF' }}>
                Payment Policies
              </Link>
            </span>
          }
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!formData.terms_accepted || loading} // Disable if not checked or if loading
          sx={{
            marginTop: 2,
            backgroundColor: '#90caf9',
            '&:hover': {
              backgroundColor: '#64b5f6',
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </Box>
    </form></div>
  )
}

export default EmvVerifiedSection
