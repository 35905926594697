import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, List, ListItem, ListItemText, Divider, IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function PostingGuidelines({userId,loggedIn, onLogout}) {
  const navigate = useNavigate();
        const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          };
    
        const handleLogout = () => {
            onLogout(); // Call the passed logout function
            navigate('/login'); // Navigate to login page after logout
          };
  return (
    <div> {loggedIn ? (
      <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
    ) : (
      <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
    )}
    <Container maxWidth="md" sx={{ padding: '20px', mt:10 }}>
      <Typography variant="h3" textAlign={'center'} gutterBottom>
        Posting Guidelines
      </Typography>
      <Typography variant="body1" paragraph>
        We value a positive and constructive environment for all users. To ensure that posts are beneficial and aligned with community standards, we’ve outlined the following posting guidelines. Adherence to these guidelines will help maintain a respectful and engaging experience for all.
      </Typography>

      <Typography variant="h5" gutterBottom>
        1. Content Quality
      </Typography>
      <Typography variant="body1" paragraph>
        All posts should meet the following quality standards:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Content should be clear, accurate, and well-structured." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Posts should be relevant to the category or topic in which they are published." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Provide valuable information that enhances the knowledge-sharing experience for others." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Avoid using excessive jargon or ambiguous language that may confuse readers." />
        </ListItem>
      </List>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        2. No Plagiarism
      </Typography>
      <Typography variant="body1" paragraph>
        All content must be original and should not plagiarize any third-party sources. Properly attribute any external resources or quotes used in your post, and ensure you have the right to share them.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        3. Use of Appropriate Language
      </Typography>
      <Typography variant="body1" paragraph>
        Ensure that your post is written using respectful and appropriate language. Avoid:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Profanity, offensive language, or derogatory comments." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Personal attacks or insults directed at individuals or groups." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Discriminatory or biased language based on race, gender, religion, or any other personal attributes." />
        </ListItem>
      </List>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        4. No Spam
      </Typography>
      <Typography variant="body1" paragraph>
        Spamming or posting irrelevant or repetitive content is strictly prohibited. This includes:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Posting the same content multiple times in different sections." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Advertising or promoting products, services, or websites unrelated to the platform's purpose." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Excessive self-promotion or solicitation." />
        </ListItem>
      </List>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        5. No Harassment or Bullying
      </Typography>
      <Typography variant="body1" paragraph>
        We have a zero-tolerance policy for harassment, bullying, or any form of abusive behavior. All posts should contribute to a positive and welcoming environment for everyone. Any post that targets individuals or groups with harmful intentions will be removed immediately.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        6. Compliance with Laws and Regulations
      </Typography>
      <Typography variant="body1" paragraph>
        All posts must comply with applicable local, national, and international laws. This includes:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Refraining from posting illegal content, including but not limited to copyrighted material, defamation, or content that promotes illegal activities." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Respecting intellectual property rights and not sharing pirated or unauthorized content." />
        </ListItem>
      </List>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        7. No Sexual Content or Explicit Material
      </Typography>
      <Typography variant="body1" paragraph>
        Posts containing sexual content, explicit images, or material that is obscene in nature are strictly prohibited. This includes any inappropriate references, images, or videos. We aim to maintain a respectful space for all users.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        8. Respect Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Do not share personal information about others without their consent. This includes:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Personal addresses, phone numbers, or email addresses." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Confidential information related to individuals or organizations." />
        </ListItem>
      </List>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        9. Correcting Errors
      </Typography>
      <Typography variant="body1" paragraph>
        If you realize that your post contains errors or incorrect information, please edit it as soon as possible or notify others in the comments. We encourage all users to be transparent and responsible with the content they share.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        10. Moderation and Removal of Posts
      </Typography>
      <Typography variant="body1" paragraph>
        Our platform has a moderation system in place to ensure that all posts adhere to the guidelines. Posts that violate these guidelines may be removed, and the user may receive a warning or suspension based on the severity of the violation.
      </Typography>

      <Typography variant="body1" paragraph>
        We encourage you to report any content that violates these guidelines using the reporting feature provided on the platform.
      </Typography>
    </Container>
    <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
   <Footer2/>
    </div>
  );
}

export default PostingGuidelines;
