import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    CircularProgress,
    createTheme,
    ThemeProvider,
    Card,
    CardContent,
    Snackbar
} from '@mui/material';
import axios from 'axios';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Fade } from '@mui/material';
import SubmittedData from './SubmittedData';
import EmvVerifiedSection from './EmvVerifiedSection';
import EmailVerifications from './EmailVerifications';

const API_URL = process.env.REACT_APP_API_URL;

const SectionContainer = styled(Box)({
    background: '#000000',
    borderRadius: '20px',
    padding: '20px', // Default padding for larger screens
    marginBottom: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    color: '#ffffff',

    // Mobile first approach
    '@media (max-width: 600px)': {
        padding: '16px', // Smaller padding for mobile screens
        marginBottom: '15px', // Adjust margin for mobile
    },

    '@media (min-width: 600px) and (max-width: 960px)': {
        padding: '24px', // Slightly larger padding for tablets or medium screens
    },

    '@media (min-width: 960px)': {
        padding: '30px', // Larger padding for desktops and larger screens
    },
});

const CustomInputLabel = styled(InputLabel)({
    color: 'rgba(255, 255, 255, 0.7)',
});

const CustomButton = styled(Button)({
    backgroundColor: '#E90FF',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '6px',
    '&:hover': {
        backgroundColor: '#E90FF',
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#f48fb1',
        },
        background: {
            default: '#000000',
            paper: '#000000',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0bec5',
        },
    },
});

const Verification = ({ onLogout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');

    const [formData, setFormData] = useState({
        user_id: userId,
        aadhar: '',
        pan: '',
        bank_account: '',
        aadhar_file: null,
        pan_file: null,
        bank_account_file: null,
        ifsc: '',
        interview_slots: [],
        terms_accepted: false,
    });

    const [dataExists, setDataExists] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [role, setRole] = useState('');
    const [error, setError] = useState([]);
    


    // useEffect(() => {
    //     const savedRoute = localStorage.getItem('currentRoute');
    //     console.log('savedRoute', savedRoute);

    //     // If there's a saved route and it's not the same as the current location,
    //     // navigate to it (only once per click/navigation)
    //     if (savedRoute && savedRoute !== location.pathname + location.search) {
    //         navigate(savedRoute);
    //     }
    // }, [location, navigate]); // Only run when location changes

    // // This effect runs every time the location changes and saves the current route to localStorage
    // useEffect(() => {
    //     const currentPath = location.pathname + location.search;  // includes any query parameters
    //     // Avoid saving the route again if it's the same as the last one
    //     const lastSavedRoute = localStorage.getItem('currentRoute');
    //     if (currentPath !== lastSavedRoute) {
    //         localStorage.setItem('currentRoute', currentPath);
    //         console.log('Route saved to localStorage:', currentPath);
    //     }
    // }, [location]);

    // useEffect(() => {
    //     // Check if a route is saved in localStorage
    //     const savedRoute = localStorage.getItem('currentRoute');
    //     console.log('savedRoute', savedRoute);

    //     if (savedRoute) {
    //         // Navigate to the saved route when the app starts
    //         navigate(savedRoute);
    //     }
    // }, [navigate]);

    // useEffect(() => {
    //     // Save the current route (pathname + search) in localStorage whenever the route changes
    //     const currentPath = location.pathname + location.search;  // includes any query parameters
    //     localStorage.setItem('currentRoute', currentPath);
    //     console.log('Route saved to localStorage:', currentPath);
    // }, [location]);

    useEffect(() => {
        const fetchVerificationData = async () => {
            try {
                const response = await axios.get(`${API_URL}/check_user_verification/?user_id=${userId}`);
                if (response.data.success) {
                    setFormData(response.data.data);
                    setSubmittedData(response.data.data);
                    setDataExists(true);
                }
            } catch (error) {
                setDataExists(false);
            }
        };
        fetchVerificationData();
    }, [userId]);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const response = await axios.get(`${API_URL}/get-user-role/`, {
                    params: { user_id: userId },
                });
                setRole(response.data.role);
            } catch (err) {
                if (err.response) {
                    setError(err.response.data.error);
                } else {
                    setError("Something went wrong");
                }
            }
        };
        fetchUserRole();
    }, [userId]);

    return (
        <div>{/* Title */}
            <Box sx={{ textAlign: 'center', mt: 10 }}>
                <Typography variant="h2" sx={{ fontWeight: 'bold', color: 'white' }}>
                    Verification Portal
                </Typography>
                {/* <Typography variant="subtitle1" sx={{ color: 'white', mt: 1 }}>
                                  Unlock premium features and resources tailored for your needs
                              </Typography> */}
            </Box>
            <ThemeProvider theme={darkTheme}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh', // Ensures the page takes full height
                        // paddingTop: '80px', // Adjust for header height
                        backgroundColor: darkTheme.palette.background.default,
                    }}
                >
                    {/* Header */}
                    <Header1 userId={userId} onLogout={onLogout} />

                    {/* Main Content */}
                    <Box sx={{ flexGrow: 1, paddingX: 2 }}>
                        {dataExists && (
                            <SectionContainer>
                                <SubmittedData userId={userId} formData={formData} submittedData={submittedData} />
                            </SectionContainer>
                        )}

                        {(!dataExists && role === 'user') && (
                            <SectionContainer>
                                <Typography variant="h6" color="primary">
                                    Please verify your email:
                                </Typography>
                                <EmailVerifications userId={userId} />
                            </SectionContainer>
                        )}

                        {(!dataExists && (role === 'emvuser' || role === 'admin' || role === 'super_admin')) && (
                            <SectionContainer>
                                <Typography variant="h6" color="primary">
                                    Please complete your EMV verification:
                                </Typography>
                                <EmvVerifiedSection userId={userId} />
                            </SectionContainer>
                        )}
                    </Box>

                    {/* Footer */}
                    <Box sx={{ marginTop: 'auto' }}>
                        <Footer2 />
                    </Box>
                </Box>
            </ThemeProvider></div>
    );
};

export default Verification;
