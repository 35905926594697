import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardContent, Typography, Grid, Box, TextField, MenuItem, Select, InputLabel, FormControl, Slider, IconButton, Button, skeleton, Avatar } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import valmik_image from "../Icon/profile_image.png";
import StarIcon from '@mui/icons-material/Star';  // Import Star icon for rating
import FilterListIcon from '@mui/icons-material/FilterList'; // Import the filter icon
import VisibilityIcon from '@mui/icons-material/Visibility';
import avatar from './default-avatar.png'
import ShareIcon from '@mui/icons-material/Share';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Import the rupee icon
import Header1 from "../Header/Header1";

const API_URL = process.env.REACT_APP_API_URL;



function Posted({ userId, onLogout }) {
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  // New state for toggling the filter panel visibility
  const [showFilters, setShowFilters] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState({
    rating: 0,
    date: "",
    time: "",
    cost: [0, 500], // Cost range slider
    language: "",
    duration: [0, 120], // Duration range slider in minutes
    sectionId: "",  // Store section ID
    subsectionId: "",  // Store subsection ID
  });

  const [page, setPage] = useState(1);  // Start with page 1
  const [allFetched, setAllFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // useEffect(() => {
  // Build query string from filters
  const buildQuery = () => {
    let query = `?user_id=${userId}&page=${page}`;

    if (filters.rating) query += `&rating=${filters.rating}`;
    if (filters.date) query += `&date=${filters.date}`;
    if (filters.time) query += `&time=${filters.time}`;
    if (filters.language) query += `&language=${filters.language}`;
    if (filters.cost) query += `&min_cost=${filters.cost[0]}&max_cost=${filters.cost[1]}`;
    if (filters.duration) query += `&min_duration=${filters.duration[0]}&max_duration=${filters.duration[1]}`;
    if (filters.sectionId) query += `&section_id=${filters.sectionId}`;
    if (filters.subsectionId) query += `&subsection_id=${filters.subsectionId}`;

    return query;
  };


  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${API_URL}/list_posted_data/${buildQuery()}`);

      if (response.data.length === 0) {
        setAllFetched(true);  // Stop fetching if no new data
      } else {
        // Avoid duplicating data by checking if items already exist
        setSections((prevSections) => {
          const newSections = response.data.filter(
            (newSection) => !prevSections.some((section) => section.id === newSection.id)
          );
          return [...prevSections, ...newSections];
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    setAllFetched(false);
    setSections([]);
  }, [filters]);

  // Fetch data on page change or when filters change
  useEffect(() => {
    if (!allFetched) {
      fetchData();
    }
  }, [filters, userId, page]); // Dependencies include page and filters

  // Handle scroll event to detect when the user has scrolled to the bottom
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (windowHeight + scrollPosition >= documentHeight - 1 && !loading && !allFetched) {
      setPage((prevPage) => prevPage + 1);  // Increment the page to load next data
    }
  };

  // Add event listener for scroll on component mount and cleanup on unmount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, allFetched]);

  const extractLanguages = (data) => {
    const allLanguages = new Set();

    data.forEach(item => {
      allLanguages.add(item.name);
    });

    setLanguages([...allLanguages]);
  };


  const navigate = useNavigate();

  const allCards = sections

  const handleFilterChange = (event, filterType) => {
    setFilters({
      ...filters,
      [filterType]: event.target.value,
    });
  };
  const handleSliderChange = (event, newValue, filterType) => {
    if (Array.isArray(newValue)) {
      setFilters({
        ...filters,
        [filterType]: newValue,
      });
    } else {
      // For single value filters
      setFilters({
        ...filters,
        [filterType]: newValue,
      });
    }
  };
  // Combine all cards into one array for rendering
  // const allCards = sections.flatMap(section =>
  //   section.subsections.flatMap(subsection => subsection.cards)
  // );
  // Filter the cards based on selected filters
  const filteredCards = allCards.filter(card => {
    const matchesRating = filters.rating ? card.rating >= filters.rating : true;
    const matchesDate = filters.date ? card.date === filters.date : true;
    const matchesTime = filters.time ? card.starttime === filters.time : true;
    const matchesCost = card.price >= filters.cost[0] && card.price <= filters.cost[1];
    const matchesLanguage = filters.language ? card.language === filters.language : true;
    // Example of filtering based on duration
    const matchesDuration = true; // Implement duration filter logic here

    return matchesRating && matchesDate && matchesTime && matchesCost && matchesLanguage && matchesDuration;
  });


  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await fetch(`${API_URL}/list_sections_and_subsections/`);
        const data = await response.json();
        const formattedSections = Object.keys(data.sections).map((key) => ({
          section_id: data.sections[key].section_id,
          section_name: key,
          subsections: data.sections[key].subsections || [],
        }));
        setCategories(formattedSections);
        extractLanguages(data.languages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchSections();
  }, []);


  const handleSearch = (result) => {
    setSections(result)
    // setData(result)
  }

  const handleClearFilters = () => {
    setFilters({
      rating: 0,
      date: "",
      time: "",
      cost: [0, 500], // Cost range slider
      language: "",
      duration: [0, 120], // Duration range slider in minutes
      sectionId: "",  // Store section ID
      subsectionId: "",  // Store subsection ID
    })
    setAllFetched(false);

  }

  const handleSectionChange = (event) => {
    const selectedSectionId = event.target.value;
    setFilters((prev) => ({
      ...prev,
      sectionId: selectedSectionId,
      subsectionId: ''  // Reset subsection when section changes
    }));

    // Find the subsections corresponding to the selected section
    const selectedSection = categories.find(
      (category) => category.section_id === selectedSectionId
    );
    setSubsections(selectedSection ? selectedSection.subsections : []);
  };

  const handleSubsectionChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      subsectionId: event.target.value
    }));
  };



  return (
    <Box sx={{ padding: "1rem", backgroundColor: "#11111", minHeight: "100vh", ml: 5, mt: 10 }}>
      <Header1 userId={userId} page_name="posted" onSearch={handleSearch} onLogout={onLogout} />
      {/* Header and Filter Icon Button */}
      {/* Filter Icon Button */}
      <IconButton
        onClick={() => setShowFilters(!showFilters)}
        sx={{
          color: "#ffffff",
          position: "fixed",  // Absolutely position it within the parent box
          top: "70px",            // Add some spacing from the top
          right: "10px",          // Add some spacing from the right edge
          zIndex: 20,
        }}
      >
        <FilterListIcon />
      </IconButton>
      {/* Filter Panel - Initially Hidden */}
      {showFilters && (
        <Box sx={{
          position: "fixed",
          top: "60px",
          right: "0",
          width: "300px",
          height: "100%",
          backgroundColor: "#11111",
          padding: "1rem",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.4)",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: "8px",
          zIndex: "10"
        }}>
          {/* Other Filters (Date, Time, Cost, etc.) can be added similarly */}
          {/* Section Filter */}
          <FormControl sx={{ width: "100%", marginBottom: "1rem", marginTop: "2rem" }} size="medium">
            <InputLabel sx={{ color: "#ffffff" }}>Category</InputLabel>
            <Select
              value={filters.sectionId}
              onChange={handleSectionChange}
              displayEmpty
              sx={{
                backgroundColor: "#2222", color: "#ffffff",
                "& .MuiInputBase-root": { color: "#ffffff" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#2222" },
                "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#1E90FF" }
              }}
            >
              <MenuItem value=""></MenuItem>
              {categories.map((section) => (
                <MenuItem key={section.section_id} value={section.section_id}>
                  {section.section_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Subsection Filter */}
          {filters.sectionId && (
            <FormControl sx={{ width: "100%", marginBottom: "1rem" }} size="medium">
              <InputLabel sx={{ color: "#ffffff" }}>Subcategory</InputLabel>
              <Select
                value={filters.subsectionId}
                onChange={handleSubsectionChange}
                displayEmpty
                sx={{
                  backgroundColor: "#2222", color: "#ffffff",
                  "& .MuiInputBase-root": { color: "#ffffff" },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#2222" },
                  "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#1E90FF" }
                }}
              >
                <MenuItem value=""></MenuItem>
                {subsections.map((subsection) => (
                  <MenuItem key={subsection.id} value={subsection.id}>
                    {subsection.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {/* Filter Form */}
          <FormControl sx={{ width: "100%", marginBottom: "1rem" }}>
            <InputLabel sx={{ color: "#ffffff" }}>Rating</InputLabel>
            <Select
              value={filters.rating}
              label="Rating"
              onChange={(e) => handleFilterChange(e, "rating")}
              sx={{
                backgroundColor: "#2222",
                color: "#ffffff",
                "& .MuiInputBase-root": {
                  color: "#ffffff",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2222",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1E90FF",
                }
              }}
            >
              {[0, 1, 2, 3, 4, 5].map((rating) => (
                <MenuItem key={rating} value={rating}>
                  {rating}+
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>

            <TextField
              // label="Date"
              type="date"
              value={filters.date}
              onChange={(e) => handleFilterChange(e, "date")}
              sx={{
                backgroundColor: "#2222",
                color: "#ffffff",
                "& .MuiInputBase-root": {
                  color: "#ffffff",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2222",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1E90FF",
                },
                marginBottom: "1rem",  // Add margin below the input field (adjust as needed)
              }}
            />

            {/* <TextField
              // label="Time"
              type="time"
              value={filters.time}
              onChange={(e) => handleFilterChange(e, "time")}
              sx={{
                backgroundColor: "#2222",
                color: "white",
                "& .MuiInputBase-root": {
                  color: "#ffffff",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2222",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1E90FF",
                },
                marginBottom: "1rem",
              }}
            /> */}
          </Box>
          {/* Language Filter */}
          <FormControl sx={{ width: "100%", marginBottom: "1rem" }}>
            <InputLabel sx={{ color: "#ffffff" }}>Language</InputLabel>
            <Select
              value={filters.language}
              label="Language"
              onChange={(e) => handleFilterChange(e, "language")}
              sx={{
                backgroundColor: "#2222",
                color: "#ffffff",
                "& .MuiInputBase-root": {
                  color: "#ffffff",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2222",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1E90FF",
                }
              }}
            >
              <MenuItem value="">Select Language</MenuItem>
              {languages.map((lang, index) => (
                <MenuItem key={index} value={lang}>
                  {lang}
                </MenuItem>
              ))}
              {/* Add other languages here */}
            </Select>
          </FormControl>
          {/* Duration Filter */}
          <Box sx={{ width: "100%", marginBottom: "1rem" }}>
            <Typography gutterBottom sx={{ color: "#ffffff" }}>Duration</Typography>
            <Slider
              value={filters.duration}
              onChange={(e, newValue) => handleSliderChange(e, newValue, "duration")}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value} mins`}
              min={0}
              max={120}
              step={10}
              sx={{
                color: "#1E90FF",
                "& .MuiSlider-thumb": {
                  backgroundColor: "#1E90FF",
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#555555",
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#1E90FF",
                }
              }}
            />
          </Box>
          {/* Cost Filter */}
          {/* <Box sx={{ width: "100%", marginBottom: "1rem" }}>
            <Typography gutterBottom sx={{ color: "#ffffff" }}>Cost</Typography>
            <Slider
              value={filters.cost}
              onChange={(e, newValue) => handleSliderChange(e, newValue, "cost")}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `₹ ${value}`}
              min={0}
              max={500}
              step={10}
              sx={{
                color: "#1E90FF",
                "& .MuiSlider-thumb": {
                  backgroundColor: "#1E90FF",
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#555555",
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#1E90FF",
                }
              }}
            />
          </Box> */}

          <Box sx={{ }}>
            <Button
              onClick={handleClearFilters}
              variant="outlined"
              sx={{
                width: "100%",
                color: "#1E90FF",
                borderColor: "#1E90FF",
                "&:hover": {
                  backgroundColor: "#1E90FF",
                  color: "#fff"
                }
              }}
            >
              Clear Filters
            </Button>
          </Box>

        </Box>
      )}
      {/* Filter Bar */}
      <Box sx={{ marginRight: showFilters ? "75px" : "0", transition: "margin-right 0.3s" }}>
        {/* Cards Section */}
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: showFilters ? "2px" : "0", // Adjust the cards' position when filters are shown
            width: showFilters ? "calc(100% - 200px)" : "100%", // Dynamically adjust width when filter is shown
            marginRight: "auto", // Center the grid container
            justifyContent: "center", // Center the grid content
            display: "flex", // Ensure flexbox layout is used
            flexWrap: "wrap", // Allow wrapping for responsive design
          }}
        >
          {allCards.map((card, cardIndex) => (
            <Grid
              item
              xs={12} // 12 columns on extra small screens (1 card per row)
              sm={showFilters ? 12 : 6}  // 12 columns on small screens when filters are open (1 card per row), else 2 cards per row
              md={showFilters ? 6 : 4}   // 6 columns on medium screens when filters are open (2 cards per row), else 3 cards per row
              lg={showFilters ? 4 : 3}   // 4 columns on large screens when filters are open (3 cards per row), else 4 cards per row
              key={cardIndex}
            >
              <Link to={`/post-tab-details/${card.id}/${userId}`} style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#000000",
                    borderRadius: "15px",
                    overflow: "hidden",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                    height: "auto",
                    borderColor: "#5555",
                    padding: "10px",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      borderColor: card.color || "#1E90FF",
                      border: `2px solid ${card.color || "#1E90FF"}`,
                      boxShadow: "0 15px 25px rgba(0, 0, 0, 0.15)",
                    },
                    // Responsive styling
                    "@media (max-width: 1200px)": {
                      padding: "8px", // Adjust padding for medium screens
                    },
                    "@media (max-width: 768px)": {
                      flexDirection: "column", // Stack elements vertically for tablets
                      padding: "6px", // Reduce padding on smaller screens
                    },
                    "@media (max-width: 480px)": {
                      padding: "5px", // Further reduce padding on small screens
                    },
                  }}
                >
                  <Box
                    sx={{
                      padding: "15px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    {/* Section Name */}
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        height: "60px",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        borderRadius: "8px",
                        padding: "12px",
                        marginBottom: "15px",
                      }}
                    >
                      <Typography
                        variant="h8"
                        sx={{
                          fontSize: { xs: "0.7rem", sm: "0.75rem", md: "0.82rem" }, // Font size adjustment for different screen sizes
                          fontWeight: 300,
                          color: "black",
                          whiteSpace: "normal", // Ensures text wraps if needed
                          wordWrap: "break-word", // Break words if they are too long
                          width: "100%", // Ensure it takes the full width
                          fontStyle: "italic",
                          // Adjusting padding and line-height for better readability on smaller screens
                          padding: { xs: "0 8px", sm: "0 12px", md: "0 16px" }, // Padding for better spacing
                          lineHeight: { xs: "1.2", sm: "1.3", md: "1.4" }, // Adjusting line height for different screen sizes
                        }}
                      >
                        {card.section_name} &gt; {card.subsection_name}
                      </Typography>

                    </Grid>

                    {/* Topic Name */}
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: "0.9rem", sm: "1.2rem", md: "1rem" }, // Responsive font size for different screen sizes
                        fontWeight: 500,
                        textAlign: "center", // Centers text on all screen sizes
                        color: "white",
                        mt: { xs: 1, sm: 2, md: 2 }, // Adjust top margin based on screen size
                        mb: { xs: 1, sm: 2, md: 2 }, // Adjust bottom margin for spacing on different screen sizes
                        px: { xs: 1, sm: 2, md: 2 }, // Add some horizontal padding for smaller screens
                        '&:hover': {
                          color: card.color || '#1E90FF', // Color changes on hover
                        },
                      }}
                    >
                      {card.highlightedTopic}
                    </Typography>

                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" }, // Responsive font size
                        fontWeight: 200,
                        textAlign: "center", // Keeps text centered on all screen sizes
                        color: "white",
                        fontStyle: "italic", // Italicized text
                        mt: { xs: 1, sm: 0.1, md: 0.1 }, // Adjust top margin for better spacing on smaller screens
                        mb: { xs: 1, sm: 1, md: 1 }, // Adjust bottom margin for consistency
                        px: { xs: 2, sm: 1, md: 1 }, // Horizontal padding to avoid text sticking to edges on smaller screens
                      }}
                    >
                      by
                    </Typography>

                    {/* Teacher Image and Name */}
                    <Grid container spacing={1} sx={{ justifyContent: "center", alignItems: "center" }}>
                      {/* Teacher Image */}
                      <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Avatar sx={{
        width: 50,
        height: 50,
        // mr: 2,
      }} src={`${API_URL}${card.image}` || '/default-avatar.png'} alt={card.teacher || 'User'} />
                      {/* <img
  src={card.image ? `${API_URL}${card.image}` : avatar}
  alt="image"
  style={{
    width: "55px",           // Default width for larger screens
    height: "55px",          // Default height for larger screens
    borderRadius: "50%",     // Ensure image remains circular
    objectFit: "cover",      // Cover the space inside the circle
    border: "1.5px solid #5555", // Border around the image
    minWidth: "35px",        // Ensure image doesn't shrink smaller than 35px
    minHeight: "35px",       // Ensure image doesn't shrink smaller than 35px
    maxWidth: "60px",        // Maximum size to ensure it scales down on smaller screens
    maxHeight: "60px",       // Ensure aspect ratio is maintained when resizing
    // Adjust size on smaller screens using media queries
    "@media (max-width: 768px)": {
      width: "45px",   // Smaller size for tablets
      height: "45px",  // Ensure height equals width for circular shape
    },
    "@media (max-width: 480px)": {
      width: "35px",   // Even smaller size for mobile screens
      height: "35px",  // Ensure height equals width for circular shape
    },
  }}
/> */}




                      </Grid>
                      {/* Teacher Name */}
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
                            color: "white",
                            textAlign: "center", // Keeps text centered across all screen sizes
                            marginTop: { xs: "3px", sm: "5px", md: "5px" }, // Adjust top margin for different screen sizes
                            marginBottom: { xs: "3px", sm: "5px", md: "5px" }, // Optional: Adjust bottom margin for consistency
                            paddingX: { xs: "8px", sm: "12px", md: "16px" }, // Add horizontal padding on smaller screens
                            '&:hover': {
                              color: '#1E90FF', // Hover color
                            },
                          }}
                        >
                          {card.teacher} {card.surname}
                        </Typography>
                      </Grid>

                      {/* Views */}
                      <Grid item xs={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <VisibilityIcon sx={{ fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" }, color: "white", marginBottom: "2px", mr: 1 }} />
                        <Typography variant="body2" sx={{ fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" }, color: "white" }}>
                          {card.views}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
export default Posted;