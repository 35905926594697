import React, { useState } from 'react';
import { Button, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import GoogleMeetIcon from '@mui/icons-material/VideoCall';

const API_URL = process.env.REACT_APP_API_URL;

const GenerateMeetingLinkButton = ( { userId, onLinkGenerated } ) => {
  // const {userId} = useParams();
  const [meetingLink, setMeetingLink] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const generateMeetingLink = async () => {
    try {
      const response = await axios.post(`${API_URL}/generate-meeting-link/`,{user_id:userId});
      // const response = await axios.post(`${API_URL}/generate-meeting-link/?user_id=${userId}`,{user_id:userId});
      const { meetingLink } = response.data;
      setMeetingLink(meetingLink);
      setOpenSnackbar(true);
      onLinkGenerated(meetingLink);
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
      // console.error('Error generating meeting link:', error);
    }
  };

  return (
    <div>
      <Button
      variant="contained"
      color="primary"
      sx={{ mb: 2 }}
      onClick={generateMeetingLink}
      startIcon={<GoogleMeetIcon />} // Add the icon here
    >
      Generate Meet Link
    </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          Meeting Link Generated: {meetingLink}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GenerateMeetingLinkButton;
