import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper, Divider, IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const CookiesPolicy = ({ userId, loggedIn, onLogout }) => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleLogout = () => {
    onLogout(); // Call the passed logout function
    navigate('/login'); // Navigate to login page after logout
  };

  return (
    <div>
      {loggedIn ? (
        <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
      ) : (
        <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
      )}
      <Container maxWidth="md" sx={{ mt: 10 }}>
        <Box my={4}>
          {/* Title Section */}
          <Typography variant="h4" align="center" gutterBottom>
            Cookies Policy
          </Typography>

          <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
            Last updated January 26, 2025
          </Typography>

          {/* Content Section */}
          <Typography variant="h5" gutterBottom>
            Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            This Cookie Policy explains how ANTHRASYNC SOLUTIONS PRIVATE LIMITED ("Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at https://www.anthrasync.com ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
          </Typography>
          
          <Typography variant="h5" gutterBottom>
            What Are Cookies?
          </Typography>
          <Typography variant="body1" paragraph>
            Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.
          </Typography>
          
          <Typography variant="body1" paragraph>
            Cookies set by the website owner (in this case, ANTHRASYNC SOLUTIONS PRIVATE LIMITED) are called "first-party cookies." Cookies set by parties other than the website owner are called "third-party cookies." Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
          </Typography>

          <Typography variant="h5" gutterBottom>
            Why Do We Use Cookies?
          </Typography>
          <Typography variant="body1" paragraph>
            We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.
          </Typography>

           {/* New Section: How to Control Cookies */}
           <Typography variant="h5" gutterBottom>
            How Can You Control Cookies?
          </Typography>
          <Typography variant="body1" paragraph>
            You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.
          </Typography>

          <Typography variant="body1" paragraph>
            The Cookie Consent Manager can be found in the notification banner and on our Website. If you choose to reject cookies, you may still use our Website though your access to some functionality and areas of our Website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.
          </Typography>

          <Typography variant="body1" paragraph>
            The specific types of first- and third-party cookies served through our Website and the purposes they perform are described in the table below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):
          </Typography>

          <Typography variant="h6" gutterBottom>
            Unclassified Cookies:
          </Typography>
          <Typography variant="body1" paragraph>
            These are cookies that have not yet been categorized. We are in the process of classifying these cookies with the help of their providers.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Name:</strong> rzp_device_id
            <br />
            <strong>Provider:</strong> www.anthrasync.com
            <br />
            <strong>Type:</strong> html_local_storage
            <br />
            <strong>Expires in:</strong> persistent
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Name:</strong> rzp_checkout_anon_id
            <br />
            <strong>Provider:</strong> www.anthrasync.com
            <br />
            <strong>Type:</strong> html_local_storage
            <br />
            <strong>Expires in:</strong> persistent
          </Typography>

          {/* New Section: Controlling Cookies in Your Browser */}
          <Typography variant="h5" gutterBottom>
            How Can You Control Cookies on Your Browser?
          </Typography>
          <Typography variant="body1" paragraph>
            As the means by which you can refuse cookies through your web browser controls vary from browser to browser, you should visit your browser's help menu for more information. The following is information about how to manage cookies on the most popular browsers:
          </Typography>

          <ul>
            <li><strong>Chrome:</strong> Open Chrome settings > Privacy and security > Cookies and other site data.</li>
            <li><strong>Internet Explorer:</strong> Open Internet Explorer settings > Privacy tab > Advanced settings.</li>
            <li><strong>Firefox:</strong> Open Firefox settings > Privacy & Security > Cookies and Site Data.</li>
            <li><strong>Safari:</strong> Open Safari settings > Preferences > Privacy tab.</li>
            <li><strong>Edge:</strong> Open Edge settings > Privacy, search, and services > Cookies and site data.</li>
            <li><strong>Opera:</strong> Open Opera settings > Privacy & security > Cookies.</li>
          </ul>

          <Typography variant="body1" paragraph>
            In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit:
          </Typography>
          <ul>
          <li>
    <a
      href="https://www.aboutads.info/"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        color: '#00bcd4',  // Bright cyan color for visibility on dark background
        textDecoration: 'underline',  // Optional: underline the link
      }}
    >
      Digital Advertising Alliance
    </a>
  </li>
  <li>
    <a
      href="https://youradchoices.ca/"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        color: '#00bcd4',
        textDecoration: 'underline',
      }}
    >
      Digital Advertising Alliance of Canada
    </a>
  </li>
  <li>
    <a
      href="https://www.edaa.eu/"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        color: '#00bcd4',
        textDecoration: 'underline',
      }}
    >
      European Interactive Digital Advertising Alliance
    </a>
  </li>
          </ul>

          {/* Web Beacons Section */}
          <Typography variant="h5" gutterBottom>
            What About Other Tracking Technologies, Like Web Beacons?
          </Typography>
          <Typography variant="body1" paragraph>
            Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique identifier that enables us to recognize when someone has visited our Website or opened an email including them. This allows us, for example, to monitor the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of email marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.
          </Typography>

          {/* New Section: Flash Cookies (LSOs) */}
          <Typography variant="h5" gutterBottom>
            Do You Use Flash Cookies or Local Shared Objects (LSOs)?
          </Typography>
          <Typography variant="body1" paragraph>
            Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or "LSOs") to, among other things, collect and store information about your use of our services, fraud prevention, and for other site operations.
          </Typography>

          <Typography variant="body1" paragraph>
            If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to block Flash Cookies storage using the tools contained in the <a
  href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
  target="_blank"
  rel="noopener noreferrer"
  style={{
    color: '#00bcd4',  // Bright cyan color for visibility on dark background
    textDecoration: 'underline',  // Optional: underline the link
  }}
>
  Website Storage Settings Panel
</a>
. You can also control Flash Cookies by going to the <a
  href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
  target="_blank"
  rel="noopener noreferrer"
  style={{
    color: '#00bcd4',  // Bright cyan color for visibility on dark background
    textDecoration: 'underline',  // Optional: underline the link
  }}
>
  Global Storage Settings Panel
</a>
 and following the instructions (which may include instructions that explain, for example, how to delete existing Flash Cookies (referred to as "information" on the Macromedia site), how to prevent Flash LSOs from being placed on your computer without your being asked, and (for Flash Player 8 and later) how to block Flash Cookies that are not being delivered by the operator of the page you are on at the time).
          </Typography>

          <Typography variant="body1" paragraph>
            Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our services or online content.
          </Typography>

           {/* Targeted Advertising Section */}
           <Typography variant="h5" gutterBottom>
            Do You Serve Targeted Advertising?
          </Typography>
          <Typography variant="body1" paragraph>
            Third parties may serve cookies on your computer or mobile device to serve advertising through our Website. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. They can accomplish this by using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details, or other details that directly identify you unless you choose to provide these.
          </Typography>

          {/* Cookie Policy Updates Section */}
          <Typography variant="h5" gutterBottom>
            How Often Will You Update This Cookie Policy?
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
          </Typography>
          <Typography variant="body1" paragraph>
            The date at the top of this Cookie Policy indicates when it was last updated.
          </Typography>
          
          <Typography variant="h5" gutterBottom>
            Contact Us
          </Typography>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="body1" paragraph>
              If you have any questions related to our cookies policy, please contact us at:
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>ANTHRASYNC SOLUTIONS PRIVATE LIMITED</strong>
              <br />
              No 26, Laskar Hosur Road, Adugodi, Bangalore South
              <br />
              Bangalore, Karnataka 560030
              <br />
              India
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Email:</strong> official@anthrasync.com
            </Typography>
          </Paper>
        </Box>
      </Container>
      <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <Footer2 />
    </div>
  );
};

export default CookiesPolicy;
