import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Grid, Typography, Container, MenuItem, Select, InputLabel, FormControl, Box, Snackbar, Alert, FormHelperText } from '@mui/material';
import { useParams } from 'react-router-dom';
import GenerateMeetingLinkButton from '../GoogleMeet/GenerateMeetingLinkButton';
import { format } from 'date-fns';
import GenerateZoomMeetingButton from '../Zoom/GenerateZoomMeetingButton';
import ZoomAuthButton from '../Zoom/ZoomAuthButton';
import GenerateMicrosoftTeamsLink from '../Microsoft/GenerateMicrosoftTeamsLink';

const API_URL = process.env.REACT_APP_API_URL;

const styles = {
    glassBox: {
        backdropFilter: 'blur(15px)',
        backgroundColor: '#000000',
        borderRadius: '16px',
        padding: '2.5rem',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        width: '100%',
        maxWidth: '100%',
        textAlign: 'center',
    },
    logo: {
        width: '160px',
        height: '160px',
        margin: '0 auto 1rem',
    },
    text: {
        color: 'white',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        width: '100%',
    },
    input: {
        marginBottom: '1rem',
        '& .MuiInputBase-input': {
            color: 'white',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            transition: 'color 0.3s',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1E90FF',
        },
        '&:hover .MuiInputLabel-root': {
            color: '#1E90FF',
        },
        '& .MuiSelect-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1E90FF',
        },
    },
    dateInput: {
        marginBottom: '1rem',
        '& .MuiInputBase-input': {
            color: 'white',
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'transparent',
            transition: 'color 0.3s',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            transition: 'color 0.3s',
        },
        '& .Mui-focused .MuiInputBase-input::placeholder': {
            color: 'white',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1E90FF',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1E90FF',
        },
    },
    button: {
        backgroundColor: '#1E90FF',
        color: 'white',
        marginTop: '1.5rem',
    },
    remainingPosts: {
        position: 'relative',
        maxWidth: '200px',
        alignItems: 'right',
        justifyContent: 'right',
        mt: 5,
        ml: 5,
        // top: '60px',
        // right: '20px',
        backgroundColor: '#1E90FF',
        padding: '0.5rem 1rem',
        borderRadius: '8px',
        color: '#fff',
        fontSize: '1rem',
    },
};

const CreatePost = ({ userId, onLogout }) => {
    const [startTime, setStartTime] = useState('');
    const [duration, setDuration] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [maxLimit, setMaxLimit] = useState('');
    const [price, setPrice] = useState('');
    const [topicName, setTopicName] = useState('');
    const [description, setDescription] = useState('');
    const [sectionId, setSectionId] = useState('');
    const [subsectionId, setSubsectionId] = useState('');
    const [sections, setSections] = useState([]);
    const [subsections, setSubsections] = useState([]);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [language, setLanguage] = useState('');
    const [meetingLink, setMeetingLink] = useState("");
    const [googleMeetLink, setGoogleMeetLink] = useState('');
    const [remainingPosts, setRemainingPosts] = useState(0);
    const [languages, setLanguages] = useState([]);  // State for languages
    const [platform, setPlatform] = useState("");
    const [isValid, setIsValid] = useState(null);

    const { id } = useParams();

    // to track the validity of the link
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();




    const validateMeetingLink = (link, platform) => {
        const googleMeetRegex = /https:\/\/meet.google.com\/[a-zA-Z0-9\-]+/;

        const zoomRegex = /https:\/\/\w+\.zoom.us\/j\/\d+(\?pwd=[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?/;  // Updated regex
        
        // Updated Zoom Regex that handles different formats, including session ID and password query params
        // const zoomRegex = /https:\/\/(?:us04\.|zoom\.)?zoom\.us\/j\/\d+(\?pwd=[a-zA-Z0-9\-\.]+)?/;
        
        // Updated Teams Regex for the new format
        const teamsRegex = /https:\/\/teams\.live\.com\/meet\/[a-zA-Z0-9\-]+/;
    
        switch (platform) {
            case "googleMeet":
                return googleMeetRegex.test(link);
            case "zoom":
                return zoomRegex.test(link);
            case "microsoftTeams":
                return teamsRegex.test(link);
            default:
                return false;
        }
    };
    
    
    
    

    // Handle platform change
    const handlePlatformChange = (e) => {
        const selectedPlatform = e.target.value;
        setPlatform(selectedPlatform);

        // Validate the link immediately when platform is selected
        if (meetingLink && meetingLink.trim() !== "") {
            const isLinkValid = validateMeetingLink(meetingLink, selectedPlatform);
            setIsValid(isLinkValid);
            if (!isLinkValid) {
                setErrorMessage("Invalid link for the selected platform.");
            } else {
                setErrorMessage("");
            }
        } else {
            // Reset validation when link is empty
            setIsValid(true);
            setErrorMessage("");
        }
    };


    // Handle link change
    const handleLinkChange = (e) => {
        const link = e.target.value;
        setMeetingLink(link);

        // If the link is empty, reset validation and error message
        if (link.trim() === "") {
            setIsValid(true); // Set valid state as true when the link is empty
            setErrorMessage(""); // Reset the error message
        } else if (platform) {
            // Validate the link immediately when user types/pastes it
            const isLinkValid = validateMeetingLink(link, platform);
            setIsValid(isLinkValid);
            if (!isLinkValid) {
                setErrorMessage("Invalid link for the selected platform.");
            } else {
                setErrorMessage("");
            }
        }
    };


    useEffect(() => {
        const fetchRemainingPosts = async () => {
            try {
                const response = await axios.get(`${API_URL}/get_user_remaining_posts/`, {
                    params: { user_id: userId }
                });
                setRemainingPosts(response.data.remaining_posts);
            } catch (error) {
                console.error('Error fetching remaining posts:', error);
            }
        };

        fetchRemainingPosts();
    }, [userId]);

    useEffect(() => {
        axios.get(`${API_URL}/list_sections_and_subsections/?user_id=${userId}`)
            .then((response) => {
                const transformedSections = Object.entries(response.data.sections).map(([sectionName, sectionData]) => ({
                    sectionId: sectionData.section_id,
                    sectionName,
                    subsections: sectionData.subsections,
                }));
                setSections(transformedSections);


                if (response.data.languages) {
                    setLanguages(response.data.languages);
                }
            })
            .catch((error) => {
                console.error('Error fetching sections:', error);
            });
    }, [userId]);


    const showAlert = () => {
        const alertDiv = document.createElement('div');
        alertDiv.textContent = 'Post Created successfully';
        alertDiv.style.position = 'fixed';
        alertDiv.style.top = '10px';
        alertDiv.style.left = '50%';
        alertDiv.style.transform = 'translateX(-50%)';
        alertDiv.style.padding = '10px 20px';
        alertDiv.style.backgroundColor = 'green';
        alertDiv.style.color = 'white';
        alertDiv.style.borderRadius = '5px';
        alertDiv.style.zIndex = '9999';
        alertDiv.style.fontSize = '16px';

        document.body.appendChild(alertDiv);

        // Auto remove the alert after 3 seconds
        setTimeout(() => {
            alertDiv.remove();
        }, 3000);
    };


    // useEffect(() => {
    //     // Fetch sections and subsections data from the backend
    //     axios.get(`${API_URL}/list_sections_and_subsections/`)
    //         .then((response) => {

    //             if (response.data && response.data.sections) {
    //                 const fetchedSections = Object.entries(response.data.sections).map(([sectionName, sectionData]) => ({
    //                     sectionName,
    //                     sectionId: sectionData.section_id,
    //                     subsections: sectionData.subsections,
    //                 }));

    //                 setSections(fetchedSections);
    //             } else {
    //                 console.error("Sections data is missing in the response!");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching sections:', error);
    //         });
    // }, []);



    const handleSectionChange = (event) => {
        const selectedSectionId = event.target.value;
        setSectionId(selectedSectionId);

        const selectedSection = sections.find(section => section.sectionId === selectedSectionId);
        setSubsections(selectedSection ? selectedSection.subsections : []);
        setSubsectionId('');
    };

    const handleSubsectionChange = (event) => {
        setSubsectionId(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handlePost = () => {
        // Step 1: Validate required fields and other constraints
    
        // Validate if the required fields are provided
        const requiredFields = [
            // { field: userId, fieldName: "User ID" },
            { field: sectionId, fieldName: "Section Name" },
            { field: subsectionId, fieldName: "Subsection Name" },
            { field: topicName, fieldName: "Topic Name" },
            { field: description, fieldName: "Description" },
            { field: language, fieldName: "Language" },
            { field: duration, fieldName: "Duration" },
            { field: maxLimit, fieldName: "Max Limit" },
            { field: price, fieldName: "Price" },
            { field: selectedDate, fieldName: "Select date" },
            { field: startTime, fieldName: "Start Time" },
            { field: meetingLink, fieldName: "Meeting Link" },
            { field: platform, fieldName: "Platform" }
        ];
    
        // Check for missing required fields
        for (let { field, fieldName } of requiredFields) {
            if (!field || field.toString().trim() === "") {
                setError(`${fieldName} is required.`);
                setOpenSnackbar(true);
                return;
            }
        }
    
        // Validate the selectedDate
        if (selectedDate) {
            const year = format(selectedDate, 'yyyy');
            if (year.length !== 4) {
                setError('The year must be exactly 4 digits.');
                setOpenSnackbar(true);
                return;
            }
        }
    
        // Validate the duration
        if (duration > 300) {
            setError('Duration cannot exceed 300 minutes.');
            setOpenSnackbar(true);
            return;
        }
    
        // Validate the maxLimit
        if (maxLimit > 100) {
            setError('Max Limit cannot exceed 100.');
            setOpenSnackbar(true);
            return;
        }
    
        // Validate the price
        if (price > 100) {
            setError('Price cannot exceed 100.');
            setOpenSnackbar(true);
            return;
        }
    
        // Step 2: Find section and subsection names based on IDs
        const selectedSection = sections.find(section => section.sectionId === sectionId);
        const sectionName = selectedSection ? selectedSection.sectionName : '';
    
        const selectedSubsection = subsections.find(subsection => subsection.id === subsectionId);
        const subsectionName = selectedSubsection ? selectedSubsection.name : '';
    
        // Step 3: Prepare postData
        const postData = {
            user_id: userId,
            section_id: sectionId,
            section_name: sectionName,
            subsection_id: subsectionId,
            subsection_name: subsectionName,
            startTime: startTime,
            duration: duration,
            selectedDate: selectedDate,
            max_limit: maxLimit,
            price: price,
            topic_name: topicName,
            description: description,
            language: language,
            meeting_link: meetingLink,
            platform: platform
        };
    
        // Step 4: Make the API request
        axios.post(`${API_URL}/create_post/`, postData).then((response) => {
            showAlert();
            window.location.reload();
        }).catch((error) => {
            console.error('Error posting data:', error);
            if (error.response && error.response.status === 403) {
                setError('You need an active subscription to post.');
                setOpenSnackbar(true);
            } else {
                setError('An error occurred while posting. Please try again later.');
                setOpenSnackbar(true);
            }
        });
    };
    

    const handleChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleRedirect = () => {
        navigate('/posting-guidelines');  // Redirect to meeting guidelines page
    };

    // Optionally, add an error check (for example, if no language is selected)
    //   const hasError = language === '';

    return (
        <div style={{ backgroundColor: '#000000', minHeight: '100vh', color: '#ffffff' }}>

            <Container maxWidth="lg" sx={{
                marginTop: { xs: '8rem', sm: '10rem', lg: '5rem' },
                marginBottom: { xs: '2rem', sm: '3rem', lg: '5rem' },
                padding: '0 2rem',
            }}>
                <Typography variant="h6" sx={styles.remainingPosts}>
                    Remaining Posts: {remainingPosts}
                </Typography>
                <Button
            variant="contained"
            color="info"
            onClick={handleRedirect}  // Handle button click and redirect
            sx={{
                width: { xs: '100%', sm: 'auto' },
                padding: { xs: '0.75rem 1rem', sm: '0.875rem 1.5rem' },
                fontSize: { xs: '0.875rem', sm: '1rem' },
                textAlign: 'center',
                marginTop: { xs: '1rem', sm: '1.5rem' },
            }}
        >
            Posting Guidelines
        </Button>

                <Box sx={{
                    padding: '2rem',
                    borderRadius: '8px',
                    backgroundColor: '#000000',
                    transition: 'border-color 0.3s ease',
                    '&:hover': {
                        borderColor: '#1E90FF',
                    },
                    paddingBottom: '1.5rem',
                }}>
                    <Typography variant="h4" sx={{
                        marginBottom: '1.5rem',
                        marginTop: '1.5rem',
                        textAlign: 'center',
                        color: '#ffffff',
                        '&:hover': {
                            color: '#1E90FF',
                        },
                    }}>
                        Create a New Post
                    </Typography>

                    <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                        <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Select Category</InputLabel>
                        <Select
                            value={sectionId}
                            onChange={handleSectionChange}
                            label="Select Section"
                        // sx={{
                        //     // color: 'rgba(255, 255, 255, 0.7)',
                        //     border: "2px solid #9999",
                        // }}
                        >
                            {sections.map((section) => (
                                <MenuItem key={section.sectionId} value={section.sectionId}>
                                    {section.sectionName}
                                </MenuItem>
                            ))}
                        </Select>

                        {sections.length === 0 && (
                            <Box sx={{ color: 'orange', marginTop: '0.5rem', fontSize: '0.875rem' }}>
                                <Typography variant="body2">
                                    You don't have any approved sections to post.
                                </Typography>
                            </Box>
                        )}
                    </FormControl>

                    {subsections.length > 0 && (
                        <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                            <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Select Subcategory</InputLabel>
                            <Select
                                value={subsectionId}
                                onChange={handleSubsectionChange}
                                label="Select Subsection"
                            // sx={{
                            //     color: 'rgba(255, 255, 255, 0.7)',
                            //     border: "2px solid #9999",
                            // }}
                            >
                                {subsections.map((subsection) => (
                                    <MenuItem key={subsection.id} value={subsection.id}>
                                        {subsection.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        label="Topic Name"
                        variant="outlined"
                        fullWidth
                        value={topicName}
                        onChange={(e) => {
                            if (e.target.value.length <= 100) {
                                setTopicName(e.target.value);
                            }
                        }}
                        sx={styles.input}
                        inputProps={{
                            maxLength: 100,
                        }}
                        helperText="Please enter the topic name on which you want to share knowledge (Maximum 100 characters are allowed)"
                    />

                    <TextField
                        label="Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => {
                            if (e.target.value.length <= 500) {
                                setDescription(e.target.value);
                            }
                        }}
                        sx={styles.input}
                        inputProps={{
                            maxLength: 500,
                        }}
                        helperText="Please enter a short description about what exactly you will be sharing in the session. It will help users to understand better (Max 500 characters allowed)"
                    />


                    <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                        <InputLabel id="language-select-label">Language</InputLabel>
                        <Select
                            labelId="language-select-label"
                            id="language-select"
                            value={language}
                            onChange={handleChange}
                            label="Language"
                        >
                            {languages.map((lang) => (
                                <MenuItem key={lang.id} value={lang.name}>
                                    {lang.name}
                                </MenuItem>
                            ))}
                        </Select>

                        {/* Helper Text with color */}
                        <FormHelperText sx={{ color: '#9999' }}>
                            Please select the language in which you will be communicating during the session.
                        </FormHelperText>
                    </FormControl>


                    <TextField
                        label="Duration (in minutes)"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        sx={styles.input}
                        inputProps={{
                            max: 60,
                            min: 1,
                        }}
                        helperText="Please enter the duration of session (Please Note: Duration cannot exceed 300 minutes)"
                    />

                    <TextField
                        label="Max Limit (in Numbers)"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={maxLimit}
                        onChange={(e) => setMaxLimit(e.target.value)}
                        sx={styles.input}
                        inputProps={{
                            max: 100,
                            min: 1,
                        }}
                        helperText="Max limit is the maximum number of users who can book your post, it cannot exceed 100."
                    />

                    <TextField
                        label="Price in INR"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        sx={styles.input}
                        inputProps={{
                            max: 100,
                            min: 0,
                        }}
                        helperText="Price value you enter will be the price of booking your post per user (Please note: Price cannot exceed INR 100)"
                    />
                    <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                        <TextField
                            label="Select Date"
                            type="date"
                            variant="outlined"
                            fullWidth
                            value={selectedDate}
                            onChange={handleDateChange}
                            required
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                // marginBottom: '1.5rem',
                                input: { color: '#ffffff' },
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: '#5555',
                                    '& fieldset': {
                                        borderColor: '#555555',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#aaaaaa',
                                },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1E90FF',
                                }
                            }}
                        /><FormHelperText sx={{ color: '#aaaaaa' }}>
                            Please select a date for the session.
                        </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                        <TextField
                            label="Start Time"
                            type="time"
                            variant="outlined"
                            fullWidth
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            required
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                // marginBottom: '1.5rem',
                                input: { color: '#ffffff' },
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: '#5555',
                                    '& fieldset': {
                                        borderColor: '#555555',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#aaaaaa',
                                },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1E90FF',
                                }
                            }}
                        /><FormHelperText sx={{ color: '#aaaaaa' }}>
                            Please select time for the session.
                        </FormHelperText>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel id="platform-select-label">Select Meeting Platform</InputLabel>
                        <Select
                            labelId="platform-select-label"
                            value={platform}
                            onChange={handlePlatformChange}
                            label="Select Meeting Platform"
                        >
                            <MenuItem value="googleMeet">Google Meet</MenuItem>
                            <MenuItem value="zoom">Zoom</MenuItem>
                            <MenuItem value="microsoftTeams">Microsoft Teams</MenuItem>
                        </Select>
                    </FormControl>


                    <TextField
                        label="Paste Meeting Link"
                        variant="outlined"
                        fullWidth
                        value={meetingLink}
                        onChange={handleLinkChange}
                        error={isValid === false}
                        helperText={isValid === false && meetingLink.trim() !== "" ? errorMessage : ""}
                    />

                    {isValid === true && meetingLink.trim() !== "" && <p style={{ color: "green" }}>Link is valid!</p>}
                    {isValid === false && meetingLink.trim() !== "" && <p style={{ color: "red" }}>Invalid link for the selected platform.</p>}

                    {/* <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}> */}
                        {/* {googleMeetLink === '' && ( */}
                            {/* // <> */}
                                {/* <GenerateMeetingLinkButton userId={userId} onLinkGenerated={(link) => setGoogleMeetLink(link)} /> */}
                                {/* <GenerateMeetingLinkButton
                                    userId={userId}
                                    onLinkGenerated={(link) => setGoogleMeetLink(link)}
                                /> */}
                                {/* Helper Text */}
                                {/* <FormHelperText sx={{ color: '#aaaaaa' }}>
                                    Click the button to generate a Meet link and wait for the button to disappear.
                                </FormHelperText> */}
                            {/* </> */}
                        {/* // )} */}
                    {/* </FormControl> */}


                    <Button
                        variant="contained"
                        onClick={handlePost}
                        sx={styles.button}
                    >
                        Submit
                    </Button>
                </Box>

                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                >
                    <Alert
                        severity="error"
                        sx={{ width: '100%' }}
                        onClose={() => setOpenSnackbar(false)}
                    >
                        {error}
                    </Alert>
                </Snackbar>

            </Container>

        </div>
    );
};

export default CreatePost;
