import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';

// Typewriter Animation
const typing = keyframes`
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
`;

const TimeToGrowTypewriter = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000000', // Dark theme background
        height: '100vh', // Full viewport height
        mt: 30,
      }}
    >
      <Typography
  sx={{
    fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' }, // Responsive font size
    fontFamily: '"Times New Roman", Times, serif', // Apply Times New Roman font
    color: '#ffffff', // White text color
    textAlign: 'center',
    overflow: 'hidden', // Hide the text before it is typed out
    whiteSpace: 'nowrap', // Prevent wrapping
    animation: `${typing} 4s steps(12) 1s forwards`, // Apply typing animation
  }}
>
  Time To Grow
</Typography>

     
    </Box>
  );
};

export default TimeToGrowTypewriter;
