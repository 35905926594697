import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLoaderData, useLocation } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography, Box, Avatar, TextField, Button, Input, MenuItem, Select, InputLabel,FormHelperText, FormControl, Snackbar, Alert, IconButton, Chip, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { Edit, Save, AccountBalance, School, FileUpload, Email, Phone, Info, Language, Home, CalendarToday, LocationOn, Person, Lock, Delete, CheckCircle, Facebook, Instagram, Twitter, LinkedIn, YouTube, X } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header1 from '../Header/Header1';
import Header3 from '../Header/Header3';
import Footer from '../Footer/Footer';
import { Link as MuiLink } from '@mui/material';
import GenderIcon from '@mui/icons-material/Male';
import { Link } from 'react-router-dom';
import VerificationForm from '../Verification/VerificationForm';
import DigilockerVerificationForm from '../Verification/DigilockerVerificationForm';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RecommendationSetup from '../Recommendations/RecommendationSetup';
import avatar from './default-avatar.png'
import { MonetizationOn, PostAdd, Event } from '@mui/icons-material';


const API_URL = process.env.REACT_APP_API_URL;




const theme = createTheme({
  palette: {
    primary: {
      main: '#1111',
    },
    secondary: {
      main: '#1111',
    },
    background: {
      paper: '#1111',
    },
  },
});


const ProfileHeader = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #55555, #77777)',
  color: 'white',
  padding: theme.spacing(4),
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  borderRadius: '15px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  color: 'white',
  padding: theme.spacing(4),
  backgroundColor: '#1111',
  marginBottom: theme.spacing(10),
  borderColor: '#5555',
  "&:hover": {
    transform: "translateY(-3px)",
    borderColor: '#1E90FF',
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
  },
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(40),
  height: theme.spacing(40),
  borderRadius: '50%',
  border: `4px solid ${theme.palette.primary.main}`,
  marginRight: theme.spacing(4),
  borderColor: '#5555',
  "&:hover": {
    transform: "translateY(-3px)",
    borderColor: '#1E90FF',
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.1rem',
  marginBottom: theme.spacing(2),

}));

const InputField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  '& .MuiInputBase-root': {
    backgroundColor: '#2222',
    borderRadius: '10px',
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'white',
  }
}));


const DarkForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#2222',
  padding: '24px',
  borderRadius: '8px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
  width: '100%',
  maxWidth: '500px',
  margin: 'auto',
}));

const DarkFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#2222',
    color: 'white',
    '& fieldset': {
      borderColor: '#2222',
    },
    '&:hover fieldset': {
      borderColor: '#3333',
    },
  },
}));

const DarkMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: '#2222',
  color: 'white',
  '&:hover': {
    backgroundColor: '#444',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 2, // Applying border-radius to MenuItem (if applicable)
  },
  '& .MuiInputLabel-root': {
    color: 'white', // Making label color white
  },
}));

// Dark Select with sx styling
const DarkSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: theme.spacing(2),
    backgroundColor: '#2222',
    color: 'white',
    borderRadius: '10px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 2, // Applying border-radius to input (for Select)
  },
  '& .MuiInputLabel-root': {
    color: 'white', // Making label color white
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2222',
  color: 'black',
  padding: theme.spacing(1, 4),
  borderRadius: '25px',
  '&:hover': {
    backgroundColor: '#1E90FF',
  },
  marginTop: theme.spacing(2),
}));


function UserProfile({ onLogout }) {

  const { userId } = useParams();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const [language, setLanguage] = useState('en');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [userStats, setUserStats] = useState({
    totalEarnings: 0,
    postsCount: 0,
    totalBookings: 0,
  });


  const navigate = useNavigate();


  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userData, setUserData] = useState({
    "name": "",
    "about": "",
    "contact": "",
    "address": "",
    "created_at": "",
    "dob": "",
    "email": "",
    "gender": "",
    "id": "",
    "image": "",
    "languages": "",
    "password": "",
    "rating": "",
    "role": "",
    "status": "",
    "surname": "",
    "city": "",
    "state": "",
    "country": "",
    "pincode": "",
    "updated_at": "",
    "facebook": "",
    "instagram": "",
    "x": "",
    "linkedin": "",
    "youtube": "",
    "currentLevel": "Advanced",
    "totalTimeLearning": "150 hrs",
    "totalTimeTeaching": "50 hrs",
    "totalSessions": "25",
    "ratings": "4.8/5",
  });

  const [image, setImage] = useState(null);
  const [error, setError] = useState('');


  // const location = useLocation();  // Track the current route location

  //   useEffect(() => {
  //       // Check if a route is saved in localStorage
  //       const savedRoute = localStorage.getItem('currentRoute');
  //       console.log('savedRoute', savedRoute);

  //       if (savedRoute) {
  //           // Navigate to the saved route when the app starts
  //           navigate(savedRoute);
  //       }
  //   }, [navigate]);

  //   useEffect(() => {
  //       // Save the current route (pathname + search) in localStorage whenever the route changes
  //       const currentPath = location.pathname + location.search;  // includes any query parameters
  //       localStorage.setItem('currentRoute', currentPath);
  //       console.log('Route saved to localStorage:', currentPath);
  //   }, [location]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > 50 * 1024) {  // 50 KB in bytes
        setError('File size exceeds the 50KB limit.');
        setImage(null);  // Clear the image if it exceeds the limit
      } else {
        setError('');
        setImage(file);
      }
    }
};


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!image) {
      setError('Please select an image.');
      return;
    }

    const formData = new FormData();
    formData.append('profilePicture', image);
    formData.append('userId', userId);

    try {
      const response = await axios.patch(`${API_URL}/update_profile_picture/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Profile picture updated successfully.');
      window.location.reload();
    } catch (error) {
      console.error('Error updating profile picture:', error);
      alert('There was an error updating your profile picture. Please try again later.');
    }
  };





  useEffect(() => {
    if (!userId) {
      return;
    }

    axios
      .get(`${API_URL}/get_user_data/?user_id=${userId}`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userId]);


  const extractLanguages = (data) => {
    const allLanguages = [];

    data.forEach(item => {
      allLanguages.push({
        id: item.id,   // Use 'id' as the value
        name: item.name,  // Display the 'name' in the dropdown
      });
    });

    setLanguages(allLanguages);  // Set languages as an array of objects
  };

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await fetch(`${API_URL}/list_sections_and_subsections/`);
        const data = await response.json();
        extractLanguages(data.languages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchSections();
  }, []);


  useEffect(() => {
    // Fetch total earnings, posts, and bookings for the user
    const fetchUserStats = async () => {
      try {
        // Make the API call
        const response = await axios.get(`${API_URL}/get_user_stats/?user_id=${userId}`);

        // Set the response data in the state
        setUserStats({
          totalEarnings: response.data.total_earnings,
          totalPosts: response.data.posts_count,
          totalBookings: response.data.total_bookings_made,
          paybleAmount: response.data.payable_amount
        });
      } catch (err) {
        setError('Error fetching user stats');
      }
    };

    fetchUserStats();
  }, []);


  const handleLanguageChange = (e) => {
    setUserData({
      ...userData,
      language: e.target.value
    });
  };



  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };



  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('New passwords do not match.');
      return;
    }

    try {
      const payload = { userId, newPassword };

      if (userData.password) {
        payload.currentPassword = currentPassword;
      }

      await axios.patch(`${API_URL}/change_user_password/`, payload);
      alert('Password changed successfully.');
      setShowChangePassword(false);
    } catch (error) {
      console.error('Error changing password:', error);
      alert('There was an error changing your password. Please try again later.');
    }
  };



  const [openSnackbar, setOpenSnackbar] = useState(false);


  const handleChange = (e, name) => {
    setUserData({
      ...userData,
      [name]: e.target.value
    });
  };

  const handleChangePreferences = () => {
    navigate(`/recommendation-setup`);
  };

  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSubSection, setSelectedSubSection] = useState('');

  const [isEditing, setIsEditing] = useState(false);


  const toggleEditMode = async () => {
    if (isEditing) {
      try {
        const response = await axios.put(`${API_URL}/update_users/${userId}/`, userData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}` // Assuming you're using JWT or any token for auth
          }
        });
        alert('Profile updated successfully!');
        window.location.reload();
      } catch (error) {
        console.error('Error updating profile:', error);
        alert('Failed to update profile. Please try again.');
      }
    }
    setIsEditing(!isEditing);
  };


  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        await axios.patch(`${API_URL}/deactivate_user/${userId}/`);
        alert('Account deactivated successfully.');
        onLogout();
      } catch (error) {
        console.error('Error deactivating account:', error);
        alert('There was an error deactivating your account. Please try again later.');
      }
    }
  };

  const specializedFields = userData?.specialized_fields || [];
  const roles = userData?.roles || [];



  return (
    <div>
      <Header1 userId={userId} onLogout={onLogout} />
      {/* <Box sx={{ display: 'flex', flexDirection: 'column',flexWrap: 'wrap', minHeight: '100vh', backgroundColor: 'black' }}> */}
      {/* Title */}
      <Box sx={{ textAlign: 'center', py: 4, mt: 10 }}>
        <Typography variant="h2" sx={{ fontWeight: 'bold', color: 'white' }}>
          User Portal
        </Typography>
        {/* <Typography variant="subtitle1" sx={{ color: 'white', mt: 1 }}>
                          Unlock premium features and resources tailored for your needs
                      </Typography> */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          minHeight: '100vh',
          backgroundColor: 'black',
          padding: 2,  // Add padding for spacing
          gap: 2,      // Add space between items in the column
          justifyContent: 'flex-start',
          alignItems: 'center',  // Center items horizontally in smaller screens
          width: '100%',  // Make sure it spans the full width of the screen
          '@media (min-width: 600px)': {
            flexDirection: 'row', // On larger screens, switch to row layout
            justifyContent: 'space-evenly', // Distribute space evenly between items
            alignItems: 'center', // Align items centrally along the row
          },
          '@media (min-width: 1024px)': {
            padding: 4, // Increase padding on larger screens
            gap: 3,     // Increase the gap for a more spacious layout
          },
        }}
      >


        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          sx={{
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',  // Centers content horizontally
            alignItems: 'center'       // Centers content vertically
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' }, // Column on small screens, row on medium and up
              flexWrap: 'wrap', // Allow links to wrap if needed
              gap: 2, // Space between links
              backgroundColor: '#1111',
              alignItems: 'center',
              justifyContent: 'center',
              padding: { xs: 1, sm: 2, md: 3 }, // Adjust padding based on screen size
              borderRadius: 2,
              boxShadow: 2,
              borderColor: '#5555',
              "&:hover": {
                transform: "translateY(-3px)",
                borderColor: '#1E90FF',
                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 1,
              flexWrap: 'wrap',
              justifyContent: { xs: 'center', sm: 'center' }, // Center content for small screens
              alignItems: { xs: 'center', sm: 'center' } // Center content vertically for small screens
            }}>
              <MuiLink
                href="#profile-header"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                  },
                }}
              >
                Profile
              </MuiLink>
              <MuiLink
                href="#performance"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                  },
                }}
              >
                Analytics
              </MuiLink>
              <MuiLink
                href="#preferences"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                  },
                }}
              >
                Preferences
              </MuiLink>

              <MuiLink
                href="#about"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                  },
                }}
              >
                About
              </MuiLink>

              <MuiLink
                href="#personal-info"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                  },
                }}
              >
                Personal
              </MuiLink>

              <MuiLink
                href="#contact-info"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                  },
                }}
              >
                Contacts
              </MuiLink>

              <MuiLink
                href="#social-media-info"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                  },
                }}
              >
                Social
              </MuiLink>

              <MuiLink
                href="#address-info"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                  },
                }}
              >
                Address
              </MuiLink>

              {/* <MuiLink
        href="#language-selection"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Language
      </MuiLink> */}

              <MuiLink
                href="#change-password"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                  },
                }}
              >
                Password
              </MuiLink>

              <MuiLink
                href="#delete-account"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                  borderRadius: 1,
                  color: '#f5f5f5',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#2222',
                    color: '#1E90FF',
                  },
                }}
              >
                Delete
              </MuiLink>
            </Box>
          </Box>
        </Grid>



        <ThemeProvider theme={theme}>
          <Container
            maxWidth="lg"
            sx={{
              marginTop: 10,
              mb: 10,
              display: 'flex',
              justifyContent: 'center',  // Centers content horizontally
              alignItems: 'center',      // Centers content vertically
              flexDirection: 'column'    // Ensures that the items are stacked vertically
            }}
          >
            <Grid container spacing={4} justifyContent="center" alignItems={'center'}>


              <Grid
                item
                xs={15}
                sm={10}
                md={8}
                lg={8}    // Custom size for large screens
                xl={10}    // Custom size for extra-large screens
                xxl={10}   // Custom size for even larger screens (if needed)
              >
                <Box id="profile-header" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}




                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {/* <Box
                        sx={{
                          display: 'flex', // Use Flexbox for alignment
                          justifyContent: 'center', // Horizontally center the image
                          alignItems: 'center', // Vertically center the image
                          width: '100%', // Full width to ensure the Box takes up the available space
                          height: 0, // Remove any default height to maintain square
                          paddingBottom: '100%', // Use padding trick to keep aspect ratio 1:1 (square)
                          position: 'relative', // Position relative for the image
                        }}
                      >
                        <img
                          src={userData.image ? `${API_URL}${userData.image}` : avatar}
                          alt={`${userData.name} ${userData.surname}`}
                          style={{
                            position: 'absolute', // Absolute position to fill the container
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: '100%',  // Full width to ensure the image fills the container
                            height: '100%', // Full height to ensure the image is square
                            borderRadius: '50%', // Circular shape
                            border: '2px solid #5555',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            cursor: 'pointer',
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.transform = 'translateY(-3px)';
                            e.target.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.15)';
                            e.target.style.borderColor = '#1E90FF'; // Change border color on hover
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.transform = 'translateY(0)';
                            e.target.style.boxShadow = 'none';
                            e.target.style.borderColor = '#5555'; // Reset border color
                          }}
                        />
                      </Box> */}

{/* <img
  src={userData.image ? `${API_URL}${userData.image}` : avatar}
  alt={`${userData.name} ${userData.surname}`}
  style={{
    // Default values for circular shape
    width: '100%',           // Make image responsive to container width
    maxWidth: '200px',       // Maximum size for larger screens
    minWidth: '120px',       // Minimum width to avoid shrinking below this size
    height: '100%',          // Keep height equal to width for circular shape
    maxHeight: '200px',      // Maximum height for larger screens
    minHeight: '120px',      // Minimum height to avoid shrinking
    objectFit: 'cover',      // Crop image to fit within the circle
    borderRadius: '50%',     // Circular profile picture
    borderColor: '#5555',
    border: '1.5px solid #6666',
    transition: 'transform 0.3s ease, border-color 0.3s ease', // Smooth transition on hover

    // Responsive styles using Material-UI's sx prop with breakpoints
    '@media (max-width: 768px)': {
      width: '120px',   // Set smaller size on mobile
      height: '120px',  // Equal width and height for circular shape
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      width: '150px',   // Medium screens
      height: '150px',  // Equal width and height for circular shape
    },
    '@media (min-width: 1024px)': {
      width: '200px',   // Larger screens
      height: '200px',  // Equal width and height for circular shape
    },
  }}
  onMouseEnter={(e) => {
    // Enlarge image and change border color on hover
    e.target.style.transform = 'scale(1.1)'; // Enlarge image to 110%
    e.target.style.borderColor = '#1E90FF';  // Change border color to light blue
  }}
  onMouseLeave={(e) => {
    // Reset image to original size and border color when hover ends
    e.target.style.transform = 'scale(1)';   // Reset to original size
    e.target.style.borderColor = '#6666';    // Reset border color to original
  }}
/> */}

<Avatar sx={{
                                        width: 150,
                                        height: 150,
                                        // mr: 2,
                                      }} src={`${API_URL}${userData.image}` || '/default-avatar.png'} alt={userData.name || 'User'} />


                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
                        {userData.facebook && (
                          <a href={userData.facebook} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{
                              color: 'white',
                              marginBottom: { xs: 0.5, sm: 1 }, // Responsive margin bottom
                              '&:hover': { color: '#1E90FF' }
                            }}>
                              <Facebook sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }} />
                            </IconButton>
                          </a>
                        )}

                        {userData.instagram && (
                          <a href={userData.instagram} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{
                              color: 'white',
                              marginBottom: { xs: 0.5, sm: 1 }, // Responsive margin bottom
                              '&:hover': { color: '#1E90FF' }
                            }}>
                              <Instagram sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }} />
                            </IconButton>
                          </a>
                        )}

                        {userData.youtube && (
                          <a href={userData.youtube} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{
                              color: 'white',
                              marginBottom: { xs: 0.5, sm: 1 }, // Responsive margin bottom
                              '&:hover': { color: '#1E90FF' }
                            }}>
                              <YouTube sx={{ fontSize: { xs: '1.8rem', sm: '2.3rem', md: '2.8rem' } }} />
                            </IconButton>
                          </a>
                        )}

                        {userData.x && (
                          <a href={userData.x} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{
                              color: 'white',
                              marginBottom: { xs: 0.5, sm: 1 }, // Responsive margin bottom
                              '&:hover': { color: '#1E90FF' }
                            }}>
                              <X sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }} />
                            </IconButton>
                          </a>
                        )}

                        {userData.linkedin && (
                          <a href={userData.linkedin} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{
                              color: 'white',
                              marginBottom: { xs: 0.5, sm: 1 }, // Responsive margin bottom
                              '&:hover': { color: '#1E90FF' }
                            }}>
                              <LinkedIn sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }} />
                            </IconButton>
                          </a>
                        )}



                      </Box>



                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 5 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 5, mt: 3 }}>
                          <Typography
                            variant="h4"
                            fontWeight="bold"
                            color="white"
                            sx={{
                              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' }, // Adjust font size for different screen sizes
                              display: 'flex',  // Use flexbox to arrange the name and surname in a row
                              alignItems: 'center', // Vertically align the text
                            }}
                          >
                            <span>{userData.name}</span>
                            <span style={{ marginLeft: '10px' }}>{userData.surname}</span>
                          </Typography>

                        </Box>

                        {isEditing && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              backgroundColor: '#5555',
                              padding: 1,
                              borderRadius: '8px',
                              boxShadow: 3,
                              width: '50%',
                            }}
                          >
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                              <Input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                fullWidth
                                sx={{
                                  backgroundColor: '#5555',
                                  color: 'white',
                                  '& input': {
                                    padding: '10px',
                                  },
                                }}
                              />
                              <FormHelperText sx={{ color: 'gray' }}>
  Max file size: 50 KB
</FormHelperText>
                              {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}

                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{
                                  width: '100%',
                                  marginTop: '16px',
                                  backgroundColor: '#0077ff',
                                  '&:hover': { backgroundColor: '#005bb5' },
                                }}
                              >
                                Update Profile Picture
                              </Button>
                            </form>
                          </Box>
                        )}

                        {/* Edit Icon */}
                        <IconButton onClick={toggleEditMode} sx={{ color: 'white', marginTop: 2 }}>
                          {/* <Edit /> */}
                          {isEditing ? <Save /> : <Edit />}
                        </IconButton>
                      </Box>

                    </Box>
                  {/* </Box> */}
                </Box>
                {/* <Grid sx={{ zIndex: 10, mr: 4, mt: 11 }}> */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      background: 'linear-gradient(135deg, #4e73df 0%, #2a59f4 100%)',
                      alignItems: 'center',
                      textAlign: 'center',
                      justifyContent: 'center',
                      padding: { xs: 3, sm: 5 }, // Adjust padding for different screen sizes
                      borderRadius: 4,
                      boxShadow: 2,
                      borderColor: '#5555',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="white"
                      sx={{
                        marginBottom: 3,
                        fontSize: { xs: '1rem', sm: '1.25rem' },
                        textAlign: 'center',
                      }}
                    >
                      Analytics
                    </Typography>

                    {/* Current Level */}
                    <Box id="performance"
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr', lg: '1fr 1fr 1fr 1fr' },
                        gap: 2,  // Add space between items
                        padding: 2,
                        width: '100%',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 1,
                      }}
                    >
                      <Typography variant="h7" sx={{ color: 'white' }}>
                        Experience: {userData.Experience} Mins
                      </Typography>
                      <Typography variant="h7" sx={{ color: 'white' }}>
                        User Ratings: {userData.rating}/5
                      </Typography>
                      <Typography variant="h7" sx={{ color: 'white' }}>
                        Total Earnings: ₹{userStats.totalEarnings.toFixed(2)}
                      </Typography>
                      <Typography variant="h7" sx={{ color: 'white' }}>
                        Total Posts: {userStats.totalPosts}
                      </Typography>
                      <Typography variant="h7" sx={{ color: 'white' }}>
                        Total Bookings: {userStats.totalBookings}
                      </Typography>
                      <Typography variant="h7" sx={{ color: 'white' }}>
                        Amount Payable: ₹{userStats.paybleAmount}
                      </Typography>
                    </Box>

                  </Box>
                {/* </Grid> */}


                {/* <RecommendationSetup/> */}
                <Box
                  sx={{
                    display: 'flex',          // Enable Flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    minHeight: '20vh',       // Ensure it takes the full viewport height
                    padding: 2,               // Optional padding around the container
                  }}
                >
                  <Button
                    onClick={handleChangePreferences}
                    variant="contained"
                    color="primary"
                    sx={{
                      padding: '10px 20px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      backgroundColor: '#1E90FF',
                      mt: 10,
                      borderRadius: '30px',
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                      textTransform: 'none',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        backgroundColor: '#1E90FF',
                        transform: 'scale(1.05)',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                      },
                      '&:active': {
                        backgroundColor: '#303f9f',
                        boxShadow: 'none',
                        transform: 'scale(0.98)',
                      },
                      '@media (max-width: 600px)': {
                        padding: '8px 16px',
                        fontSize: '14px',
                        borderRadius: '25px',
                      },
                      '@media (max-width: 400px)': {
                        padding: '6px 12px',
                        fontSize: '12px',
                        borderRadius: '20px',
                      },
                    }}

                  >
                    Change My Preferences
                  </Button>
                </Box >

                <Box id="preferences" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 5, mt: 3, width: '100%' }}>

                  {/* Display Specialized Fields only if data is available */}
                  {/* {specializedFields.length > 0 && (
    <Box sx={{ mb: 3, width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs="auto">
          <Typography variant="subtitle1" color="white" sx={{ fontWeight: 'bold' }}>
            Specialized Fields:
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Grid container spacing={2} justifyContent="center">
            {specializedFields.map((field, index) => (
              <Grid item key={index}>
                <Chip
                  label={field}
                  color="primary"
                  sx={{
                    fontWeight: 'bold',
                    borderRadius: '20px',
                    backgroundColor: '#1E90FF',
                    paddingX: '16px',
                    paddingY: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )} */}

                  {/* Display Roles only if data is available */}
                  {/* {roles.length > 0 && (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs="auto">
          <Typography variant="subtitle1" color="white" sx={{ fontWeight: 'bold' }}>
            Roles:
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Grid container spacing={2} justifyContent="center">
            {roles.map((role, index) => (
              <Grid item key={index}>
                <Chip
                  label={role}
                  color="secondary"
                  sx={{
                    fontWeight: 'bold',
                    borderRadius: '20px',
                    backgroundColor: '#1E90FF',
                    paddingX: '16px',
                    paddingY: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )} */}

                </Box>


                {/* About Me Card */}
                <Box
                  id="about"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4,
                    backgroundColor: '#000000',  // Dark background for modern look
                    borderRadius: 2,
                    boxShadow: 3,  // Add shadow for depth
                    maxWidth: '800px', // Limit the width for large screens
                    margin: '0 auto',  // Center on the page
                    marginTop: 10,  // Add top margin for space
                    textAlign: 'center', // Center the text
                    width: '100%',  // Make it responsive
                    border: '1px solid #ddd',// Add a white border
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <SectionTitle sx={{ color: 'white', marginBottom: 2 }}>About Me</SectionTitle>

                    {/* Conditionally render helper text */}
                    {userData.about === null && (
                      <Typography variant="body2" sx={{ color: 'gray', marginBottom: 3 }}>
                        Describe yourself in a few words...
                      </Typography>
                    )}

                    {isEditing ? (
                      <InputField
                        name="aboutMe"
                        value={userData.about || ''}
                        onChange={(e) => handleChange(e, 'about')}
                        label="About Me"
                        color="black"
                        backgroundColor="#f0f0f0"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: 2,  // Rounded corners for input field
                          },
                          '& .MuiInputLabel-root': {
                            color: 'white',  // Black label for better contrast
                          },
                          '& .MuiInputBase-root': {
                            padding: '12px 14px',  // Add padding for spacious input
                          },
                        }}
                      />
                    ) : (
                      <Typography variant="body1" color="white" sx={{ marginTop: 2 }}>
                        {userData.about || 'No description available'}
                      </Typography>
                    )}
                  </Box>
                </Box>


                {/* Personal Information Card */}
                <Box
                  id="personal-info"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4,
                    backgroundColor: '#000000',  // Dark background for modern look
                    borderRadius: 3,
                    boxShadow: 5,  // Add shadow for depth
                    maxWidth: '900px', // Limit the width for large screens
                    margin: '0 auto',  // Center on the page
                    marginTop: 10,  // Add top margin for space
                    textAlign: 'center', // Center the text
                    width: '100%',  // Make it responsive
                    border: '1px solid #ddd', // Add border (white in this case)
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <SectionTitle sx={{ color: 'white', marginBottom: 3 }}>
                      {/* <Person sx={{ fontSize: '2rem', marginRight: 2 }} />  */}
                      Personal Info
                    </SectionTitle>

                    <Grid container spacing={3}>
                      {/* First Name */}
                      <Grid item xs={12} sm={6}>
                        {!isEditing ? (
                          <Typography variant="body1" color="white">
                            First Name: {userData.name}
                          </Typography>
                        ) : (
                          <InputField
                            name="name"
                            value={userData.name}
                            onChange={(e) => handleChange(e, 'name')}
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            color="black"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: 2, // Rounded corners for input field
                              },
                              '& .MuiInputLabel-root': {
                                color: 'white',  // Black label for better contrast
                              },
                              '& .MuiInputBase-root': {
                                padding: '14px',  // Add padding for spacious input
                              },
                            }}
                          />
                        )}
                      </Grid>

                      {/* Last Name */}
                      <Grid item xs={12} sm={6}>
                        {!isEditing ? (
                          <Typography variant="body1" color="white">
                            Last Name: {userData.surname}
                          </Typography>
                        ) : (
                          <InputField
                            name="surname"
                            value={userData.surname}
                            onChange={(e) => handleChange(e, 'surname')}
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            color="black"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                              },
                              '& .MuiInputLabel-root': {
                                color: 'white',
                              },
                            }}
                          />
                        )}
                      </Grid>

                      {/* Date of Birth */}
                      <Grid item xs={12} sm={6}>
                        {!isEditing ? (
                          <Typography variant="body1" color="white">
                            Date of Birth: {userData.dob}
                          </Typography>
                        ) : (
                          <InputField
                            name="dob"
                            value={userData.dob}
                            onChange={(e) => handleChange(e, 'dob')}
                            label="Date of Birth"
                            variant="outlined"
                            type="date"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                              },
                              '& .MuiInputLabel-root': {
                                color: 'white',
                              },
                            }}
                          />
                        )}
                      </Grid>

                      {/* Gender */}
                      <Grid item xs={12} sm={6}>
  {!isEditing ? (
    <Typography variant="body1" color="white">
      Gender: {userData.gender}
    </Typography>
  ) : (
    <DarkSelect
      name="gender"
      value={userData.gender}
      onChange={(e) => handleChange(e, 'gender')}
      label="Gender"
      variant="outlined"
      fullWidth
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: 2,
        },
        '& .MuiInputLabel-root': {
          color: 'white', // Ensure label text is white
        },
        '& .MuiSelect-select': {
          color: 'white', // Ensures text inside the select box is white
        },
      }}
    >
      <DarkMenuItem value="Male">Male</DarkMenuItem>
      <DarkMenuItem value="Female">Female</DarkMenuItem>
      <DarkMenuItem value="Other">Other</DarkMenuItem>
    </DarkSelect>
  )}
</Grid>

                    </Grid>
                  </Box>
                </Box>




                <Box
                  id="contact-info"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4,
                    backgroundColor: '#000000',  // Dark background for modern look
                    borderRadius: 3,
                    boxShadow: 5,  // Add shadow for depth
                    maxWidth: '900px', // Limit the width for large screens
                    margin: '0 auto',  // Center on the page
                    marginTop: 10,  // Add top margin for space
                    width: '100%',  // Make it responsive
                    border: '1px solid #ddd',  // Add border (white in this case)
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',  // Ensure the content is stacked vertically
                      alignItems: 'center',  // Center-align the content horizontally
                    }}
                  >
                    <SectionTitle sx={{ color: 'white', marginBottom: 3 }}>Contact Info</SectionTitle>

                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                      <Grid item xs={12} sm={6}>
                        {!isEditing && (
                          <Typography variant="body1" color="white">
                            <Email sx={{ fontSize: '1.2rem', marginRight: 1 }} /> Email: {userData.email}
                          </Typography>
                        )}
                        {isEditing && (
                          <InputField
                            name="email"
                            value={userData.email}
                            onChange={(e) => handleChange(e, 'email')}
                            label="Email"
                            variant="outlined"
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                              },
                              '& .MuiInputLabel-root': {
                                color: 'white',
                              },
                            }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {!isEditing && (
                          <Typography variant="body1" color="white">
                            <Phone sx={{ fontSize: '1.2rem', marginRight: 1 }} /> Contact: {userData.contact}
                          </Typography>
                        )}
                        {isEditing && (
                          <InputField
                            name="contact"
                            value={userData.contact}
                            onChange={(e) => handleChange(e, 'contact')}
                            label="Phone Number"
                            variant="outlined"
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                              },
                              '& .MuiInputLabel-root': {
                                color: 'white',
                              },
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>


                {/* <VerificationForm/>
      <DigilockerVerificationForm/> */}

                {/* Social Media Profiles Info Card */}
                <Box
  id="social-media-info"
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    backgroundColor: 'background.paper',
    boxShadow: 3,
    mt: 10,
    borderRadius: 2,
    maxWidth: '900px',
    border: '1px solid #ddd',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: 6,
    },
  }}
>
  <Box sx={{ width: '100%' }}>
    <SectionTitle
      sx={{
        textAlign: 'center',
        marginBottom: 3,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#ffff',
      }}
    >
      Social Media Profiles
    </SectionTitle>
    <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
      {/* Facebook */}
      <Grid item xs={12} sm={6} md={4}>
        {!isEditing ? (
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '0.875rem',
                sm: '1rem',
              },
              display: 'flex',
              alignItems: 'center',
              marginRight: {
                xs: 0.5,
                sm: 1,
              },
              padding: '0.5rem 0', // Add padding to avoid tightness
            }}
          >
            <Facebook sx={{ marginRight: 1 }} /> Facebook: {userData.facebook}
          </Typography>
        ) : (
          <InputField
            name="facebook"
            value={userData.facebook}
            onChange={(e) => handleChange(e, 'facebook')}
            label="Facebook URL"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1.5,
                fontSize: '1rem',
              },
              padding: '0.5rem', // Padding to avoid tight input areas
            }}
          />
        )}
      </Grid>

      {/* Instagram */}
      <Grid item xs={12} sm={6} md={4}>
        {!isEditing ? (
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '0.875rem',
                sm: '1rem',
              },
              display: 'flex',
              alignItems: 'center',
              marginRight: {
                xs: 0.5,
                sm: 1,
              },
              padding: '0.5rem 0',
            }}
          >
            <Instagram sx={{ marginRight: 1 }} /> Instagram: {userData.instagram}
          </Typography>
        ) : (
          <InputField
            name="instagram"
            value={userData.instagram}
            onChange={(e) => handleChange(e, 'instagram')}
            label="Instagram URL"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1.5,
                fontSize: '1rem',
              },
              padding: '0.5rem',
            }}
          />
        )}
      </Grid>

      {/* X (formerly Twitter) */}
      <Grid item xs={12} sm={6} md={4}>
        {!isEditing ? (
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '0.875rem',
                sm: '1rem',
              },
              display: 'flex',
              alignItems: 'center',
              marginRight: {
                xs: 0.5,
                sm: 1,
              },
              padding: '0.5rem 0',
            }}
          >
            <X sx={{ marginRight: 1 }} /> X: {userData.x}
          </Typography>
        ) : (
          <InputField
            name="x"
            value={userData.x}
            onChange={(e) => handleChange(e, 'x')}
            label="X URL"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1.5,
                fontSize: '1rem',
              },
              padding: '0.5rem',
            }}
          />
        )}
      </Grid>

      {/* LinkedIn */}
      <Grid item xs={12} sm={6} md={4}>
        {!isEditing ? (
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '0.875rem',
                sm: '1rem',
              },
              display: 'flex',
              alignItems: 'center',
              marginRight: {
                xs: 0.5,
                sm: 1,
              },
              padding: '0.5rem 0',
            }}
          >
            <LinkedIn sx={{ marginRight: 1 }} /> LinkedIn: {userData.linkedin}
          </Typography>
        ) : (
          <InputField
            name="linkedin"
            value={userData.linkedin}
            onChange={(e) => handleChange(e, 'linkedin')}
            label="LinkedIn URL"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1.5,
                fontSize: '1rem',
              },
              padding: '0.5rem',
            }}
          />
        )}
      </Grid>

      {/* YouTube */}
      <Grid item xs={12} sm={6} md={4}>
        {!isEditing ? (
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '0.875rem',
                sm: '1rem',
              },
              display: 'flex',
              alignItems: 'center',
              marginRight: {
                xs: 0.5,
                sm: 1,
              },
              padding: '0.5rem 0',
            }}
          >
            <YouTube sx={{ marginRight: 1 }} /> YouTube: {userData.youtube}
          </Typography>
        ) : (
          <InputField
            name="youtube"
            value={userData.youtube}
            onChange={(e) => handleChange(e, 'youtube')}
            label="YouTube URL"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1.5,
                fontSize: '1rem',
              },
              padding: '0.5rem',
            }}
          />
        )}
      </Grid>
    </Grid>
  </Box>
</Box>



                {/* Address Information Card */}
                <Box
                  id="address-info"
                  sx={{
                    display: 'flex',

                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4,
                    backgroundColor: 'background.paper',
                    boxShadow: 3,
                    borderRadius: 2,
                    mt: 10,
                    maxWidth: '900px',
                    // margin: 'auto',
                    border: '1px solid #ddd',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: 6
                    },
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <SectionTitle
                      sx={{
                        textAlign: 'center',
                        marginBottom: 3,
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        color: '#ffff'
                      }}
                    >
                      <LocationOn sx={{ marginRight: 1 }} /> Address Info
                    </SectionTitle>

                    <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                      {/* Address Line Field */}
                      <Grid item xs={12}>
                        {!isEditing ? (
                          <Typography
                            variant="body1"
                            sx={{
                              color: 'gray',  // Default color
                              fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }, // Adjust font size for responsiveness
                              lineHeight: { xs: 1.4, sm: 1.6, md: 1.8 }, // Adjust line height for better readability
                              wordBreak: 'break-word', // Ensures long words break correctly on smaller screens
                            }}
                          >
                            Address Line: {userData.address || <span style={{ color: 'gray' }}>Not available</span>}
                          </Typography>

                        ) : (
                          <>
                            <InputField
                              name="address"
                              value={userData.address}
                              onChange={(e) => handleChange(e, 'address')}
                              label="Address Line"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 100 }} // Set max length to 100 characters
                            />
                            <Typography variant="body2" color="gray" sx={{ marginTop: 1 }}>
                              (Max 100 characters)
                            </Typography>
                          </>
                        )}
                      </Grid>

                      {/* City Field */}
                      <Grid item xs={12} sm={6}>
                        {!isEditing ? (
                          <Typography variant="body1">
                            City: {userData.city || <span style={{ color: 'gray' }}>Not available</span>}
                          </Typography>
                        ) : (
                          <InputField
                            name="city"
                            value={userData.city}
                            onChange={(e) => handleChange(e, 'city')}
                            label="City"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>

                      {/* State Field */}
                      <Grid item xs={12} sm={6}>
                        {!isEditing ? (
                          <Typography variant="body1">
                            State: {userData.state || <span style={{ color: 'gray' }}>Not available</span>}
                          </Typography>
                        ) : (
                          <InputField
                            name="state"
                            value={userData.state}
                            onChange={(e) => handleChange(e, 'state')}
                            label="State"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>

                      {/* Country Field */}
                      <Grid item xs={12} sm={6}>
                        {!isEditing ? (
                          <Typography variant="body1">
                            Country: {userData.country || <span style={{ color: 'gray' }}>Not available</span>}
                          </Typography>
                        ) : (
                          <InputField
                            name="country"
                            value={userData.country}
                            onChange={(e) => handleChange(e, 'country')}
                            label="Country"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>

                      {/* Pincode Field */}
                      <Grid item xs={12} sm={6}>
                        {!isEditing ? (
                          <Typography variant="body1">
                            Pincode: {userData.pincode || <span style={{ color: 'gray' }}>Not available</span>}
                          </Typography>
                        ) : (
                          <InputField
                            name="pincode"
                            value={userData.pincode}
                            onChange={(e) => handleChange(e, 'pincode')}
                            label="Pincode"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>


                {/* Language Selection Card */}
                {/* <ProfileCard id="language-selection">
                  <CardContent>
                    <SectionTitle>Preferred Languages</SectionTitle>
                    <DarkFormControl fullWidth sx={{ color: 'white' }}>
                      <InputLabel sx={{ color: 'white' }}>Choose Language</InputLabel>
                      <DarkSelect
                        value={userData.languages}
                        onChange={handleLanguageChange}
                        label="Choose Language"
                        fullWidth
                        sx={{ color: 'white' }}
                      >
                        {languages.length > 0 ? (
                          languages.map((lang, index) => (
                            <DarkMenuItem key={index} sx={{ color: 'white' }} value={lang.code}>
                              {lang.name}
                            </DarkMenuItem>
                          ))
                        ) : (
                          <DarkMenuItem sx={{ color: 'white' }} disabled>
                            No Languages Available
                          </DarkMenuItem>
                        )}
                      </DarkSelect>
                    </DarkFormControl>
                  </CardContent>
                </ProfileCard> */}

                {/* Change Password Card */}
                <Box
                  id="change-password"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4,
                    backgroundColor: 'background.paper',
                    boxShadow: 3,
                    borderRadius: 2,

                    maxWidth: '600px',
                    margin: 'auto',
                    border: '1px solid #ddd',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: 6
                    },

                    mt: 10,  // Add margin-top (adjust the value as needed)
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <SectionTitle
                      sx={{
                        textAlign: 'center',
                        marginBottom: 3,
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        color: '#ffff'
                      }}
                    >
                      Change Password
                    </SectionTitle>

                    {/* Current Password Field (only if user has password) */}
                    {userData.password ? (
                      <InputField
                        label="Current Password"
                        type="password"
                        variant="outlined"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        fullWidth
                      />
                    ) : (
                      <Typography variant="body2" color="gray" sx={{ textAlign: 'center', marginTop: 2 }}>
                        Current password is not available.
                      </Typography>
                    )}

                    {/* New Password Field */}
                    <InputField
                      label="New Password"
                      type="password"
                      variant="outlined"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      fullWidth
                    />

                    {/* Confirm New Password Field */}
                    <InputField
                      label="Confirm New Password"
                      type="password"
                      variant="outlined"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      fullWidth
                    />

                    {/* Change Password Button */}
                    <Button
                      onClick={handleChangePassword}
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        marginTop: 3,
                        fontWeight: 'bold',
                        textTransform: 'none',
                        borderRadius: 1.5,
                      }}
                    >
                      Change Password
                    </Button>
                  </Box>
                </Box>


                {/* Delete Account Card */}
                <Box
                  id="delete-account"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4,
                    backgroundColor: 'background.paper',
                    boxShadow: 3,
                    borderRadius: 2,
                    maxWidth: '600px',
                    margin: 'auto',
                    mt: 10,
                    border: '1px solid #ddd',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: 6
                    },
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <SectionTitle
                      sx={{
                        textAlign: 'center',
                        marginBottom: 2,
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        color: 'white'
                      }}
                    >
                      Delete Account
                    </SectionTitle>

                    <Typography variant="body2" color="gray" sx={{ textAlign: 'center', marginBottom: 3 }}>
                      Deleting your account is permanent and cannot be undone.
                    </Typography>

                    {/* Display helper text if data is unavailable */}
                    {userData?.name ? (
                      <Typography variant="body2" color="#1E90FF" sx={{ textAlign: 'center', marginBottom: 3 }}>
                        Your account is currently active.
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="gray" sx={{ textAlign: 'center', marginBottom: 3 }}>
                        Account status is not available.
                      </Typography>
                    )}

                    <Button
                      onClick={handleDeleteAccount}
                      variant="contained"
                      color="error"
                      fullWidth
                      sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                        borderRadius: 1.5,
                        marginTop: 2,
                        '&:hover': {
                          backgroundColor: '#d32f2f',
                        }
                      }}
                    >
                      Delete Account
                    </Button>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>

        {/* Snackbar for feedback messages */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <IconButton
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}>
        <ArrowUpwardIcon />
      </IconButton>
      <Footer2 />
    </div>

  );
}

export default UserProfile;