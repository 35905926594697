import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Divider, IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ContactUs = ({userId, loggedIn, onLogout}) => {

    const navigate = useNavigate();
    
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleLogout = () => {
        onLogout(); // Call the passed logout function
        navigate('/login'); // Navigate to login page after logout
    };

  return (
    <div>
        {loggedIn ? (
        <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
      ) : (
        <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
      )}
    
    <Container maxWidth="md" sx={{ paddingTop: 4, mt:10 }}>
      {/* Title Section */}
      <Box textAlign="center">
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontSize: '26px', fontWeight: 'bold', color: '#ffffff' }}>
          Contact Us
        </Typography>
        <Typography variant="subtitle1" component="h2" sx={{ fontSize: '14px', color: '#595959' }}>
          <strong>Last updated:</strong> January 26, 2025
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Contact Information Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>Company Information</Typography>

        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body1" sx={{ fontSize: '16px', color: '#595959' }}>
            <strong>Company Name:</strong> Anthrasync Solutions Private Limited
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '16px', color: '#595959' }}>
            <strong>Address:</strong> No 26, Laskar Hosur Road, Adugodi, Bangalore South, Bengaluru, Karnataka 560030, India
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '16px', color: '#595959' }}>
            <strong>Email:</strong> official@anthrasync.com
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '16px', color: '#595959' }}>
            <strong>Phone:</strong> +91 7338259131
          </Typography>
        </Box>
      </Box>
      
    </Container>

    {/* Scroll to Top Button */}
    <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>

    <Footer2 />
  </div>
  );
};

export default ContactUs;
