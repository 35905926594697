import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Snackbar } from '@mui/material';
import axios from 'axios';
import Header1 from '../Header/Header1';

const API_URL = process.env.REACT_APP_API_URL;

const GeneralEmail = ({ userId, onLogout }) => {
  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/send_welcome_email/`, {
        email: email,
      });

      setSnackbarMessage(response.data.message);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setEmail('');
    } catch (error) {
      setSnackbarMessage(error.response?.data?.error || 'Something went wrong!');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
    <Header1 userId={userId} onLogout={onLogout} />
      <Typography variant="h5" gutterBottom>
        Send Welcome Email
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Send Welcome Email
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default GeneralEmail;
