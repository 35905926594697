import React from 'react';
import { Typography } from '@mui/material';

function ComingSoon() {
  const firstWord = "COMING";
  const secondWord = "SOON!";

  return (
    <div
      style={{
        background: 'linear-gradient(45deg, #000000, #000000)', // Dark gradient for dark theme
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '20px',
        color: '#fff', // Light text for dark background
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Coming Soon Text */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center', // Align letters horizontally
          justifyContent: 'center',
          flexDirection: 'row', // Ensure the words are in a single row
          fontFamily: 'Roboto, sans-serif',
          overflow: 'hidden',
        }}
      >
        {/* Display "COMING" with Slide In Animation */}
        {firstWord.split('').map((char, index) => (
          <Typography
            key={index}
            variant="h3"
            style={{
              color: '#1E90FF', // Dodger Blue color
              fontWeight: 'bold',
              fontSize: '6vw',
              margin: '0 5px',
              animation: `slideIn 0.5s ease-out ${index * 0.1}s forwards`,
              '@media (max-width: 600px)': { fontSize: '10vw' },
              '@media (max-width: 400px)': { fontSize: '12vw' },
            }}
          >
            {char}
          </Typography>
        ))}

        {/* Space between the words */}
        <span style={{ margin: '0 10px' }}>&nbsp;</span>

        {/* Display "SOON!" with Pulse Animation */}
        {secondWord.split('').map((char, index) => (
          <Typography
            key={index}
            variant="h3"
            style={{
              color: '#1E90FF', // Dodger Blue color
              fontWeight: 'bold',
              fontSize: '6vw',
              margin: '0 5px',
              animation: `pulse 1.5s infinite ease-in-out ${index * 0.1}s`,
              '@media (max-width: 600px)': { fontSize: '10vw' },
              '@media (max-width: 400px)': { fontSize: '12vw' },
            }}
          >
            {char}
          </Typography>
        ))}
      </div>

      <style>
        {`
          @keyframes slideIn {
            0% {
              transform: translateX(-100%);
              opacity: 0;
            }
            100% {
              transform: translateX(0);
              opacity: 1;
            }
          }

          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
}

export default ComingSoon;
