import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

function AdminCareers() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch application data from the API
  const fetchApplications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/get_applications/`);
      setApplications(response.data); // Assuming the API response directly returns the list of applications
    } catch (error) {
      setError('Failed to fetch applications');
    } finally {
      setLoading(false);
    }
  };

  // UseEffect hook to fetch data when the component mounts
  useEffect(() => {
    fetchApplications();
  }, []);

  // Function to handle deleting an application (setting is_active to false)
  const handleDelete = async (id) => {
    try {
      const response = await axios.patch(`${API_URL}/mark_application_inactive/${id}/`);
      // Remove the application from the state if deletion is successful
      setApplications((prevApplications) =>
        prevApplications.filter((application) => application.id !== id)
      );
    } catch (error) {
      console.error('Failed to delete application', error);
      setError('Failed to delete application');
    }
  };

  return (
    <Container sx={{ backgroundColor: '#121212', minHeight: '100vh', pt: 8 }} maxWidth="lg">
      <Typography variant="h4" align="center" gutterBottom sx={{ color: '#fff' }}>
        Admin - Career Applications
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress sx={{ color: '#1E90FF' }} />
        </Box>
      ) : error ? (
        <Typography sx={{ color: '#ff4747', textAlign: 'center' }}>{error}</Typography>
      ) : (
        <Grid container spacing={3}>
          {applications.length === 0 ? (
            <Typography variant="h6" align="center" sx={{ color: '#fff' }}>
              No applications available.
            </Typography>
          ) : (
            applications.map((application) => (
              <Grid item xs={12} sm={6} md={4} key={application.id}>
                <Card sx={{
                  backgroundColor: '#1C1C1C',
                  color: '#fff',
                  mb: 3,
                  borderRadius: 2,
                  boxShadow: 3,
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#1E90FF' }}>
                      {application.name}
                    </Typography>
                    <Divider sx={{ backgroundColor: '#555' }} />
                    <Typography sx={{ fontSize: '1rem', color: '#bbb', marginTop: 1 }}>
                      <strong>Contact:</strong> {application.contact}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#bbb' }}>
                      <strong>Email:</strong> {application.email}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#bbb' }}>
                      <strong>Role:</strong> {application.role}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#bbb' }}>
                      <strong>Type:</strong> {application.type}
                    </Typography>
                    <Box sx={{ marginTop: 2 }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        href={`${API_URL}${application.resume}`} // assuming the resume is part of the application data
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Resume
                      </Button>
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDelete(application.id)} // Delete functionality
                      >
                        Delete Application
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      )}
    </Container>
  );
}

export default AdminCareers;