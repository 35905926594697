import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, IconButton, Grid } from '@mui/material';
import FacebookIcon from '../Icon/facebook.png';
import InstagramIcon from '../Icon/instagram.png';
import YouTubeIcon from '../Icon/Youtube-Icon.png';
import TwitterIcon from '../Icon/X.png';
import LinkedInIcon from '../Icon/LinkedIn.png';

function Footer() {
    return (
        <Box
            sx={{
                background: 'linear-gradient(to right, #000000, #000000)', // Gradient background for a modern look
                color: '#fff',
                padding: '60px 20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 'auto',
                boxShadow: '0 -3px 10px rgba(0, 0, 0, 0.2)', // Add some depth
                borderTop: '3px solid rgba(255, 255, 255, 0.2)', // Slight top border
            }}
        >
            <Grid container spacing={4} justifyContent="center">
                {/* Quick Links Section */}
                <Grid item xs={12} md={3} textAlign="center">
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 2,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            fontFamily: '"Roboto", sans-serif',
                            fontSize: { xs: '1.2rem', md: '1.5rem' }, // Responsive font size
                        }}
                    >
                        Quick Links
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link to="/about-us" style={styles.link}>About Us</Link>
                        <Link to="/contact-us" style={styles.link}>Contact Us</Link>
                        <Link to="/faqs" style={styles.link}>FAQs</Link>
                    </Box>
                </Grid>

                {/* Legal Section */}
                <Grid item xs={12} md={3} textAlign="center">
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 2,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            fontFamily: '"Roboto", sans-serif',
                            fontSize: { xs: '1.2rem', md: '1.5rem' }, // Responsive font size
                        }}
                    >
                        Legal
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link to="/terms-of-service" style={styles.link}>Terms of Service</Link>
                        <Link to="/privacy-policy" style={styles.link}>Privacy Policy</Link>
                        <Link to="/cookies-policy" style={styles.link}>Cookies Policy</Link>
                        <Link to="/refunds-and-cancellations-policy" style={styles.link}>Refund & Cancellations</Link>
                        <Link to="/shipping-policy" style={styles.link}>Shipping Policy</Link>
                    </Box>
                </Grid>

                {/* Join Us Section */}
                <Grid item xs={12} md={3} textAlign="center">
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 2,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            fontFamily: '"Roboto", sans-serif',
                            fontSize: { xs: '1.2rem', md: '1.5rem' }, // Responsive font size
                        }}
                    >
                        Join Us
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link to="/careers" style={styles.link}>Careers</Link>
                        <Link to="/community" style={styles.link}>Community</Link>
                    </Box>
                </Grid>

                {/* Social Media Section */}
                <Grid item xs={12} md={3} textAlign="center">
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 2,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            fontFamily: '"Roboto", sans-serif',
                            fontSize: { xs: '1.2rem', md: '1.5rem' }, // Responsive font size
                        }}
                    >
                        Follow Us
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <IconButton href="https://www.facebook.com/people/Anthrasync/61566752842183/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer" sx={styles.iconButton}>
                            <img src={FacebookIcon} alt="Facebook" style={styles.icon} />
                        </IconButton>
                        <IconButton href="https://www.instagram.com/anthrasync/" target="_blank" rel="noopener noreferrer" sx={styles.iconButton}>
                            <img src={InstagramIcon} alt="Instagram" style={styles.icon} />
                        </IconButton>
                        <IconButton href="https://www.youtube.com/@AnthrasyncMarketing" target="_blank" rel="noopener noreferrer" sx={styles.iconButton}>
                            <img src={YouTubeIcon} alt="YouTube" style={styles.icon} />
                        </IconButton>
                        <IconButton href="https://x.com/anthrasync" target="_blank" rel="noopener noreferrer" sx={styles.iconButton}>
                            <img src={TwitterIcon} alt="X" style={styles.icon} />
                        </IconButton>
                        <IconButton href="https://www.linkedin.com/company/anthrasync-solutions-private-limited/" target="_blank" rel="noopener noreferrer" sx={styles.iconButton}>
                            <img src={LinkedInIcon} alt="LinkedIn" style={styles.icon} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>

            {/* Copyright Section */}
            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: { xs: '0.9rem', sm: '1rem' }, // Responsive font size
                        opacity: 0.7,
                        fontFamily: '"Roboto", sans-serif',
                    }}
                >
                    © 2024 Anthrasync Inc. All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
}

const styles = {
    link: {
        color: '#fff',
        textDecoration: 'none',
        margin: '5px 0',
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '0.5px',
        transition: 'all 0.3s ease',
    },
    iconButton: {
        '&:hover': {
            transform: 'scale(1.1)', // Subtle hover effect for icons
            transition: 'transform 0.2s ease',
        },
    },
    icon: {
        width: '30px',
        height: '30px',
        transition: 'all 0.3s ease',
    },
};

export default Footer;
