import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import dayjs from 'dayjs';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Snackbar,
    IconButton,
    InputAdornment,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';

const API_URL = process.env.REACT_APP_API_URL;

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        dob: '',
        gender: '',
        emailOrPhone: '',
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        // Clear the error for the specific field when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '', // Reset error for the current field
        }));
    
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validatePassword = (password) => {
        const minLength = /.{8,}/; // At least 8 characters
        const uppercase = /[A-Z]/; // At least one uppercase letter
        const lowercase = /[a-z]/; // At least one lowercase letter
        const number = /[0-9]/;    // At least one number
        const specialChar = /[!@#$%^&*(),.?":{}|<>]/; // At least one special character

        if (!minLength.test(password)) return 'Password must be at least 8 characters long.';
        if (!uppercase.test(password)) return 'Password must include at least one uppercase letter.';
        if (!lowercase.test(password)) return 'Password must include at least one lowercase letter.';
        if (!number.test(password)) return 'Password must include at least one number.';
        if (!specialChar.test(password)) return 'Password must include at least one special character.';
        return null; // Password is valid
    };

    const validate = () => {
        let newErrors = {};
        if (!formData.name) {
            newErrors.name = 'First Name is required';
        } else if (formData.name.length > 20) {
            newErrors.name = 'First Name cannot exceed 20 characters';
        }

        if (!formData.surname) {
            newErrors.surname = 'Last Name is required';
        } else if (formData.surname.length > 20) {
            newErrors.surname = 'Last Name cannot exceed 20 characters';
        }

        if (!formData.dob) newErrors.dob = 'Date of Birth is required';
        if (!formData.gender) newErrors.gender = 'Gender is required';
        // if (!formData.emailOrPhone) newErrors.emailOrPhone = 'Email or phone number is required';
        if (!formData.emailOrPhone) {
            newErrors.emailOrPhone = 'Email or phone number is required';
        } else {
            // Email format validation
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov)$/;
            if (!emailRegex.test(formData.emailOrPhone)) {
                newErrors.emailOrPhone = 'Please enter a valid email address';
            }
        }
        const passwordError = validatePassword(formData.password);
        if (passwordError) newErrors.password = passwordError;

        if (!formData.confirmPassword) {
            newErrors.confirmPassword = 'Confirm Password is required';
        } else if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!rememberMe) {
            setSnackbarMessage('You must agree to the terms and conditions before creating an account.');
            setSnackbarOpen(true);
            return;
        }

        if (validate()) {
            await handlePost();
        } else {
            setSnackbarMessage('Account creation failed. Please check the errors and try again.');
            setSnackbarOpen(true);
        }
    };

    const handlePost = async () => {
        try {
            const response = await axios.post(`${API_URL}/create_users/`, formData);
            showAlert()
            navigate('/login');
        } catch (error) {
            console.error('Error posting data:', error);
            setSnackbarMessage('Failed to create account. Please try again later.');
            setSnackbarOpen(true);
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const showAlert = () => {
        const alertDiv = document.createElement('div');
        alertDiv.textContent = 'Account Created successfully';
        alertDiv.style.position = 'fixed';
        alertDiv.style.top = '10px';
        alertDiv.style.left = '50%';
        alertDiv.style.transform = 'translateX(-50%)';
        alertDiv.style.padding = '10px 20px';
        alertDiv.style.backgroundColor = 'green';
        alertDiv.style.color = 'white';
        alertDiv.style.borderRadius = '5px';
        alertDiv.style.zIndex = '9999';
        alertDiv.style.fontSize = '16px';
    
        document.body.appendChild(alertDiv);
    
        // Auto remove the alert after 3 seconds
        setTimeout(() => {
            alertDiv.remove();
        }, 3000);
    };
    
    return (
        <div>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#000000',
                    marginTop: 5,
                    mb: 70,
                    padding: { xs: '1rem', sm: '2rem', md: '3rem' } // Adjust padding for different screen sizes
                }}
            >
                <Box
                    sx={{
                        backdropFilter: 'blur(15px)',
                        backgroundColor: '#000000',
                        borderRadius: '16px',
                        padding: '1.5rem',
                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
                        border: '1px solid rgba(255, 255, 255, 0.3)',
                        maxWidth: '500px',
                        textAlign: 'center',
                        width: { xs: '100%', sm: '80%', md: '60%' },
                        '&:hover': {
                            borderColor: '#1E90FF',
                        },
                    }}
                >
                    <img
                        src={Logo}
                        alt="Logo"
                        style={{
                            marginBottom: '20px',
                            height: 'auto',
                            width: '100%',
                            maxWidth: '400px',
                        }}
                    />
                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{
                            color: 'white',
                            marginBottom: 2,
                            '&:hover': {
                                color: '#1E90FF',
                            },
                        }}
                    >
                        Create an Account
                    </Typography>
                     <form onSubmit={handleSubmit}>
                        <TextField
                            label="First Name"
                            name="name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formData.name}
                            onChange={handleChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            sx={{
                                '& .MuiInputBase-input': { color: 'white' },
                                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1E90FF' },
                            }}
                        />
                        <TextField
                            label="Last Name"
                            name="surname"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formData.surname}
                            onChange={handleChange}
                            error={!!errors.surname}
                            helperText={errors.surname}
                            sx={{
                                '& .MuiInputBase-input': { color: 'white' },
                                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1E90FF' },
                            }}
                        />

                        <TextField
                            label="Date of Birth"
                            name="dob"
                            variant="outlined"
                            type="date"
                            fullWidth
                            margin="normal"
                            value={formData.dob}
                            onChange={handleChange}
                            error={!!errors.dob}
                            helperText={errors.dob}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                '& .MuiInputBase-input': { color: 'white' },
                                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1E90FF' },
                            }}
                        />

                        <FormControl
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors.gender}
                            sx={{
                                '& .MuiInputBase-input': { color: 'white' },
                                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1E90FF' },
                            }}
                        >
                            <InputLabel>Gender</InputLabel>
                            <Select
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                label="Gender"
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="Email"
                            name="emailOrPhone"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formData.emailOrPhone}
                            onChange={handleChange}
                            error={!!errors.emailOrPhone}
                            helperText={errors.emailOrPhone}
                            sx={{
                                '& .MuiInputBase-input': { color: 'white' },
                                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1E90FF' },
                            }}
                        />

                        <TextField
                            label="Password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formData.password}
                            onChange={handleChange}
                            error={!!errors.password}
                            helperText={errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiInputBase-input': { color: 'white' },
                                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1E90FF' },
                            }}
                        />

                        <TextField
                            label="Confirm Password"
                            name="confirmPassword"
                            type="password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                            sx={{
                                '& .MuiInputBase-input': { color: 'white' },
                                '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1E90FF' },
                            }}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)} // Update state when checkbox is toggled
                                    sx={{
                                        color: 'white',
                                        '&.Mui-checked': {
                                            color: '#1E90FF',  // Set color of checked checkbox
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography
  sx={{
    color: 'white',
    '&:hover': {
      color: 'white', // Hover color for the label text
    },
  }}
>
  I agree to the{' '}
  <Link to="/terms-of-service" style={{ textDecoration: 'none', color: '#1E90FF' }}>
    Terms of Service
  </Link>{' '}
  and{' '}
  <Link to="/privacy-policy" style={{ textDecoration: 'none', color: '#1E90FF' }}>
    Privacy Policy
  </Link>
</Typography>
                            }
                        />

                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            sx={{ mt: 2 }}
                            disabled={!rememberMe} // Disable the button if rememberMe is false (checkbox not checked)
                        >
                            Create Account
                        </Button>
                    </form>

                    <Box sx={{ mt: 2 }}>
                        <Link to="/login" style={{
                            textDecoration: 'none', color: 'white',
                            '&:hover': {
                                color: '#1E90FF',
                            },
                        }}>
                            Already have an account? Sign in
                        </Link>
                    </Box>
                </Box>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    action={
                        <IconButton size="small" onClick={() => setSnackbarOpen(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Container>
            <IconButton
                onClick={scrollToTop}
                sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}>
                <ArrowUpwardIcon />
            </IconButton>
            <Footer2 />
        </div>
    );
};

export default SignUp;
