import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Button, Card, Box, CardContent, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { AccessTime, Language, AttachMoney, Person, Event, Construction } from '@mui/icons-material';
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';
import Footer2 from '../Footer/Footer2';








const SubscriptionsPayment = ({ userId, onLogout }) => {
  const [csrfToken, setCsrfToken] = useState('');
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();


  const params = useParams();
  const type = params.type;
  const subscription_id = params.id;


  const [bookingCreated, setBookingCreated] = useState(false);
  const hasCalledApi = useRef(false); // useRef to track whether API has been called


  const API_URL = process.env.REACT_APP_API_URL;

  const [userData, setUserData] = useState({
    "name": "",
    "about": "",
    "contact": "",
    "designation": "",
    "created_at": "",
    "dob": "",
    "email": "",
    "gender": "",
    "id": "",
    "image": "",
    "languages": "",
    "password": "",
    "rating": "",
    "role": "",
    "status": "",
    "surname": "",
    "address": "",
    "city": "",
    "state": "",
    "country": "",
    "pincode": "",
    "updated_at": "",
    "facebook": "",
    "instagram": "",
    "x": "",
    "linkedin": "",
    "youtube": "",
    "currentLevel": "Advanced",
    "totalTimeLearning": "150 hrs",
    "totalTimeTeaching": "50 hrs",
    "totalSessions": "25",
    "ratings": "4.8/5",
  });


  const [isChecked, setIsChecked] = useState(false);




  //   const handleSubscribe = async () => {
  //     const payload = {
  //       user_id: userId,
  //       subscription_id: plan.id,
  //       remaining_posts: plan.post_count,
  //     };


  //     try {
  //       await axios.post('http://localhost:8000/create_subscription_mapping/', payload);
  //       handleSubscriptionSuccess(plan.id);  // Update the active subscription state
  //     } catch (error) {
  //       console.error('Error during subscription:', error);
  //     }
  //   };


  const createUserPostBookings = async () => {
    try {
      const response = await fetch(`${API_URL}/create_subscription_mapping/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          subscription_id: subscription_id,
          remaining_posts: courses.post_count,
        }),
      });


      const data = await response.json();


      if (response.ok) {
        setBookingCreated(true); // Mark booking as created
        hasCalledApi.current = true; // Mark API as called
      }
    } catch (error) {
      console.error('Error during subscription:', error);
    }
  };




  useEffect(() => {
    if (!userId) {
      return;
    }


    // Axios request to fetch user data
    axios
      .get(`${API_URL}/get_user_data/?user_id=${userId}`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userId]);


  const CreateTransactions = (transactionId, status, paymentReason, id) => {
    const postData = {
      user_id: userId,
      post_id: subscription_id,
      order_id: id,
      transaction_id: transactionId,
      amount: courses.price,
      status: status,
      payment_reason: paymentReason,
    };


    axios
      .post(`${API_URL}/create_transactions/`, postData)
      .then((response) => {
      })
      .catch((error) => {
        console.error('Error saving transaction:', error);
      });
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/get_subscription_plan_details/?subscription_id=${subscription_id}`);
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };
    fetchData();
  }, [subscription_id]);


  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${API_URL}/get-csrf-token/`, { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };
    fetchCsrfToken();
  }, []);


  const handlePayment = async (subscription_id, post_count) => {


    let missingFields = [];

    if (!csrfToken) missingFields.push('CSRF Token');
    if (!courses.price) missingFields.push('Price');
    if (!courses.post_count) missingFields.push('Post Count');
    if (!userData.city) missingFields.push('City');
    if (!userData.state) missingFields.push('State');
    if (!userData.country) missingFields.push('Country');
    if (!userData.pincode) missingFields.push('Pincode');

    if (missingFields.length > 0) {
      alert('Please fill the required details for making the payment: ' + missingFields.join(', ') + '.');
      return;
    }


    try {
      const response = await axios.post(
        `${API_URL}/create-order/`,
        {
          amount: courses.price * 100, // Amount in paise
        },
        {
          headers: {
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        }
      );


      const { id } = response.data;


      if (!id) {
        console.error('No order ID received.');
        return;
      }


      const options = {
        key: 'rzp_live_7e0OC9qGVaGXLr', // Razorpay key ID
        amount: courses.price * 100, // Amount in paise
        currency: 'INR',
        name: 'Anthrasync',
        description: 'Test Transaction',
        order_id: id,
        handler: function (response) {
          CreateTransactions(response.razorpay_payment_id, 'success', 'Payment successful', id);
          createUserPostBookings();
          alert(`Payment successful: ${response.razorpay_payment_id}`);
          navigate(`/subscriptions?userId=${userId}`, { state: { paymentStatus: 'success', paymentId: response.razorpay_payment_id } });
        },
        modal: {
          ondismiss: function () {
            CreateTransactions(null, 'failed', 'Payment was cancelled', id);
            alert('Payment was cancelled');
            navigate(`/subscriptions?userId=${userId}`, { state: { paymentStatus: 'success', paymentId: response.razorpay_payment_id } });
          },
        },
        prefill: {
          name: `${userData.name} ${userData.surname}`,
          email: userData.email,
          contact: userData.contact,
        },
        theme: {
          color: '#F37254',
        },
      };


      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Payment error:', error.response || error.message);
    }
  };


  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  return (
    <div>
      <Container maxWidth="sm" sx={{ mt: 1, paddingBottom: 4 }}>
        <Card
          sx={{
            backgroundColor: 'black',
            boxShadow: 5,
            borderRadius: 3,
            padding: { xs: 3, sm: 4, md: 5 },  // Responsive padding
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.03)',
            },
          }}
        >
          <Box sx={{ textAlign: 'center', mb: { xs: 5, sm: 8, md: 10 } }}>
            <Link to="/home">
              <img
                src={Logo}
                alt="Logo"
                style={{
                  width: '100%',
                  maxWidth: '350px', // Cap the maximum size of the logo
                  height: 'auto',
                }}
              />
            </Link>
          </Box>

          <CardContent>
            {/* Main Title */}
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
                color: 'white',
                fontWeight: 'bold',
                letterSpacing: '1px',
                mb: { xs: 3, sm: 5, md: 7 }, // Adjusted margin bottom for responsiveness
                fontSize: { xs: '1.6rem', sm: '2rem', md: '2.4rem' },  // Responsive font size
              }}
            >
              Subscriptions and Order Details
            </Typography>

            {/* Payment Info Section */}
            <Grid container spacing={4}>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <Person sx={{ color: '#1E90FF', mr: 3, fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.5rem' } }} />
                <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' } }}>
                  Number of Posts: {courses.post_count}
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <AttachMoney sx={{ color: '#32CD32', mr: 3, fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.5rem' } }} />
                <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' } }}>
                  Price: ₹{courses.price}
                </Typography>
              </Grid>
            </Grid>

            {/* Billing Information Section */}
            <Typography
              variant="h4"
              sx={{
                color: 'white',
                fontWeight: 'bold',
                mb: { xs: 3, sm: 5, md: 7 },
                mt: 15,
                textAlign: 'center',
                fontSize: { xs: '1.6rem', sm: '2rem', md: '2.4rem' },
              }}
            >
              Billing Information
            </Typography>

            <Grid container spacing={4}>
              {[
                { label: 'Username', value: `${userData.name} ${userData.surname}` },
                { label: 'Email', value: userData.email },
                { label: 'Contact', value: userData.contact },
                { label: 'Address', value: userData.address },
                { label: 'City', value: userData.city },
                { label: 'State', value: userData.state },
                { label: 'Country', value: userData.country },
                { label: 'Pincode', value: userData.pincode },
              ].map(({ label, value }) => (
                <Grid
                  item
                  xs={12}
                  key={label}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',  // Allows text to wrap to the next line if needed
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'white',
                      fontSize: {
                        xs: '0.9rem',  // Extra small devices
                        sm: '1rem',    // Small devices
                        md: '1.2rem',  // Medium devices
                        lg: '1.4rem',  // Large devices
                        xl: '1.6rem',  // Extra large devices
                      },
                      wordBreak: 'break-word',  // Ensures long words or data break to fit into the container
                      whiteSpace: 'normal',    // Allows text to wrap normally (important for long words)
                    }}
                  >
                    {label}: {value}
                  </Typography>
                </Grid>

              ))}
            </Grid>

            {/* Checkbox for confirmation */}
            <Grid item xs={12} sx={{ mt: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    sx={{
                      color: 'white',
                      '&.Mui-checked': {
                        color: '#1E90FF',
                      },
                    }}
                  />
                }
                label="I agree and confirm all the details are correct."
                sx={{
                  color: 'white',
                  '& .MuiFormControlLabel-label': {
                    color: 'white',
                  },
                }}
              />
            </Grid>

            {/* Pay Now Button */}
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePayment}
                fullWidth
                disabled={!isChecked}
                sx={{
                  '&:hover': {
                    transform: 'scale(1.05)',
                    transition: 'transform 0.3s ease-in-out',
                    backgroundColor: '#FF6347',
                  },
                  padding: '14px',
                  backgroundColor: '#1E90FF',
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: '8px',
                  fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.6rem' },  // Responsive font size
                }}
              >
                Pay Now
              </Button>
            </Grid>
          </CardContent>
        </Card>

      </Container>
      <Footer2 />
    </div>


  );
};


export default SubscriptionsPayment;



