import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Typography, Box, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams for dynamic route params

function UpdateSections({ userId }) {
  const [sections, setSections] = useState([]);  // Store all sections
  const [sectionId, setSectionId] = useState('');  // Store the selected section ID
  const [sectionName, setSectionName] = useState('');
  const [numSubsections, setNumSubsections] = useState(0);
  const [subsections, setSubsections] = useState([]); // Ensure it's initialized as an empty array
  const [sectionColor, setSectionColor] = useState('#FFFFFF'); // Default color white

  const API_URL = process.env.REACT_APP_API_URL;
  const { sectionId: paramSectionId } = useParams(); // Get sectionId from the URL params (if editing a specific section)

  // Fetch all sections when the component mounts
  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await axios.get(`${API_URL}/list_sections/`);
        setSections(response.data); // Assuming this returns a list of sections
        if (paramSectionId) {
          // If a sectionId is passed in the URL, pre-select that section
          const section = response.data.find(section => section.id === parseInt(paramSectionId));
          if (section) {
            setSectionId(section.id);
            setSectionName(section.name);
            setSubsections(section.subsections || []); // Ensure subsections is an empty array if undefined
            setNumSubsections(section.subsections ? section.subsections.length : 0);
          }
        }
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    };

    fetchSections();
  }, [paramSectionId, API_URL]);

  // Handle section selection
  const handleSectionSelect = (event) => {
    const selectedSectionId = event.target.value;
    setSectionId(selectedSectionId);

    const selectedSection = sections.find(section => section.id === selectedSectionId);
    if (selectedSection) {
      setSectionName(selectedSection.name);
      setNumSubsections(selectedSection.subsections ? selectedSection.subsections.length : 0);
      setSubsections(selectedSection.subsections || []); // Ensure subsections is an array
    }
  };

  // Handle the number of subsections change
  const handleNumSubsectionsChange = (e) => {
    const value = e.target.value;
    setNumSubsections(value);
    setSubsections(Array.from({ length: value }, (_, i) => subsections[i] || { name: '' }));
  };

  // Handle subsection name change
  const handleSubsectionChange = (index, field, value) => {
    const newSubsections = [...subsections];
    newSubsections[index] = { ...newSubsections[index], [field]: value };
    setSubsections(newSubsections);
  };

  // Handle form submission (updating section details)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      sectionName,
      subsections,
      userId,
      color: sectionColor,
    };

    try {
      const response = await axios.put(`${API_URL}/update_section/${sectionId}/`, data);
      window.alert('Section updated successfully!');
      window.location.reload();
    } catch (error) {
      console.error('Error updating section:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'black' }}>
      <Box sx={{ flexGrow: 1, marginTop: '80px' }}>
        <Paper
          sx={{
            maxWidth: 600,
            margin: '20px auto',
            padding: 4,
            background: 'linear-gradient(45deg, #6a1b9a, #2196f3)',
            borderRadius: 2,
            boxShadow: 8,
          }}
        >
          <Typography variant="h4" align="center" color="white" fontWeight="bold" gutterBottom>
            Update Section
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Section Selector */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: '#e1bee7' }}>Select Section</InputLabel>
                  <Select
                    value={sectionId}
                    onChange={handleSectionSelect}
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#ba68c8',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#8e24aa',
                        },
                      },
                    }}
                  >
                    {sections.map((section) => (
                      <MenuItem key={section.id} value={section.id}>
                        {section.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Section Name */}
              <Grid item xs={12}>
                <TextField
                  label="Section Name"
                  variant="outlined"
                  fullWidth
                  value={sectionName}
                  onChange={(e) => setSectionName(e.target.value)}
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Number of Subsections
              <Grid item xs={12}>
                <TextField
                  label="Number of Subsections"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={numSubsections}
                  onChange={handleNumSubsectionsChange}
                  min="0"
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid> */}

              {/* Subsections
              {Array.from({ length: numSubsections }).map((_, index) => (
                <Grid item xs={12} key={index}>
                  <TextField
                    label={`Subsection Name ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    value={subsections[index]?.name || ''}
                    onChange={(e) => handleSubsectionChange(index, 'name', e.target.value)}
                    required
                    InputLabelProps={{
                      style: { color: '#e1bee7' },
                    }}
                    InputProps={{
                      style: { color: 'white' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#ba68c8',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#8e24aa',
                        },
                      },
                    }}
                  />
                </Grid>
              ))} */}

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    background: 'linear-gradient(45deg, #8e24aa, #2196f3)',
                    padding: '12px',
                    fontSize: '16px',
                    color: 'white',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #7b1fa2, #1976d2)',
                      boxShadow: 8,
                    },
                  }}
                >
                  Update Section
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default UpdateSections;
