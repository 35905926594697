import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, List, ListItem, ListItemText, Divider, IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


function MeetingGuidelines({userId,loggedIn, onLogout}) {
  const navigate = useNavigate();
      const scrollToTop = () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        };
  
      const handleLogout = () => {
          onLogout(); // Call the passed logout function
          navigate('/login'); // Navigate to login page after logout
        };
  return (
    <div>
      {loggedIn ? (
      <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
    ) : (
      <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
    )}
    
    <Container maxWidth="md" sx={{ padding: '20px', mt:10 }}>
      <Typography variant="h3" textAlign={'center'} gutterBottom>
        Meeting Instructions
      </Typography>
      <Typography variant="body1" paragraph>
        All users, whether verified or normal users, have to comply with the Meeting Instructions.
      </Typography>
      <Typography variant="body1" paragraph>
        To ensure a safe, respectful, and productive environment during all online meetings, we kindly ask all users to follow the guidelines outlined below. These rules are designed to foster a positive experience for all participants and to prevent any disruptive or unethical behavior.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Meeting Instructions Guidelines
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Respectful Communication
      </Typography>
      <Typography variant="body1" paragraph>
        Always treat others with respect and professionalism, regardless of their role, background, or opinions. Avoid using offensive language, derogatory comments, or discriminatory remarks. Ensure that all interactions remain civil and do not escalate into personal attacks or harassment.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h6" gutterBottom>
        2. No Harassment or Bullying
      </Typography>
      <Typography variant="body1" paragraph>
        Harassment, bullying, or intimidation of any kind is strictly prohibited. This includes sending unwanted messages or engaging in disruptive behavior. Please report any such incidents to the meeting host or platform support immediately.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h6" gutterBottom>
        3. No Sexual Content or Inappropriate Behavior
      </Typography>
      <Typography variant="body1" paragraph>
        The sharing, display, or discussion of explicit sexual content, images, videos, or any other inappropriate material will not be tolerated. Participants must refrain from engaging in sexually suggestive or explicit behavior, whether verbally or visually.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h6" gutterBottom>
        4. Illegal Activities and Knowledge Sharing
      </Typography>
      <Typography variant="body1" paragraph>
        Sharing or discussing illegal activities, such as hacking, fraud, or the distribution of pirated content, is strictly prohibited. Any form of sharing or promoting illegal, harmful, or dangerous knowledge or behavior will result in immediate removal from the meeting and may lead to account suspension.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h6" gutterBottom>
        5. Privacy and Confidentiality
      </Typography>
      <Typography variant="body1" paragraph>
        Be mindful of others' privacy. Do not record or share meeting content without prior consent from all participants. Keep sensitive personal or company information confidential and avoid discussing confidential topics in public or unsecured forums.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h6" gutterBottom>
        6. Appropriate Use of Audio and Video
      </Typography>
      <Typography variant="body1" paragraph>
        Ensure that your microphone is muted when you are not speaking to avoid background noise. Always be mindful of your surroundings when using video; inappropriate or distracting backgrounds can be disruptive. Use video and audio for constructive purposes only. Do not disrupt the flow of the meeting with unrelated noise or video distractions.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h6" gutterBottom>
        7. No Trolling or Spamming
      </Typography>
      <Typography variant="body1" paragraph>
        Refrain from spamming the chat or using disruptive tactics to derail the meeting. All messages in the chat should remain relevant to the meeting’s agenda.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h6" gutterBottom>
        8. Reporting Violations
      </Typography>
      <Typography variant="body1" paragraph>
        If you observe any violations of these guidelines, please report them to the meeting host or platform administrator immediately. We are committed to taking appropriate actions to maintain the integrity of our meetings and the safety of all participants.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h6" gutterBottom>
        9. Consequences of Unethical Behavior
      </Typography>
      <Typography variant="body1" paragraph>
        Any participant found engaging in unethical behavior will be warned and may be removed from the meeting. Repeated violations may result in a permanent ban from the platform.
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h6" gutterBottom>
        10. A Positive and Productive Environment
      </Typography>
      <Typography variant="body1" paragraph>
        Remember that we are all here to collaborate and work together. Let's maintain a positive and productive environment that fosters mutual respect and effective communication.
      </Typography>
    </Container>
    <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
   <Footer2/>
    </div>
  );
}

export default MeetingGuidelines;
