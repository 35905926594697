import React from 'react'
import SignUp from '../SignUp/SignUp'
import Header1 from '../Header/Header1'
import Footer2 from '../Footer/Footer2'
import { Box, Grid } from '@mui/material'

function AdminCreateAccounts({ userId, onLogout }) {
    return (
      <Box sx={{ padding: { xs: '10px', sm: '20px', md: '40px' } }}>
        <Header1 userId={userId} onLogout={onLogout} /> 
        <Box sx={{ marginTop: { xs: '10px', sm: '20px', md: '40px' } }} />
        <SignUp />
      </Box>
    )
  }

export default AdminCreateAccounts
