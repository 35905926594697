import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
  Grid,
  Typography,
  Box
} from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

function AdminUpdateAccounts() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedField, setSelectedField] = useState('');
  const [newValue, setNewValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // List of fields available for update
  const fields = [
    "name", "surname", "email", "contact", "gender", "dob", 
    "password", "about", "rating", "languages", "role", "status",
    "address", "city", "state", "country", "pincode", "facebook", 
    "instagram", "x", "linkedin", "youtube", "image"
  ];

  const navigate = useNavigate();

    // const location = useLocation();  // Track the current route location

    // useEffect(() => {
    //     // Check if a route is saved in localStorage
    //     const savedRoute = localStorage.getItem('currentRoute');
    //     console.log('savedRoute', savedRoute);

    //     if (savedRoute) {
    //         // Navigate to the saved route when the app starts
    //         navigate(savedRoute);
    //     }
    // }, [navigate]);

    // useEffect(() => {
    //     // Save the current route (pathname + search) in localStorage whenever the route changes
    //     const currentPath = location.pathname + location.search;  // includes any query parameters
    //     localStorage.setItem('currentRoute', currentPath);
    //     console.log('Route saved to localStorage:', currentPath);
    // }, [location]);

  // Fetch users from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/list_users_data/`);
        setUsers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch users');
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  // Handle field selection and user selection
  const handleFieldChange = (e) => {
    setSelectedField(e.target.value);
  };

  const handleUserSelect = (e) => {
    const userId = e.target.value;
    const user = users.find((user) => user.id === userId);
    setSelectedUser(user);
  };

  const handleInputChange = (e) => {
    setNewValue(e.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedUser || !selectedField || !newValue) {
      alert('Please select a user, field, and provide a value to update.');
      return;
    }

    const payload = {
      selectedField: selectedField,  // Correct field name
      value: newValue // The new value for the field
    };

    try {
      await axios.put(`${API_URL}/update_user_accounts/${selectedUser.id}/`, payload);
      showAlert()
      window.location.reload();
    } catch (err) {
      setError('Failed to update user');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;


  const showAlert = () => {
    const alertDiv = document.createElement('div');
    alertDiv.textContent = 'User updated successfully';
    alertDiv.style.position = 'fixed';
    alertDiv.style.top = '10px';
    alertDiv.style.left = '50%';
    alertDiv.style.transform = 'translateX(-50%)';
    alertDiv.style.padding = '10px 20px';
    alertDiv.style.backgroundColor = 'green';
    alertDiv.style.color = 'white';
    alertDiv.style.borderRadius = '5px';
    alertDiv.style.zIndex = '9999';
    alertDiv.style.fontSize = '16px';

    document.body.appendChild(alertDiv);

    // Auto remove the alert after 3 seconds
    setTimeout(() => {
        alertDiv.remove();
    }, 3000);
};

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Update User Accounts
      </Typography>

      {/* Select a user from the list */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select a user</InputLabel>
            <Select
              value={selectedUser ? selectedUser.id : ''}
              onChange={handleUserSelect}
              label="Select a user"
            >
              <MenuItem value="">Select a user</MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name} {user.surname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Select a field to update */}
        {selectedUser && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Select a field to update</InputLabel>
              <Select
                value={selectedField}
                onChange={handleFieldChange}
                label="Select a field to update"
              >
                <MenuItem value="">Select a field</MenuItem>
                {fields.map((field) => (
                  <MenuItem key={field} value={field}>
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Input for the selected field */}
        {selectedField && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Value"
              value={newValue}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
        )}

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ marginTop: 2 }}
            fullWidth
          >
            Update User
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminUpdateAccounts;
