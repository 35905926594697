import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
  Grid,
  Typography,
  Box
} from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

function AdminUpdatePosts() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedField, setSelectedField] = useState('');
  const [newValue, setNewValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formTouched, setFormTouched] = useState(false); // Flag to track form submission attempt

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/list_users_data/`); // Adjust API endpoint for users
        setUsers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch users');
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  // Fetch posts when a user is selected
  useEffect(() => {
    if (selectedUser) {
      const fetchPosts = async () => {
        try {
          const response = await axios.get(`${API_URL}/admin_list_posts/?user_id=${selectedUser}`);
          setPosts(response.data);
        } catch (err) {
          setError('Failed to fetch posts');
        }
      };
      fetchPosts();
    }
  }, [selectedUser]);

  const handleUserSelect = (e) => {
    setSelectedUser(e.target.value);
    setPosts([]); // Clear posts when user changes
    setSelectedPost(null); // Clear selected post
    setSelectedField(''); // Clear selected field
    setNewValue('');
  };

  const handlePostSelect = (e) => {
    const postId = e.target.value;
    const post = posts.find((post) => post.id === postId);
    setSelectedPost(post);
  };

  const handleFieldChange = (e) => {
    setSelectedField(e.target.value);
    setNewValue(''); // Reset the value when the field changes
  };

  const handleInputChange = (e) => {
    setNewValue(e.target.value);
  };

  const handleSubmit = async () => {
    setFormTouched(true); // Set form as touched when the submit is triggered

    if (!selectedPost || !selectedField || !newValue) {
      alert('Please select a post, field, and provide a value to update.');
      return;
    }

    // Convert newValue to boolean if the selected field is "is_active"
    const payload = {
      selectedField: selectedField,
      value: selectedField === 'is_active' ? newValue === 'true' : newValue, // Convert to boolean for 'is_active'
    };

    try {
      await axios.put(`${API_URL}/admin_update_post/${selectedPost.id}/`, payload);
      showAlert()
      window.location.reload();
    } catch (err) {
      console.error(err);
      setError('Failed to update post');
    }
  };

  const showAlert = () => {
    const alertDiv = document.createElement('div');
    alertDiv.textContent = 'Post updated successfully';
    alertDiv.style.position = 'fixed';
    alertDiv.style.top = '10px';
    alertDiv.style.left = '50%';
    alertDiv.style.transform = 'translateX(-50%)';
    alertDiv.style.padding = '10px 20px';
    alertDiv.style.backgroundColor = 'green';
    alertDiv.style.color = 'white';
    alertDiv.style.borderRadius = '5px';
    alertDiv.style.zIndex = '9999';
    alertDiv.style.fontSize = '16px';

    document.body.appendChild(alertDiv);

    // Auto remove the alert after 3 seconds
    setTimeout(() => {
        alertDiv.remove();
    }, 3000);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Update Post
      </Typography>

      <Grid container spacing={2}>
        {/* User Selection */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select a user</InputLabel>
            <Select
              value={selectedUser}
              onChange={handleUserSelect}
              label="Select a user"
            >
              <MenuItem value="">Select a user</MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name} {user.surname} {/* Display the user name */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Post Selection (only show if a user is selected) */}
        {selectedUser && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Select a post</InputLabel>
                <Select
                  value={selectedPost ? selectedPost.id : ''}
                  onChange={handlePostSelect}
                  label="Select a post"
                >
                  <MenuItem value="">Select a post</MenuItem>
                  {posts.map((post) => (
                    <MenuItem key={post.id} value={post.id}>
                      {post.topic_name} {/* Display the post ID */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        {/* Selected Post Information */}
        {selectedPost && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">
                <strong>Selected Topic Name:</strong> {selectedPost.topic_name}
              </Typography>
            </Grid>

            {/* Field Selection */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Select a field to update</InputLabel>
                <Select
                  value={selectedField}
                  onChange={handleFieldChange}
                  label="Select a field to update"
                >
                  <MenuItem value="">Select a field</MenuItem>
                  <MenuItem value="highlightedTopic">Topic Name</MenuItem>
                  <MenuItem value="duration">Duration</MenuItem>
                  <MenuItem value="price">Price</MenuItem>
                  <MenuItem value="language">Language</MenuItem>
                  <MenuItem value="startTime">Start Time</MenuItem>
                  <MenuItem value="section_name">Section Name</MenuItem>
                  <MenuItem value="section_id">Section ID</MenuItem>
                  <MenuItem value="subsection_name">Subsection Name</MenuItem>
                  <MenuItem value="subsection_id">Subsection ID</MenuItem>
                  <MenuItem value="is_active">Is Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        {/* Input for the selected field */}
        {selectedField === 'is_active' && (
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Is Active</InputLabel>
              <Select
                value={newValue}
                onChange={handleInputChange}
                label="Is Active"
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {selectedField !== 'is_active' && selectedField && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Value"
              value={newValue}
              onChange={handleInputChange}
              margin="normal"
              error={formTouched && !newValue} // Only show error if form is touched and value is empty
              helperText={formTouched && !newValue ? 'This field is required' : ''}
            />
          </Grid>
        )}

        {/* Update Button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ marginTop: 2 }}
            fullWidth
          >
            Update Post
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminUpdatePosts;