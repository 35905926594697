import React, { useState } from 'react'
import {
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    CircularProgress,
    createTheme,
    ThemeProvider,
    Card,
    CardContent,
    Snackbar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Fade } from '@mui/material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function EmailVerifications({ userId }) {

    const [email, setEmail] = useState('');
    const [emailCode, setEmailCode] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const theme = useTheme();


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setErrorMessage(''); // Reset error message when user starts typing
    };

    // Reset error message whenever the email code field changes
    const handleEmailCodeChange = (e) => {
        setEmailCode(e.target.value);
        setErrorMessage(''); // Reset error message when user starts typing
    };


    const handleSubmitEmail = async () => {
        try {
            const response = await axios.post(`${API_URL}/send_email_code/`, { email });
            alert(response.data.message);
        } catch (error) {
            // setErrorMessage(error.response.data.error);
            setErrorMessage("Something went wrong. Please try again later.");
        }
    };

    const handleVerifyEmail = async () => {
        try {
            const response = await axios.post(`${API_URL}/verify_email_code/`, { email, code: emailCode, user_id: userId });
            //   setEmailVerified(response.data.role);
            setEmailVerified(response.data.is_verified);
            window.location.reload()
            // setErrorMessage('')

        } catch (error) {
            // setErrorMessage(error.response.data.error);
            // setErrorMessage(error.response.data.error);
            setErrorMessage("Something went wrong. Please try again later.");
        }
    };

    return (
        <div>{/* Title */}
            <Box sx={{ textAlign: 'center',  mt:1 }}>
            <Typography
  variant="h2"
  sx={{
    fontWeight: 'bold',
    color: 'white',
    fontSize: {
      xs: '1.5rem',  // for extra small screens
      sm: '2rem',    // for small screens
      md: '2rem',  // for medium screens
      lg: '2rem',    // for large screens
      xl: '2rem',  // for extra large screens
    },
  }}
>
  Stage 1 : Email verification
</Typography>


                {/* <Typography variant="subtitle1" sx={{ color: 'white', mt: 1 }}>
                                      Unlock premium features and resources tailored for your needs
                                  </Typography> */}
            </Box>
            <Box
                sx={{
                    bgcolor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    // mt: { xs: 20, sm: 10, md: 20 }, // Responsive top margin
                    borderRadius: 2,
                    boxShadow: 3,
                    // p: { xs: 3, sm: 4, md: 6 }, // Responsive padding
                    maxWidth: 600,
                    width: '100%',
                    mx: 'auto',
                    // my: 3,
                    '&:hover': {
                        transform: 'translateY(-5px)', // Slightly increased hover effect
                        boxShadow: 10,
                        transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transition
                    },
                    '@media (max-width: 600px)': {
                        maxWidth: '90%',
                        p: 2,
                    },
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: 'center',
                        mb: 3,
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },
                    }}
                >
                    Verify Your Email
                </Typography>

                {/* Email Input Section */}
                {/* {role !== 'emvuser' && ( */}
                <Fade in={!emailVerified} timeout={500}>
                    <div>
                        <TextField
                            label="Email"
                            type="email"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={handleEmailChange}
                            sx={{
                                input: {
                                    color: theme.palette.text.primary,
                                    backgroundColor: theme.palette.background.paper,
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderColor: theme.palette.text.primary,  // Default border color
                                    borderRadius: 1.5,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme.palette.text.primary,  // Default outline border color
                                },
                                // On hover and focus, change the border color to light blue
                                '&:hover .MuiOutlinedInput-root, &:focus .MuiOutlinedInput-root': {
                                    borderColor: '#1E90FF',  // Light blue color on hover or focus
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1E90FF',  // Light blue outline on hover or focus
                                },
                                // On hover and focus, change the label color to light blue
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.text.primary,  // Default label color
                                },
                                '&:hover .MuiInputLabel-root, &:focus .MuiInputLabel-root': {
                                    color: '#1E90FF',  // Light blue color on hover or focus for the label
                                },
                            }}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleSubmitEmail}
                            sx={{
                                mb: 2,
                                color: 'white',
                                backgroundColor: theme.palette.primary.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.dark,
                                },
                            }}
                            disabled={!email}
                        >
                            Send Email Code
                        </Button>
                        <TextField
                            label="Email Code"
                            fullWidth
                            margin="normal"
                            value={emailCode}
                            onChange={handleEmailCodeChange}
                            sx={{
                                input: {
                                    color: theme.palette.text.primary,
                                    backgroundColor: theme.palette.background.paper,
                                },
                                '& .MuiInputBase-root': {
                                    borderColor: theme.palette.text.primary, // Default border color
                                    borderRadius: 1.5,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme.palette.text.primary, // Default outline color
                                },
                                // On hover and focus, change the border color to light blue
                                '&:hover .MuiInputBase-root, &:focus .MuiInputBase-root': {
                                    borderColor: '#1E90FF', // Light blue on hover or focus
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1E90FF', // Light blue outline on hover or focus
                                },
                                // On hover and focus, change the label color to light blue
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.text.primary, // Default label color
                                },
                                '&:hover .MuiInputLabel-root, &:focus .MuiInputLabel-root': {
                                    color: '#1E90FF', // Light blue color on hover or focus
                                },
                            }}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleVerifyEmail}
                            sx={{
                                mb: 2,
                                color: 'white',
                                backgroundColor: theme.palette.primary.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.dark,
                                },
                            }}
                            disabled={!emailCode}
                        >
                            Verify Email
                        </Button>
                    </div>
                </Fade>
                {/* )} */}

                {/* Error Message */}
                {errorMessage && (
                    <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Typography>
                )}

                {/* Success Messages */}
                {emailVerified && (
                    <Fade in={emailVerified} timeout={500}>
                        <Typography
                            color="primary"
                            variant="body2"
                            align="center"
                            sx={{ mt: 2, fontWeight: 'bold' }}
                        >
                            Email Verified Successfully!
                        </Typography>
                    </Fade>
                )}
            </Box></div>
    )
}

export default EmailVerifications
