import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper, Divider,IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const RefundsAndCancellationsPolicy = ({userId, loggedIn,onLogout}) => {
    const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    const handleLogout = () => {
        onLogout(); // Call the passed logout function
        navigate('/login'); // Navigate to login page after logout
      };
    return (
        <div>
            {loggedIn ? (
        <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
      ) : (
        <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
      )}
        <Container maxWidth="md" sx={{mt:10}}>
            <Box my={4}>
                {/* Title Section */}
                <Typography variant="h4" align="center" gutterBottom>
                    Refunds & Cancellations Policy
                </Typography>

                <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                    Last updated January 26, 2025
                </Typography>

                {/* Agreement to Policies Section */}
                <Paper elevation={3} sx={{ padding: 2, marginBottom: 3 }}>
                    <Typography variant="body1" paragraph>
                        <strong>Agreement to Policies:</strong> By using our platform and services, you agree to abide by these refund and cancellation policies. It is your responsibility to familiarize yourself with these policies before making any purchases or bookings.
                    </Typography>
                </Paper>

                {/* Refunds Section */}
                <Typography variant="h5" gutterBottom>
                    REFUNDS
                </Typography>
                <Paper elevation={3} sx={{ padding: 2, marginBottom: 3 }}>
                    <Typography variant="body1" paragraph>
                        <strong>Final Decision on Refunds:</strong> In the event of any dispute regarding refunds, the final decision will be at the sole discretion of the company. The customer agrees to abide by the company’s decision.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Refund Delay:</strong> If there is a delay in processing the refund, the customer agrees not to take any legal or other action against the company. The company is not liable for any delays in the refund process.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Refund Amount:</strong> The company reserves the right to determine the amount of the refund based on the circumstances of the purchase and return. The final refund amount will be decided solely by the company.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Refund Eligibility:</strong> The company retains the discretion to approve or deny a refund based on the nature of the transaction and compliance with the company’s policies.
                    </Typography>
                </Paper>

                {/* Refunds Processing Section */}
                <Typography variant="h5" gutterBottom>
                    REFUNDS PROCESSING
                </Typography>
                <Paper elevation={3} sx={{ padding: 2, marginBottom: 3 }}>
                    <Typography variant="body1" paragraph>
                        All refunds will be processed within 7 to 10 business working days, and in some cases, refunds may be issued by the end of the current billing cycle. Please note that the company reserves the right to delay the refund process due to unavoidable circumstances, including but not limited to technical issues, high transaction volumes, or other unforeseen events. We appreciate your understanding and patience during such times, and we strive to ensure that all refunds are handled as efficiently as possible.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We will notify you by email when your refund has been processed.
                    </Typography>
                </Paper>

                {/* Cancellations Section */}
                <Typography variant="h5" gutterBottom>
                    CANCELLATIONS
                </Typography>
                <Paper elevation={3} sx={{ padding: 2, marginBottom: 3 }}>
                    <Typography variant="body1" paragraph>
                        <strong>Cancellation before 48 hours:</strong> If the user cancels the booking more than 48 hours before the session start time, they will receive a full refund of the amount paid.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Cancellation before 36 hours:</strong> If the user cancels the booking more than 36 hours but less than 48 hours before the session start time, they will receive a 75% refund of the total paid amount at the time of booking.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Cancellation before 24 hours:</strong> If the user cancels the booking more than 24 hours but less than 36 hours before the session start time, they will receive a 50% refund of the total paid amount at the time of booking.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Cancellation before 12 hours:</strong> If the user cancels the booking more than 12 hours but less than 24 hours before the session start time, they will receive a 25% refund of the total paid amount at the time of booking.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Cancellation within 12 hours:</strong> If the user cancels the booking less than 12 hours before the session start time, no refund will be provided.
                    </Typography>
                </Paper>

                {/* Contact Us Section */}
                <Typography variant="h5" gutterBottom>
                    CONTACT US
                </Typography>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="body1" paragraph>
                        If you have any questions related to our refunds and cancellations policy, please contact us at:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>ANTHRASYNC SOLUTIONS PRIVATE LIMITED</strong>
                        <br />
                        No 26, Laskar Hosur Road, Adugodi, Bangalore South
                        <br />
                        Bangalore, Karnataka 560030
                        <br />
                        India
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Email:</strong> official@anthrasync.com
                    </Typography>
                </Paper>
            </Box>
        </Container>
        <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
        <Footer2/>
        </div>
    );
};

export default RefundsAndCancellationsPolicy;
