import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Typography, Container, Paper, Tab, Tabs } from '@mui/material';
import { format, addDays, startOfDay, isSameDay } from 'date-fns';

const SlotBookingPage = () => {
  // State to hold selected date, slots and availability status
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  
  // Number of available slots per day (72 slots for 24 hours with 20 mins duration each)
  const totalSlots = 72;

  // Slot duration (in minutes)
  const slotDuration = 20;

  // Generate slot times for a given day
  const generateSlots = (date) => {
    const slots = [];
    let currentTime = startOfDay(date); // Start from the beginning of the day
    for (let i = 0; i < totalSlots; i++) {
      slots.push({
        id: i,
        time: format(currentTime, 'hh:mm a'),
        isAvailable: true, // Assume all slots are initially available
      });
      currentTime = addDays(currentTime, slotDuration / 1440); // Increment by 20 mins
    }
    return slots;
  };

  // Handle date change (on selecting new date)
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setAvailableSlots(generateSlots(date));
  };

  // Handle slot selection
  const handleSlotBooking = (slotId) => {
    setAvailableSlots((prevSlots) =>
      prevSlots.map((slot) =>
        slot.id === slotId ? { ...slot, isAvailable: false } : slot
      )
    );
    setSelectedSlot(slotId);
  };

  // Initialize available slots when component mounts or date changes
  useEffect(() => {
    setAvailableSlots(generateSlots(selectedDate));
  }, [selectedDate]);

  return (
    <Container maxWidth="lg" sx={{ paddingTop: '2rem' }}>
      {/* Title */}
                                           <Box sx={{ textAlign: 'center' }}>
                                                          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                                                              Stage 4: Slot booking for online meet
                                                          </Typography>
                                                          {/* <Typography variant="subtitle1" sx={{ color: 'white', mt: 1 }}>
                                                              Unlock premium features and resources tailored for your needs
                                                          </Typography> */}
                                                      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ marginBottom: '2rem' }}>
          Slot Booking System
        </Typography>

        {/* Date Picker */}
        <Box sx={{ marginBottom: '1.5rem' }}>
          <Typography variant="h6">Select Date</Typography>
          <input
            type="date"
            value={format(selectedDate, 'yyyy-MM-dd')}
            onChange={(e) => handleDateChange(new Date(e.target.value))}
            style={{
              padding: '10px',
              fontSize: '16px',
              border: '2px solid #ccc',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          />
        </Box>

        {/* Slot Grid for selected date */}
        <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
          Available Slots for {format(selectedDate, 'MMMM dd, yyyy')}
        </Typography>

        {/* Grid of slot buttons */}
        <Grid container spacing={2} justifyContent="center">
          {availableSlots.map((slot) => (
            <Grid item xs={4} sm={2} key={slot.id}>
              <Button
                variant="contained"
                color={slot.isAvailable ? 'primary' : 'secondary'}
                sx={{
                  width: '100%',
                  height: '50px',
                  textTransform: 'none',
                  fontSize: '16px',
                  cursor: slot.isAvailable ? 'pointer' : 'not-allowed',
                  '&:hover': {
                    backgroundColor: slot.isAvailable ? '#1976d2' : '#e0e0e0',
                  },
                }}
                disabled={!slot.isAvailable}
                onClick={() => handleSlotBooking(slot.id)}
              >
                {slot.time}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default SlotBookingPage;
