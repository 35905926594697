import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Typography, Button, Collapse } from '@mui/material';
import axios from 'axios'; // Import axios for API requests
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function MyPosts({ userId, searchResults }) {
    const [courses, setCourses] = useState([]);  // Initialize as an empty array, not null
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedCourse, setExpandedCourse] = useState(null);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);  // Start with page 1
    const [allFetched, setAllFetched] = useState(false);

    // const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/meeting-guidelines');  // Redirect to meeting guidelines page
    };

    // Fetch the user's courses data
    const fetchMyPosts = async (page) => {
        setLoading(true);

        try {
            const response = await fetch(`${API_URL}/list_my_posts_data/?user_id=${userId}&page=${page}`);
            if (!response.ok) {
                throw new Error('Failed to fetch posts');
            }

            const data = await response.json();

            // If no data is returned, we stop fetching
            if (data.length === 0) {
                setAllFetched(true);  // Mark as all data fetched
            } else {
                setCourses((prevCourses) => {
                    // Ensure prevCourses is an array before using .some()
                    const newCourses = data.filter(course => !Array.isArray(prevCourses) || !prevCourses.some(existingCourse => existingCourse.id === course.id));
                    return [...prevCourses, ...newCourses];  // Append new data
                });
            }

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    // Fetch data when the page changes (triggered by scroll)
    useEffect(() => {
        if (!allFetched) {
            fetchMyPosts(page);
        }
    }, [page]);

    // Handle scroll to trigger next page fetch
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (windowHeight + scrollPosition >= documentHeight - 1 && !loading && !allFetched) {
            setPage((prevPage) => prevPage + 1);  // Increment page to load the next set of data
        }
    };

    // Add event listener for scroll on component mount and cleanup on unmount
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, allFetched]); // Only re-run the effect when `loading` or `allFetched` changes

    // UseEffect for handling `searchResults` updates
    useEffect(() => {
        if (searchResults) {
            setCourses(searchResults);  // Reset courses to searchResults if provided
            setPage(1);  // Reset page to 1 on new search
            setAllFetched(false);  // Allow further fetches if new search is performed
        }
    }, [searchResults]);

    // Loading state
    if (loading) {
        return <Typography variant="h6" sx={{ textAlign: "center", padding: "2rem" }}>Loading...</Typography>;
    }

    // Error handling
    if (error) {
        return <Typography variant="h6" sx={{ textAlign: "center", padding: "2rem", color: 'red' }}>{error}</Typography>;
    }

    // If no courses found
    if (!courses || courses.length === 0) {
        return <Typography variant="h6" sx={{ textAlign: "center", padding: "2rem" }}>No posts available</Typography>;
    }

    // Toggle visibility of booked users list
    const handleExpandClick = (courseId) => {
        setExpandedCourse(expandedCourse === courseId ? null : courseId); // Toggle visibility
    };

    const getSessionEndTime = (startTime, durationInMinutes, selectedDate) => {
        const [startHours, startMinutes] = startTime.split(':');
        const sessionStartTime = new Date(selectedDate);
        sessionStartTime.setHours(startHours);
        sessionStartTime.setMinutes(startMinutes);
        sessionStartTime.setSeconds(0);

        // Add the duration to the start time
        const sessionEndTime = new Date(sessionStartTime.getTime() + durationInMinutes * 60 * 1000);  // duration in milliseconds
        return sessionEndTime;
    };

    const handleCourseDetailsNavigation = (courseId,bookedUsers) => {
        // Navigate to the course details page, passing the course ID as a URL parameter
        navigate(`/show-booked-users/${courseId}`, { state: { bookedUsers } });
    };

    const convertToAMPM = (time) => {
        const [hours, minutes] = time.split(':'); // Get hours and minutes
        const date = new Date();
        date.setHours(parseInt(hours)); // Set hours in 24-hour format
        date.setMinutes(parseInt(minutes)); // Set minutes
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        return date.toLocaleTimeString([], options); // Use locale to get 12-hour format with AM/PM
      };

      const convertDuration = (duration) => {
        const hours = Math.floor(duration / 60); // Calculate hours
        const minutes = duration % 60; // Get remaining minutes
    
        let timeString = "";
    
        if (hours > 0) {
          timeString += `${hours} hour${hours > 1 ? "s" : ""} `;
        }
        if (minutes > 0) {
          timeString += `${minutes} minute${minutes > 1 ? "s" : ""}`;
        }
    
        return timeString.trim(); // Remove extra space if no minutes
      };
    
    
    

    return (
        <Box sx={{ padding: "2rem" }}>
            <Grid container spacing={5} direction="column">
                {courses.map((course) => {
                    const fullDateTimeString = `${course.selectedDate}T${course.startTime}`;
                    const sessionStartTime = new Date(fullDateTimeString);
                    const sessionEndTime = getSessionEndTime(course.startTime, course.duration, course.selectedDate);
                    const currentTime = new Date();
                    const isSessionEnded = currentTime.getTime() >= sessionEndTime.getTime();
                    const isSessionAboutToStart = currentTime.getTime() >= sessionStartTime.getTime() - 10 * 60 * 1000; // 10 minutes before start
                    const isSessionOngoing = currentTime.getTime() >= sessionStartTime.getTime() && currentTime.getTime() <= sessionEndTime.getTime();
                    
                    const formattedTime = convertToAMPM(course.startTime);
                    const formattedDuration = convertDuration(course.duration);

                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={course.id}>
                            <Box
                                sx={{
                                    padding: { xs: "0.5rem", sm: "1rem" },  // Adjust padding for smaller screens
                                    backgroundColor: "#3333",  // Dark grayish blue (suits black background)
                                    color: "#ecf0f1",  // Light gray text for good contrast
                                    borderRadius: "16px",
                                    boxShadow: 3,
                                    height: "auto",
                                    maxHeight: { xs: "650px", sm: "700px" }, // Adjust max height for smaller screens
                                    display: "flex",
                                    flexDirection: "column", // Stack content vertically
                                    justifyContent: "space-between", // Ensure spacing between content blocks
                                    '&:hover': {
                                        boxShadow: 6,
                                        backgroundColor: "#1111",  // Slightly lighter shade on hover
                                        border: `2px solid #1E90FF`,
                                    },
                                    transition: "box-shadow 0.3s ease-in-out",
                                    overflow: "hidden",  // Hide overflow content if height is exceeded
                                    width: "100%",  // Full width for all screen sizes
                                    maxWidth: "100%", // Ensure max-width does not exceed 100% of container
                                    // margin: { xs: "1rem", sm: "2rem" }, // Responsive margin for spacing
                                    marginLeft: {
                                        xs: '1rem',  // Add margin-left of 2rem for small screens
                                        sm: '0',     // No margin-left for larger screens
                                      },
                                }}
                            >
                                {/* Section Name and Posted Date */}
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },  // Stack content on small screens, row on larger ones
                                    justifyContent: "space-between",
                                    marginBottom: { xs: "0.5rem", sm: "1rem" },  // Adjust margin based on screen size
                                    alignItems: { xs: "flex-start", sm: "center" },  // Align items differently on smaller screens
                                }}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: "#ecf0f1",
                                            fontWeight: "bold",
                                            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },  // Adjust font size based on screen size
                                            display: 'flex',  // Use flexbox for better control
                                            justifyContent: { xs: "center", sm: "center", md: "center" },  // Center content horizontally
                                            alignItems: { xs: "center", sm: "center", md: "center" },  // Center content vertically
                                            textAlign: { xs: "center", sm: "center", md: "center" },  // Center text for all screen sizes (fallback for smaller screens)
                                            width: '100%'  // Ensure the element takes up full width
                                        }}
                                    >
                                        {course.subsection_name}
                                    </Typography>


                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: "#95a5a6",
                                            display: 'flex',  // Use flexbox for alignment control
                                            justifyContent: 'center',  // Center content horizontally for all screens
                                            alignItems: 'center',  // Center content vertically for all screens
                                            textAlign: 'center',  // Center text for all screens
                                            fontSize: { xs: "0.75rem", sm: "0.85rem" },  // Adjust font size for captions on smaller screens
                                            width: '100%'  // Ensure it takes full width for proper centering
                                        }}
                                    >
                                        Posted on: {new Date(course.created_at).toLocaleDateString()} {/* Display only date */}
                                    </Typography>

                                </Box>


                                {/* Course Title (Centered) */}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        textAlign: { xs: "center", sm: "center" },  // Align text to the left on small screens and center on larger screens
                                        fontWeight: "bold",
                                        color: "#ecf0f1",
                                        marginBottom: { xs: "0.5rem", sm: "1rem" },  // Adjust margin based on screen size
                                        fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.8rem" },  // Adjust font size based on screen size
                                        '&:hover': {
                                            color: '#1E90FF', // Light blue color on hover
                                        },
                                    }}
                                >
                                    {course.topic_name}
                                </Typography>


                                {/* Course Details (Bottom Row) */}
                                <Box
  sx={{
    display: "flex",
    justifyContent: { xs: "center", sm: "space-between" }, // Center content on small screens, space-between on larger screens
    marginTop: "auto",
    flexWrap: "wrap",
    gap: "0.5rem",
  }}
>
                                    <Typography variant="body2" sx={{ color: "#bdc3c7", fontSize: { xs: "0.9rem", sm: "1rem" }, marginBottom: { xs: "0.5rem", sm: "1rem" } }}>
                                        Price: {course.price}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "#bdc3c7", fontSize: { xs: "0.9rem", sm: "1rem" }, marginBottom: { xs: "0.5rem", sm: "1rem" } }}>
                                        Language: {course.language}
                                    </Typography>
                                    
                                    <Typography variant="body2" sx={{ color: "#bdc3c7", fontSize: { xs: "0.9rem", sm: "1rem" }, marginBottom: { xs: "0.5rem", sm: "1rem" } }}>
                                        Date: {course.selectedDate}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "#bdc3c7", fontSize: { xs: "0.9rem", sm: "1rem" }, marginBottom: { xs: "0.5rem", sm: "1rem" } }}>
                                        Time: {formattedTime}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "#bdc3c7", fontSize: { xs: "0.9rem", sm: "1rem" }, marginBottom: { xs: "0.5rem", sm: "1rem" } }}>
                                        Duration: {formattedDuration}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "#bdc3c7", fontSize: { xs: "0.9rem", sm: "1rem" }, marginBottom: { xs: "0.5rem", sm: "1rem" } }}>
                                        Booked Seats: {course.count}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "#bdc3c7", fontSize: { xs: "0.9rem", sm: "1rem" }, marginBottom: { xs: "0.5rem", sm: "1rem" } }}>
                                        Total Seats: {course.max_limit}
                                    </Typography>


                                    {/* Show the "Booked Users" button and list of names */}
                                    {/* {course.booked_users && course.booked_users.length > 0 && (
                                        <Box sx={{
                                            marginTop: { xs: "0.5rem", sm: "1rem" }, // Adjust margin-top for smaller screens
                                            textAlign: "center",
                                            width: "100%", // Ensure it's responsive to screen width
                                        }}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleExpandClick(course.id)} // Toggle visibility
                                                sx={{
                                                    width: { xs: "100%", sm: "50%" },  // Full width on small screens, 50% on larger screens
                                                    padding: { xs: "0.8rem", sm: "1rem" },  // Adjust padding for different screen sizes
                                                }}
                                            >
                                                {expandedCourse === course.id ? "Hide Booked Users" : "Show Booked Users"}
                                            </Button>

                                            <Collapse in={expandedCourse === course.id} timeout="auto" unmountOnExit>
                                                <Box sx={{ marginTop: { xs: "0.5rem", sm: "1rem" } }}>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: "#ecf0f1",
                                                            fontSize: { xs: "0.9rem", sm: "1rem" }, // Adjust font size for small screens
                                                        }}
                                                    >
                                                        Booked Users:
                                                    </Typography>
                                                    {course.booked_users.map((user, index) => (
                                                        <Typography
                                                            key={index}
                                                            variant="body2"
                                                            sx={{
                                                                color: "#bdc3c7",
                                                                marginLeft: { xs: "0.5rem", sm: "1rem" },  // Adjust left margin for responsiveness
                                                                fontSize: { xs: "0.9rem", sm: "1rem" }, // Adjust font size for small screens
                                                            }}
                                                        >
                                                            {user}
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            </Collapse>
                                        </Box>

                                    )} */}

                                    {
                                        course.booked_users && course.booked_users.length > 0 && (
                                            <Box sx={{
                                                marginTop: { xs: "0.5rem", sm: "1rem" },
                                                textAlign: "center",
                                                width: "100%",
                                            }}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => handleCourseDetailsNavigation(course.id,course.booked_users)} // Navigate to course details page
                                                    sx={{
                                                        width: { xs: "100%", sm: "50%" },
                                                        padding: { xs: "0.8rem", sm: "1rem" },
                                                    }}
                                                >
                                                    View Booked Users
                                                </Button>
                                            </Box>
                                        )
                                    }

                                    {/* Show the appropriate button based on the session state */}
                                    {course.comments ? (
                                        <Typography
                                        variant="h6"
                                        sx={{
                                          color: '#2ecc71',
                                          fontSize: { xs: '1rem', sm: '1.5rem', md: '1.75rem' }, // Adjust font size for different screen sizes
                                          textAlign: 'center', // Ensure text is center-aligned on all screen sizes
                                          marginBottom: { xs: '1rem', sm: '1.25rem', md: '1.5rem' }, // Adjust margin based on screen size
                                          fontStyle: 'italic', // Apply italic font style
                                        }}
                                      >
                                        Thank you for your feedback!
                                      </Typography>
                                      

                                    ) : isSessionEnded ? (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{
                                                width: { xs: '100%', sm: 'auto' }, // Full width on xs, auto width on larger screens
                                                padding: { xs: '0.75rem 1rem', sm: '0.875rem 1.5rem' }, // Adjust padding for small and larger screens
                                                fontSize: { xs: '0.875rem', sm: '1rem' }, // Adjust font size for different screen sizes
                                                textAlign: 'center', // Ensure text is centered
                                                marginTop: { xs: '1rem', sm: '1.5rem' }, // Adjust margin top for responsiveness
                                            }}
                                            onClick={() => navigate(`/guide-feedbackform/${course.id}`)}
                                        >
                                            Give Feedback
                                        </Button>

                                    ) : isSessionAboutToStart ? (
                                        <a href={course.meeting_link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    width: { xs: '100%', sm: 'auto' }, // Full width on small screens, auto on larger screens
                                                    padding: { xs: '0.75rem 1rem', sm: '0.875rem 1.5rem' }, // Adjust padding for responsiveness
                                                    fontSize: { xs: '0.875rem', sm: '1rem' }, // Adjust font size for small and large screens
                                                    textAlign: 'center', // Ensure text is centered
                                                    marginTop: { xs: '1rem', sm: '1.5rem' }, // Adjust margin top for different screen sizes
                                                }}
                                            >
                                                Join Now
                                            </Button>
                                            <Typography
                                              sx={{
                                                mt: { xs: 1, sm: 2 }, // Adjust margin top for different screen sizes
                                                textAlign: 'center',
                                                color:"#1E90FF",
                                                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }, // Adjust font size for responsiveness
                                              }}
                                            >
                                              By clicking <strong>Join Now</strong>, you will be redirected to an external meeting platform. By doing so, you agree to that platform's Terms of Service and Privacy Policy.
                                            </Typography>

                                        </a>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="info"
                                            onClick={handleRedirect}
                                            sx={{
                                                width: { xs: '100%', sm: 'auto' },  // Full width on small screens, auto on larger screens
                                                padding: { xs: '0.75rem 1rem', sm: '0.875rem 1.5rem' },  // Adjust padding for different screen sizes
                                                fontSize: { xs: '0.875rem', sm: '1rem' },  // Adjust font size for responsiveness
                                                textAlign: 'center',  // Ensures text is centered
                                                marginTop: { xs: '1rem', sm: '1.5rem' },  // Adjust margin top for spacing
                                            }}
                                        >
                                            Meeting Instructions
                                        </Button>

                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}

export default MyPosts;
