import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, List, ListItem, ListItemText, Divider, IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function VideoCreationGuidelinesPage({userId,loggedIn, onLogout}) {

  
      const navigate = useNavigate();
      const scrollToTop = () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        };
  
      const handleLogout = () => {
          onLogout(); // Call the passed logout function
          navigate('/login'); // Navigate to login page after logout
        };
  return (
    <div>
      {loggedIn ? (
        <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
      ) : (
        <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
      )}
    <Container maxWidth="md" sx={{ padding: '20px', mt:10 }}>
      <Typography variant="h3" textAlign={'center'} gutterBottom>
        Video Creation Guidelines
      </Typography>

      <Typography variant="body1" paragraph>
        We are excited to see your video submission! To make sure your video meets the required guidelines, please follow the instructions below:
      </Typography>

      <Typography variant="h5" gutterBottom>
        General Instructions
      </Typography>

      <Typography variant="body1" paragraph>
        You can create the video in any language of your choice. Express yourself freely, and be authentic in your response. The video should be uploaded to YouTube or Google Drive, and you need to share the link with us. 
      </Typography>

      <Typography variant="h6" gutterBottom>
        Please make sure your video answers the following questions:
      </Typography>

      <List>
        <ListItem>
          <ListItemText primary="1. Tell us something about yourself." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Your background and location." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Current roles." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. Interests and passions." />
        </ListItem>
        <ListItem>
          <ListItemText primary="5. Why Anthrasync?" />
        </ListItem>
        <ListItem>
          <ListItemText primary="6. Which category and subcategory are you applying for, and why?" />
        </ListItem>
        <ListItem>
          <ListItemText primary="7. What will you do after getting posting access?" />
        </ListItem>
      </List>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" gutterBottom>
        Tips for Recording Your Video
      </Typography>
      <Typography variant="body1" paragraph>
        Here are a few tips to help you record a great video:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="1. Choose a quiet and well-lit environment for recording." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Ensure good audio quality – speak clearly and avoid background noise." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Keep the video length concise and to the point, ideally between 2 to 5 minutes." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. Make sure your video answers all the questions thoroughly." />
        </ListItem>
        <ListItem>
          <ListItemText primary="5. Check the video link before submission to ensure it works properly." />
        </ListItem>
      </List>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="body1" paragraph>
        Once you're done recording and uploading your video, please share the link with us in the designated submission area.
      </Typography>

      <Typography variant="h6" gutterBottom>
        We look forward to reviewing your submission!
      </Typography>
    </Container>
    <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
   <Footer2/>
    </div>
  );
}

export default VideoCreationGuidelinesPage;

