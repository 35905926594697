import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

const CreateLanguage = ({ userId, onLogout }) => {
    const [languageName, setLanguageName] = useState('');
    const [error, setError] = useState(null);

    const handleCreateLanguage = async () => {
        if (!languageName) {
            setError('Language name is required');
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/create_langauge/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: languageName }),
        });

        const data = await response.json();

        if (response.ok) {
            showAlert()
            setLanguageName('');
        } else {
            setError(data.message || 'Failed to create language');
        }
    };

    const showAlert = () => {
        const alertDiv = document.createElement('div');
        alertDiv.textContent = 'Language Created successfully';
        alertDiv.style.position = 'fixed';
        alertDiv.style.top = '10px';
        alertDiv.style.left = '50%';
        alertDiv.style.transform = 'translateX(-50%)';
        alertDiv.style.padding = '10px 20px';
        alertDiv.style.backgroundColor = 'green';
        alertDiv.style.color = 'white';
        alertDiv.style.borderRadius = '5px';
        alertDiv.style.zIndex = '9999';
        alertDiv.style.fontSize = '16px';
    
        document.body.appendChild(alertDiv);
    
        // Auto remove the alert after 3 seconds
        setTimeout(() => {
            alertDiv.remove();
        }, 3000);
    };

    return (
        <Box
            sx={{
                p: 4,
                bgcolor: '#121212', // dark background color
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)', // shadow for better visibility
            }}
        >
            <Typography variant="h5" sx={{ mb: 3, color: '#fff' }}>
                Create New Language
            </Typography>
            
            <TextField
                label="Language Name"
                variant="outlined"
                fullWidth
                value={languageName}
                onChange={(e) => setLanguageName(e.target.value)}
                sx={{
                    mb: 2,
                    '& .MuiOutlinedInput-root': {
                        bgcolor: '#222', // dark input background
                        color: '#fff',
                        '& fieldset': {
                            borderColor: '#555', // light border color for dark theme
                        },
                        '&:hover fieldset': {
                            borderColor: '#1E90FF', // border color on hover
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#aaa', // faded label color
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#1E90FF', // focused label color
                    },
                }}
            />

            {error && (
                <Typography variant="body2" sx={{ color: '#f44336', mb: 2 }}>
                    {error}
                </Typography>
            )}

            <Button
                variant="contained"
                onClick={handleCreateLanguage}
                sx={{
                    bgcolor: '#1E90FF',
                    '&:hover': { bgcolor: '#0066cc' },
                    fontWeight: 'bold',
                    px: 4,
                    py: 1.5,
                }}
            >
                Create Language
            </Button>
        </Box>
    );
};

export default CreateLanguage;
