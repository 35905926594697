import React, { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  InputBase,
  Tab,
  Tabs,
  Paper,
  CssBaseline,
  createTheme,
  ThemeProvider,
  Fade,
  Card,
  CardContent,
  Drawer,
  CardActionArea,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Header1 from '../Header/Header1';
import Footer2 from "../Footer/Footer2";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Link } from 'react-router-dom';
import { Menu as MenuIcon } from "@mui/icons-material";
import { useLocation, useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';

// Helper function to parse and format content
const parseMarkdownContent = (text) => {
  // Convert markdown-style links to <a> tags
  const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g;
  text = text.replace(linkRegex, (match, text, url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`;
  });

  // Convert markdown headings (e.g., # Heading) to <h1>, <h2>, etc.
  const headingRegex = /^(#{1,6})\s*(.+)$/gm;
  text = text.replace(headingRegex, (match, hashes, headingText) => {
    const level = hashes.length; // Number of `#` determines the level of heading
    return `<h${level} class="markdown-heading">${headingText}</h${level}>`; // Replace # with appropriate heading tags
  });

  // Convert line breaks to <br /> tags
  text = text.replace(/\n/g, "<br />");

  // Wrap paragraphs in <p> tags
  text = text.replace(/([^\n]+)\n?/g, "<p>$1</p>");

  return text;
};

// Sections Content Component (Card Style)
const SectionsContent = ({ value, setHeadings }) => {
  const navigate = useNavigate();

  const location = useLocation();  // Track the current route location

    // useEffect(() => {
    //     // Check if a route is saved in localStorage
    //     const savedRoute = localStorage.getItem('currentRoute');
    //     console.log('savedRoute', savedRoute);

    //     if (savedRoute) {
    //         // Navigate to the saved route when the app starts
    //         navigate(savedRoute);
    //     }
    // }, [navigate]);

    // useEffect(() => {
    //     // Save the current route (pathname + search) in localStorage whenever the route changes
    //     const currentPath = location.pathname + location.search;  // includes any query parameters
    //     localStorage.setItem('currentRoute', currentPath);
    //     console.log('Route saved to localStorage:', currentPath);
    // }, [location]);

  const sections = [
    {
      title: "Overview",
      content: `<h3 id="overview">Welcome to Anthrasync</h3><p>This platform is designed to meet the knowledge needs of all individuals from diverse backgrounds. At Anthrasync, we focus on providing an innovative knowledge exchange environment that benefits users with different expertise and interests. The current platform is in beta, with limited features.</p>
      <h3 id="documentation">Documentation</h3><p>This documentation section provides brief information about the current platform and its features. It is designed to guide you through various aspects of the platform and help you get the most out of your experience.</p><p>You can also go through some of our policies, Terms of Service, and other important information related to the platform, users, and the company. This will help you understand how we operate and how we protect user data.</p>
<h3 id="continuous-updates">Continuous Updates</h3><p>We will be continuously adding more cutting-edge features and updating the platform to enhance the user experience. We are committed to ensuring that the platform stays ahead of trends and meets the growing needs of our users.</p>
<h3 id="stay-updated">Stay Updated</h3><p>Keep visiting Announcements section of Home page to receive recent updates about our platform. Stay informed about new features, improvements, and upcoming events directly from us.</p><p>Please keep visiting our website <a href="https://www.anthrasync.com/" target="_blank" rel="noopener noreferrer">Anthrasync</a> for more updates.</p>
`,
    },
    {
      title: "User Portal",
      content: `
        <h3 id="user-portal">Users Can View All Their Information on This Page</h3><p>The top navigation panel provides all the fields inside the User Portal page. Users can easily navigate to a particular area by clicking on the respective field name in the Navigation Panel.</p><ol><li>Profile</li><li>Analytics</li><li>Preferences</li><li>About</li><li>Personal</li><li>Contact</li><li>Social</li><li>Address</li><li>Change Password</li><li>Delete Account</li></ol>
        <h3 id="profile">Profile</h3><p>The Profile section displays the profile picture and full user name. Users can edit their profiles using the edit icon provided below the profile section. The profile picture and name will be visible to all other users when they view the posts made by the user. Social media icons will appear alongside the profile picture if the user has entered links in the Social Media Profiles section.</p>
        <h3 id="analytics"> Analytics </h3> <p>This panel displays important information related to the user's performance on our platform, such as:</p><ol><li>Experience</li><li>User Ratings</li><li>Total Number of Posts Made</li><li>Total Number of Bookings Received (Displayed only for verified users)</li><li>Total Earnings (Displayed only for verified users)</li></ol>
        <h3 id="preferences">Preferences</h3><p>Users can select or change their preferences at any time. Our recommendation system will use these preferences to personalize the platform. By clicking the 'Change My Preferences' button, users will be redirected to a set of questions, where they can select their preferred categories, subcategories, and languages.</p>
        <h3 id="about-me">About Me</h3><p>In this section, users are requested to provide a short description about themselves. This description will be displayed to all other users during bookings.</p>
        <h3 id="personal-info">Personal Info</h3><p>Basic user information such as the user's name, surname, date of birth, and gender is displayed in this section. This information will not be visible to other users.</p>
        <h3 id="contact-info">Contact Info</h3><p>This section displays user contact details such as email and contact number. These details will not be visible to other users.</p>
        <h3 id="social-media-profiles">Social Media Profiles</h3><p>In this section, users can enter links to their social media profiles such as Facebook, Instagram, X , LinkedIn, and YouTube. These details will be visible to other users when they click on the corresponding profile icons during bookings. When entering social media profile links, the corresponding social media icons will appear next to the user's profile picture.</p>
        <h3 id="address-info">Address Info</h3><p>The address details, including city, state, country, and postcode, will be displayed in this section. These details will not be visible to other users.</p>
        <h3 id="change-password">Change Password</h3><p>Users can use this section to change their passwords. Users logged in via Google can directly set a new password.</p>
        <h3 id="delete-account">Delete Account</h3><p>The user can delete their account. All user data will be deleted 30 days after the account deletion request. Once the user presses the "Delete Account" option, the account cannot be retrieved after deletion.</p>
      `,
    },
    {
      title: "Verification",
      content:`
      <h3 id="verification-overview">Verification Overview</h3><p>Users can apply for verification if they want to make posts in a particular category or subcategory.</p>
      <h3 id="required-documents">Required Documents</h3><p>The user has to submit the following KYC (Know Your Customer) documents for verification:</p><ol><li>Aadhar Card</li><li>Pan Card</li><li>Bank Account Info</li><li>Other Valid Documents (if necessary)</li></ol>
<h3 id="verification-status">Verification Status</h3><p>The user will receive the status of their verification by visiting this page.</p>
<h3 id="interest-selection">Area of Interest Selection</h3><p>Here, the user will also get an opportunity to select their area of interest in which they want to make a post.</p>
<h3 id="post-verification">Post-Verification Access</h3><p>Once verified, a user can apply for access to different areas of interest at different points in time, based on their talent and skills.</p>
<h3 id="verification-decision">Verification Decision</h3><p>The verifying team has the authority to either approve or reject the application based on eligibility criteria.</p>
<h3 id="reapply">Re-apply After Rejection</h3><p>If the application is rejected, the user can re-apply for verification after 30 days.</p>
<h3 id="confidentiality">Confidentiality</h3><p>All the submitted user data during the verification process will be kept confidential and will not be shared with anyone.</p>
      `,
    },
    {
      title:"Subscriptions",
      content:`
      <h3 id="subscriptions-overview">Subscriptions Overview</h3><p>When a user logs into the platform for the first time, they will be given 20 free posts under the free trial subscription model.</p>
<h3 id="subscription-selection">Subscription Selection</h3><p>After the free trial posts are used, the user can choose a subscription model based on their convenience.</p>
<h3 id="subscription-updates">Subscription Updates</h3><p>The subscription models will be continuously updated as new features are added to the platform.</p>
<h3 id="view-subscriptions">View Subscriptions</h3><p>Please visit the Subscriptions section for more details. All the data related to the user's subscription will be displayed here.</p>
      `,
    },
    {
      title:"How to create a post",
      content:`
      <h3 id="session-posting-overview">Session Posting Overview</h3><p>All verified users can post a session based on their topics of interest.</p>
<h3 id="form-options">Form Options</h3><p>The user has the option to either minimize or close the create post form by clicking on the '-' or 'cancel' icon at the top of the form.</p><p>The user can also close the form by selecting the close option at the bottom.</p>
<h3 id="remaining-posts">Remaining Posts</h3><p>The number of remaining posts will be displayed at the top of this page.</p>
<h3 id="verified-user-posting">Verified User Posting</h3><p>The verified user can only post a session to which they had been granted approval during verification.</p>
<h3 id="topic-name">Topic Name</h3><p>The user has to enter the topic name that they want to share knowledge about with users who book this post.</p>
<h3 id="topic-description">Topic Description</h3><p>The user has to enter the description of the topic they are posting about.</p><p>The topic name and description will be displayed to other users during booking.</p>
<h3 id="language-selection">Language Selection</h3><p>The user has to select the language in which they will be taking this session.</p>
<h3 id="session-time">Session Date and Time</h3><p>The user has to select the date and time of the session.</p>
<h3 id="session-duration">Session Duration</h3><p>The maximum duration of a session will be 60 minutes. The user has the privilege to select a duration of their choice, below or equal to 60 minutes.</p>
<h3 id="session-limit">Session Limit</h3><p>The maximum limit for a session is 100 users. The posting user has the privilege to select any limit of their choice, less than or equal to 100.</p>
<h3 id="session-price">Session Price</h3><p>The maximum price for a session is Indian Rupees 100. The posting user has the privilege to select any amount of their choice, less than or equal to 100.</p>
<h3 id="generate-link">Generate Meeting Link</h3><p>After filling all the necessary details, the user has to click the "Generate Meeting Link" button to generate the meeting link.</p>
<h3 id="meeting-link-cleared">Meeting Link Cleared</h3><p>The user can clear the "Meeting Link Generated" message once the link has been generated.</p>
<h3 id="submit-post">Submit Post</h3><p>The user can submit the post and view it in the "My Posts" section in "My Sync."</p>
<h3 id="post-booking">Post Booking</h3><p>The post will now be displayed to all other users for booking.</p>
      `,
    },
    {
      title:"Posting Guidelines",
      content:`
<h3 id="posting-guidelines">Posting Guidelines: Share Content Responsibly</h3><p>We value a positive and constructive environment for all users. To ensure that posts are beneficial and aligned with community standards, we’ve outlined the following posting guidelines. Adherence to these guidelines will help maintain a respectful and engaging experience for all.</p>
<h4 id="content-quality">1. Content Quality</h4><p>All posts should meet the following quality standards:</p><ul><li>Content should be clear, accurate, and well-structured.</li><li>Posts should be relevant to the category or topic in which they are published.</li><li>Provide valuable information that enhances the knowledge-sharing experience for others.</li><li>Avoid using excessive jargon or ambiguous language that may confuse readers.</li></ul>
<h4 id="no-plagiarism">2. No Plagiarism</h4><p>All content must be original and should not plagiarize any third-party sources. Properly attribute any external resources or quotes used in your post, and ensure you have the right to share them.</p>
<h4 id="appropriate-language">3. Use of Appropriate Language</h4><p>Ensure that your post is written using respectful and appropriate language. Avoid:</p><ul><li>Profanity, offensive language, or derogatory comments.</li><li>Personal attacks or insults directed at individuals or groups.</li><li>Discriminatory or biased language based on race, gender, religion, or any other personal attributes.</li></ul>
<h4 id="no-spam">4. No Spam</h4><p>Spamming or posting irrelevant or repetitive content is strictly prohibited. This includes:</p><ul><li>Posting the same content multiple times in different sections.</li><li>Advertising or promoting products, services, or websites unrelated to the platform's purpose.</li><li>Excessive self-promotion or solicitation.</li></ul>
<h4 id="no-harassment">5. No Harassment or Bullying</h4><p>We have a zero-tolerance policy for harassment, bullying, or any form of abusive behavior. All posts should contribute to a positive and welcoming environment for everyone. Any post that targets individuals or groups with harmful intentions will be removed immediately.</p>
<h4 id="compliance-with-laws">6. Compliance with Laws and Regulations</h4><p>All posts must comply with applicable local, national, and international laws. This includes:</p><ul><li>Refraining from posting illegal content, including but not limited to copyrighted material, defamation, or content that promotes illegal activities.</li><li>Respecting intellectual property rights and not sharing pirated or unauthorized content.</li></ul>
<h4 id="no-sexual-content">7. No Sexual Content or Explicit Material</h4><p>Posts containing sexual content, explicit images, or material that is obscene in nature are strictly prohibited. This includes any inappropriate references, images, or videos. We aim to maintain a respectful space for all users.</p>
<h4 id="privacy-respect">8. Respect Privacy</h4><p>Do not share personal information about others without their consent. This includes:</p><ul><li>Personal addresses, phone numbers, or email addresses.</li><li>Confidential information related to individuals or organizations.</li></ul>
<h4 id="correcting-errors">9. Correcting Errors</h4><p>If you realize that your post contains errors or incorrect information, please reach out to us. We encourage all users to be transparent and responsible with the content they share.</p>
<h4 id="moderation-and-removal">10. Moderation and Removal of Posts</h4><p>Our platform has a moderation system in place to ensure that all posts adhere to the guidelines. Posts that violate these guidelines may be removed, and the user may receive a warning or suspension based on the severity of the violation.</p><p>We encourage you to report any content that violates these guidelines using the reporting feature provided on the platform.</p>

      `,
    },
    {
      title:"How to book a session",
      content:`
      <h3 id="booking-overview">Booking Overview</h3><p>All users of the platform are eligible to book any post based on their interests.</p>
<h3 id="post-card-click">Post Card Click</h3><p>To book a post, the user has to click on that particular post card.</p>
<h3 id="redirect-to-booking">Redirect to Booking Page</h3><p>Upon clicking, the user will be redirected to the booking page of that post, which displays all the details related to that post.</p>
<h3 id="booking-page-details">Booking Page Details</h3><p>The booking page displays the following crucial data related to the post:</p>
<h4 id="posting-user-details">Posting User Details</h4><ul><li>Full Name</li><li>Specialized Field of Expertise</li><li>Roles</li><li>About</li><li>Social Media Profiles</li><li>Ratings</li><li>Experience</li></ul>
<h4 id="topic-details">Topic Details</h4><ul><li>Category</li><li>Sub-category</li><li>Topic</li><li>Description</li><li>Language</li><li>Maximum Limit</li><li>Duration</li><li>Number of Remaining Seats</li><li>Meeting Platform</li><li>Date and Time</li></ul>
<h3 id="post-actions">Post Actions</h3><p>The user has the options to either like, dislike, share, or report the post.</p>
<h3 id="book-now">Book Now</h3><p>The user can initiate the booking by clicking on the "Book Now" button.</p>
<h3 id="recommended-posts">Recommended Posts</h3><p>The user can also view the recommended and related posts as they scroll down the booking page.</p>

      `,
    },
    {
      title:"Payments",
      content:`
      <h3 id="payment-overview">Payment Overview</h3><p>When a user clicks on the "Book Now" button on the Booking Page, he/she will be redirected to the Payments page.</p>
<h3 id="razorpay-integration">Razorpay Integration</h3><p>The platform has been integrated with the Razorpay Payments Gateway API to ensure safe and secure checkouts.</p>
<h3 id="payment-page-details">Payment Page Details</h3><p>In the Payment page, the booking user can clearly see the order and booking details along with their billing information.</p>
<h3 id="confirmation">Confirmation Before Payment</h3><p>Before making the payment, the user has to confirm that all the details mentioned on the payment page are correct.</p>
<h3 id="view-policies">View Payment and Refund Policies</h3><p>The user can also view the payment and refund policies and confirm their booking by checking the checkbox provided.</p>
<h3 id="pay-now">Pay Now</h3><p>To initiate the transaction, the user has to click on the "Pay Now" button, which will redirect them to the Razorpay payment gateway.</p>
<h3 id="razorpay-payment-page">Razorpay Payment Page</h3><p>In the Razorpay payments page, the user can clearly see the price as well as their contact details displayed.</p>
<h3 id="complete-payment">Complete Payment</h3><p>The user can complete the payment by selecting the appropriate payment methods provided by Razorpay within the time limit.</p>
<h3 id="payment-status">Payment Status</h3><p>Once the payment is completed, the user will be redirected from the Razorpay payment page to the Anthrasync booking page, where they can view the payment status.</p>
<h3 id="successful-booking">Successful Booking</h3><p>If the booking is successful, the user will see a "Booking Successful" message on the booking page. The user will also receive an email with payment transaction details and booking confirmation details.</p>
<h3 id="my-bookings">My Bookings</h3><p>The user can navigate to the "My Bookings" section of My Sync to view their bookings.</p>
<h3 id="transaction-details">Transaction Details</h3><p>The user can also view the transaction details on the payment details page of My Sync.</p>
<h3 id="retry-payment">Retry Payment</h3><p>If the payment is unsuccessful, the user can retry the payment.</p>
<h3 id="failed-payment-details">Failed Payment Details</h3><p>The user can also view the unsuccessful transaction ID with a failed status in the payment details page.</p>
<h3 id="contact-support">Contact Support</h3><p>The user is free to reach out to the Anthrasync team via the Help section in case of any payment-related issues.</p>

      `,
    },
    {
      title:"My Sync",
      content:`
      <h3 id="my-sync-overview">My Sync Overview</h3><p>All user activity can be seen in the My Sync Page.</p>
<h3 id="my-sync-parts">Parts of My Sync Page</h3><p>The My Sync page consists of four parts:</p><ol><li>Recent Activity</li><li>My Posts</li><li>My Bookings</li><li>Payment Details</li></ol>
<h3 id="recent-activity">Recent Activity</h3><p>In this section, details of all recently viewed posts will be displayed.</p><p>Upon clicking on a particular recent activity tab, the user will be redirected to the booking page of that post.</p>
<h3 id="my-posts">My Posts</h3><p>All the posts done by the user will be visible in this section.</p><p>The user can view the post details as well as the number of bookings received for a particular post, including:</p><ul><li>Category and Subcategory Name</li><li>Topic Name</li><li>Posted User Full Name</li><li>Language</li><li>Date and Time of Session</li><li>Date and Time of Booking</li><li>Duration of Session</li><li>Meeting Instructions</li><li>Join Now button</li><li>Feedback Form</li></ul><p>The user can view the Meeting Instructions and Guidelines by clicking on the Meeting Instructions button on My Posts.</p><p>The Join Now button will appear 10 minutes before the scheduled session time. It's good practice to join 5 minutes before the meeting.</p><p>After the session is completed, the feedback form button will appear.</p><p>Upon clicking the Feedback button, the user will be redirected to the Feedback Page.</p>
<h3 id="my-bookings">My Bookings</h3><p>All the bookings done by the user will be visible in this section.</p><p>The user can view the following booked post details:</p><ul><li>Category and Subcategory Name</li><li>Topic Name</li><li>Posted User Full Name</li><li>Language</li><li>Date and Time of Session</li><li>Date and Time of Booking</li><li>Duration of Session</li><li>Meeting Instructions</li><li>Join Now button</li><li>Feedback Form</li></ul><p>The user can view the Meeting Instructions and Guidelines by clicking on the Meeting Instructions button on My Bookings.</p><p>The Join Now button will appear 10 minutes before the scheduled session time. It's good practice to join 5 minutes before the meeting.</p><p>After the session is completed, the feedback form button will appear.</p><p>Upon clicking the Feedback button, the user will be redirected to the Feedback Page.</p>
<h3 id="payment-details">Payment Details</h3><p>In this section, the user can view all their transaction and payment details.</p><p>The payment details table consists of the following fields:</p><ul><li>Serial Number (for user reference)</li><li>ID</li><li>Date and Time of Transaction</li><li>Transaction ID</li><li>Transaction Status</li><li>Transaction Amount</li><li>Remarks</li></ul><p>All payment and transaction details, whether successful or failed, are displayed in the Payment Details table.</p>
      
      `,
    },
    {
      title:"Meeting Instructions",
      content:`
      <h3 id="meeting-instructions-overview">Meeting Instructions Overview</h3><p>All users, whether verified or normal users, have to comply with the Meeting Instructions.</p><p>To ensure a safe, respectful, and productive environment during all online meetings, we kindly ask all users to follow the guidelines outlined below. These rules are designed to foster a positive experience for all participants and to prevent any disruptive or unethical behavior.</p>
<h3 id="meeting-instructions-guidelines">Meeting Instructions Guidelines</h3>
<h4 id="respectful-communication">1. Respectful Communication</h4><p>Always treat others with respect and professionalism, regardless of their role, background, or opinions. Avoid using offensive language, derogatory comments, or discriminatory remarks. Ensure that all interactions remain civil and do not escalate into personal attacks or harassment.</p>
<h4 id="no-harassment">2. No Harassment or Bullying</h4><p>Harassment, bullying, or intimidation of any kind is strictly prohibited. This includes sending unwanted messages or engaging in disruptive behavior. Please report any such incidents to the meeting host or platform support immediately.</p>
<h4 id="no-sexual-content">3. No Sexual Content or Inappropriate Behavior</h4><p>The sharing, display, or discussion of explicit sexual content, images, videos, or any other inappropriate material will not be tolerated. Participants must refrain from engaging in sexually suggestive or explicit behavior, whether verbally or visually.</p>
<h4 id="illegal-activities">4. Illegal Activities and Knowledge Sharing</h4><p>Sharing or discussing illegal activities, such as hacking, fraud, or the distribution of pirated content, is strictly prohibited. Any form of sharing or promoting illegal, harmful, or dangerous knowledge or behavior will result in immediate removal from the meeting and may lead to account suspension.</p>
<h4 id="privacy-confidentiality">5. Privacy and Confidentiality</h4><p>Be mindful of others' privacy. Do not record or share meeting content without prior consent from all participants. Keep sensitive personal or company information confidential and avoid discussing confidential topics in public or unsecured forums.</p>
<h4 id="appropriate-use-audio-video">6. Appropriate Use of Audio and Video</h4><p>Ensure that your microphone is muted when you are not speaking to avoid background noise. Always be mindful of your surroundings when using video; inappropriate or distracting backgrounds can be disruptive. Use video and audio for constructive purposes only. Do not disrupt the flow of the meeting with unrelated noise or video distractions.</p>
<h4 id="no-trolling-spamming">7. No Trolling or Spamming</h4><p>Refrain from spamming the chat or using disruptive tactics to derail the meeting. All messages in the chat should remain relevant to the meeting’s agenda.</p>
<h4 id="reporting-violations">8. Reporting Violations</h4><p>If you observe any violations of these guidelines, please report them to the meeting host or platform administrator immediately. We are committed to taking appropriate actions to maintain the integrity of our meetings and the safety of all participants.</p>
<h4 id="consequences-unethical-behavior">9. Consequences of Unethical Behavior</h4><p>Any participant found engaging in unethical behavior will be warned and may be removed from the meeting. Repeated violations may result in a permanent ban from the platform.</p>
<h4 id="positive-productive-environment">10. A Positive and Productive Environment</h4><p>Remember that we are all here to collaborate and work together. Let's maintain a positive and productive environment that fosters mutual respect and effective communication.</p>

      
      `,
    },
    {
      title:"Joining a Session",
      content:`
      <h3 id="joining-session-overview">Joining a Session Overview</h3><p>A verified user or posted user can join the session by directly clicking on the link received via email.</p>
<h4 id="join-now-button-verified-user">Join Now Button for Verified Users</h4><p>The Join Now button will be displayed 10 minutes before the session starts in the My Posts section of My Sync.</p><p>Upon clicking the Join Now button, the user will be directly redirected to the Meeting Platform.</p>
<h4 id="join-now-button-regular-user">Join Now Button for Regular Users</h4><p>A regular user or booked user can join the session by directly clicking on the link received via email after successful booking.</p><p>The Join Now button will be displayed 10 minutes before the session starts in the My Bookings section of My Sync.</p><p>Upon clicking the Join Now button, the user will be directly redirected to the Meeting Platform.</p> 
      `,
    },
    {
      title:"Feedback Form",
      content:`
      <h3 id="feedback-form-overview">Feedback Form Overview</h3><p>After the session is completed, the users have to fill the feedback form.</p>
<h4 id="feedback-form-verified-users">For Verified Users or Posted Users</h4><p>The feedback form will be displayed after the session timings in that particular session tab of the My Posts section of My Sync.</p><p>Upon clicking the feedback form button, the users will be redirected to the feedback form page.</p><p>In the Feedback form page, the user has to enter the feedback of the session, experience with other booked users, or any other comments.</p><p>The verified users can also report any misconduct or violation of the Meeting Code of Conduct by booked users.</p>
<h4 id="feedback-form-regular-users">For Regular Users or Booked Users</h4><p>The feedback form will be displayed after the session timings in that particular session tab of the My Bookings section of My Sync.</p><p>Upon clicking the feedback form button, the users will be redirected to the feedback form page.</p><p>In the Feedback form page, the user has to rate the verified user who has shared the knowledge and can enter the feedback of the session, experience with other booked users, or any other comments.</p><p>The users can also report any misconduct or violation of the Meeting Code of Conduct by any other users.</p>
 
      `,
    },
    {
      title:"Experience and Ratings",
      content:`
      <h3 id="experience-ratings">Experience and Ratings</h3>
<h4 id="experience">Experience</h4><p>After the successful completion of a particular session, the experience of verified user will be increased by the duration of that session.</p>
<h4 id="ratings">Ratings</h4><p>After the successful completion of a particular session, the rating of the posted user will be updated based on the rating provided by all the other booked and attended users.</p><p><strong>Formula used for rating calculation:</strong> (previous rating + new rating)/2</p><p><strong>Formula for calculating first-time user rating:</strong> New rating/2</p><p>By default, if no rating is available for a particular user, then the rating will be shown as NRY (Not Rated Yet).</p>

      `,
    },
    {
      title:"User Earnings",
      content:`
      <h3 id="earnings">Earnings</h3><p>All the verified users are eligible for earning.</p><p>The verified user can view his/her earnings displayed in the User Portal.</p><p>All the amount earned by the user will be credited to his/her bank account during the last 10 days of every month.</p><p>Please refer to our payment policies to view the standard service charges that will be applicable for the specified time period.</p>

      `,
    },
    {
      title:"AI Sync",
      content:`
      <h3 id="ai-sync">AI Sync: Empowering Knowledge Exchange with Cutting-Edge AI Tools</h3><p>We’re excited to introduce <strong>AI Sync</strong>, a powerful feature that will revolutionize your knowledge exchange experience. In the coming updates, we’ll be adding advanced AI tools, including chatbots, AI agents, and assistants, all fine-tuned to address the unique needs and interests of every user.</p>
<h4 id="what-to-expect">What to Expect from AI Sync:</h4><ul><li><strong>Instant Support:</strong> Our intelligent chatbots and assistants will provide immediate, relevant answers to your questions—helping you solve problems and find resources without delay.</li><li><strong>Personalized Experience:</strong> AI agents will analyze your knowledge preferences, recommending content and discussions tailored just for you, making learning more efficient.</li><li><strong>Enhanced Collaboration:</strong> AI-powered tools will facilitate seamless group discussions, suggest relevant topics, and help organize content, ensuring that everyone in the community is on the same page.</li><li><strong>Smart Knowledge Management:</strong> With AI Sync, you’ll enjoy smarter searches, content organization, and personalized insights, transforming how you access and contribute knowledge.</li></ul><p>Whether you're looking for fast solutions, want personalized content, or need a smart assistant to guide your journey, <strong>AI Sync</strong> will be there to help you every step of the way.</p><p>Stay tuned for this groundbreaking feature as we work to make your knowledge-sharing experience more intuitive, interactive, and efficient.</p><p><strong>The future of learning and collaboration is here!</strong></p>

      
      `,
    },
    {
      title:"Customer Support",
      content:`
      <h3 id="customer-support">Customer Support: Always Here to Help</h3><p>Have a question or need assistance? Our <strong>Help Section</strong> is available to all users, whether you're logged in or not.</p><p>It’s your go-to resource for finding answers, troubleshooting issues, or learning how to make the most of our platform.</p>
<h4 id="whats-in-help-section">What’s in the Help Section?</h4><ul><li><strong>Troubleshooting Tips:</strong> Solutions to common issues you might encounter.</li><li><strong>Submit a Query:</strong> If you're facing an issue, simply input your problem, and our team will contact you and resolve it at the earliest.</li><li><strong>Contact Support:</strong> Reach out to our team directly for personalized assistance.</li></ul><p>We’re committed to providing you with the support you need to have the best possible experience on our platform.</p><p>Explore the <strong>Help Section</strong> anytime, and we’ll be here to assist you!</p>

      
      `,
    },
    {
      title:"Reporting",
      content:`
      <h3 id="reporting">Reporting: Keeping Our Platform Safe and Ethical</h3><p>We take the safety and integrity of our platform seriously.</p><p>If you encounter any unethical or illegal activities, you can easily report them to us.</p><p>Whether it's inappropriate content, harassment, or other violations of our community guidelines, we encourage you to use our reporting feature to help us maintain a positive and respectful environment.</p>
<h4 id="how-it-works">How it works:</h4><ul><li><strong>Report issues directly:</strong> Use a simple, easy-to-use tool available on the platform.</li><li><strong>Report Icon:</strong> A report icon is provided for every post. You can click the icon to report a particular post.</li><li><strong>Investigation:</strong> Our team will investigate all reports promptly and take appropriate action to address the situation.</li></ul>
<p>Your contributions help us ensure a safe, productive, and respectful space for everyone.</p>
<p>Thank you for being part of our community!</p>   
      `,
    },
    {
      title:"Data Protection",
      content:`
      <h3 id="data-protection">Data Protection: Keeping Your Information Safe and Secure</h3><p>We are committed to safeguarding your personal information and ensuring that your privacy is protected. To help you understand how we manage your data, here are some essential data protection guidelines.</p>
<h4 id="data-collection">1. Data Collection</h4><p>We only collect the necessary data required to provide you with a personalized and secure experience. This may include:</p><ul><li>Your name, email address, and contact details</li><li>Usage data (e.g., session times, interactions, and preferences)</li><li>Payment information for processing transactions</li></ul>
<h4 id="data-use">2. Data Usage</h4><p>Your data will only be used for the following purposes:</p><ul><li>Providing services and features tailored to your preferences</li><li>Processing payments and facilitating transactions</li><li>Improving the platform and user experience</li><li>Communicating with you regarding updates and support</li></ul>
<h4 id="data-storage">3. Data Storage</h4><p>We store your data securely in compliance with industry standards. We use encryption techniques to ensure your data is protected both in transit and at rest.</p><p>Your personal data will only be kept for as long as necessary for the purposes outlined above or as required by law. Once it is no longer needed, it will be securely deleted.</p>
<h4 id="data-sharing">4. Data Sharing</h4><p>We do not share your personal data with third parties unless:</p><ul><li>It is necessary to complete a transaction (e.g., payment gateway providers)</li><li>You have given explicit consent for us to share your information</li><li>We are legally required to do so (e.g., compliance with a court order)</li></ul>
<h4 id="user-rights">5. Your Data Protection Rights</h4><p>As a user, you have the following rights regarding your data:</p><ul><li><strong>Access:</strong> You can request access to your personal data stored by us.</li><li><strong>Correction:</strong> You can request corrections to any inaccuracies in your data.</li><li><strong>Deletion:</strong> You can request the deletion of your data, subject to certain conditions.</li><li><strong>Opt-Out:</strong> You can opt-out of receiving marketing communications at any time.</li></ul>
<h4 id="security-measures">6. Security Measures</h4><p>We have implemented robust security measures to protect your personal data from unauthorized access, alteration, or destruction. These measures include:</p><ul><li>Secure encryption for sensitive data</li><li>Regular security audits and updates</li><li>Access controls to ensure only authorized personnel handle your data</li></ul>
<h4 id="reporting-issues">7. Reporting Data Protection Issues</h4><p>If you believe your personal data has been misused or there has been a breach of data protection policies, please report it to us immediately using the Help Section or contact support.</p><p>We take all such reports seriously and will investigate promptly to resolve any issues and protect your privacy.</p>
<p>Thank you for trusting us with your data. We are committed to maintaining the highest standards of privacy and security.</p>

      
      `,
    },
   

    // Other sections...
  ];

  useEffect(() => {
    // Extract headings and set them for right navigation panel
    const headingRegex = /<h([3-6]) id="([^"]+)">(.+)<\/h\1>/g; // Match h3, h4, h5, h6
    const headings = [];
    let match;
    while ((match = headingRegex.exec(sections[value].content))) {
      headings.push({ id: match[2], text: match[3], level: match[1] });
    }
    setHeadings(headings);
  }, [value]);
  

  return (
    <div>
      <Box p={3}>
        {sections[value] && (
          <Fade in={true} timeout={500}>
            <Box
  sx={{
    marginBottom: 2,
    backgroundColor: "#1111",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    width: '100%',  // Full width for all screen sizes
    maxWidth: { xs: "100%", sm: "100%", md: 1000 },  // Max width of 1000px on medium and larger screens
    margin: '0 auto',  // Center the card horizontally
    padding: { xs: 1, sm: 2 },  // Adjust padding for different screen sizes
    boxSizing: 'border-box',  // Include padding in width calculation
  }}
>
              <Box>
                <Box>
                  <Typography
                    textAlign={'center'}
                    variant="h5"
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }, // Responsive font size
                      wordWrap: 'break-word',
                      userSelect: 'text',
                    }}
                  >
                    {sections[value].title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#bbb",
                      marginTop: "8px",
                      fontFamily: '"Helvetica", "Arial", sans-serif',
                      fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }, // Responsive font size
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                      lineHeight: 1.6,
                      paddingX: { xs: 1, sm: 2, md: 3 },
                      paddingBottom: { xs: 2, sm: 3, md: 4 },
                      userSelect: 'text',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: parseMarkdownContent(sections[value].content),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Fade>
        )}
      </Box>
      <Footer2 /> {/* Assuming Footer2 is already responsive */}
    </div>
  );
};

// Main Documentation Page
const Documentation = ({ userId, onLogout }) => {
  const [value, setValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [headings, setHeadings] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleHeadingClick = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#f50057",
      },
      secondary: {
        main: "#f50057",
      },
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#fff",
        secondary: "#bbb",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });
  // const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false); // For mobile drawer toggle

  const toggleDrawer = (open) => {
    setOpen(open);
  };

  return (
    <div>
      <Header1 userId={userId} page_name="documentation" onLogout={onLogout} />
       {/* Title */}
             <Box sx={{ textAlign: 'center', py: 4, mt: 5}}>
                            <Typography variant="h2" sx={{ fontWeight: 'bold', color: 'white' }}>
                                Documentation
                            </Typography>
                            {/* <Typography variant="subtitle1" sx={{ color: 'white', mt: 1 }}>
                                Unlock premium features and resources tailored for your needs
                            </Typography> */}
                        </Box>
      <ThemeProvider theme={theme}>
        <Box display="flex" flexDirection="row" height="100vh" sx={{ mt: 1 }}>
          <CssBaseline />
          <Box display="flex" flex="1" sx={{ padding: "16px", backgroundColor: "#000000" }}>
            {/* Sidebar */}
            <Box sx={{ width: { xs: "0", sm: 280 }, position: "relative" }}>
              {/* Icon button to toggle tabs on small screens */}
              <IconButton
                onClick={() => toggleDrawer(!open)}
                sx={{
                  display: { xs: "block", sm: "none" }, // Show only on small screens
                  position: "absolute",
                  top: -2,
                  left: -2,
                  color: "#fff",
                }}
              >
                <MenuIcon />
              </IconButton>

              <Paper square sx={{ backgroundColor: "#000000", borderRadius: "20px", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)" }}>
                {/* Tabs component */}
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  orientation="vertical"
                  sx={{
                    backgroundColor: "#000000",
                    borderRadius: "20px",
                    display: { xs: "none", sm: "block" }, // Hide on small screens
                    '& .MuiTab-root': {
                      padding: "16px",
                      transition: "background-color 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#111",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#111",
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <Tab label="Overview" />
                  <Tab label="User Portal" />
                  <Tab label="Verification" />
                  <Tab label="Subscriptions" />
                  <Tab label="Create a Post" />
                  <Tab label="Posting Guidelines" />
                  <Tab label="Book a Session" />
                  <Tab label="Payments" />
                  <Tab label="My Sync" />
                  <Tab label="Meeting Instructions" />
                  <Tab label="Joining Session" />
                  <Tab label="Feedback Form" />
                  <Tab label="Experience and Ratings" />
                  <Tab label="User Earnings" />
                  <Tab label="AI Sync" />
                  <Tab label="Customer Support" />
                  <Tab label="Reporting" />
                  <Tab label="Data Protection" />
                  
                </Tabs>
              </Paper>
            </Box>

            {/* Mobile Drawer to show tabs */}
            <Drawer
              anchor="left"
              open={open}
              onClose={() => toggleDrawer(false)}
              sx={{
                display: { xs: "block", sm: "none" }, // Only show on small screens
              }}
            >
              <Box sx={{ width: 250, padding: "1rem" }}>
                <Typography variant="h6" sx={{ color: "#fff", marginBottom: "1rem" }}>Tabs Menu</Typography>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  orientation="vertical"
                  sx={{
                    backgroundColor: "#000000",
                    '& .MuiTab-root': {
                      padding: "16px",
                      transition: "background-color 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#111",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#111",
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <Tab label="Overview" />
                  <Tab label="User Portal" />
                  <Tab label="Verification" />
                  <Tab label="Subscriptions" />
                  <Tab label="Create a Session" />
                  <Tab label="Posting Guidelines" />
                  <Tab label="Book a Session" />
                  <Tab label="Payments" />
                  <Tab label="My Sync" />
                  <Tab label="Meeting Instructions" />
                  <Tab label="Joining Session" />
                  <Tab label="Feedback Form" />
                  <Tab label="Experience and Ratings" />
                  <Tab label="User Earnings" />
                  <Tab label="AI Sync" />
                  <Tab label="Customer Support" />
                  <Tab label="Reporting" />
                  <Tab label="Data Protection" />
               
                </Tabs>
              </Box>
            </Drawer>

            {/* Main Content */}
            <Box component="main" flex="1" p={3} sx={{ backgroundColor: "#000000" }}>
              <SectionsContent value={value} setHeadings={setHeadings} />
            </Box>

            {/* Right Navigation Panel with clickable headings */}
            <Box
  sx={{
    width: { xs: "0", sm: 280 },  // Hide the box on small screens
    padding: 2,
    position: "sticky",
    top: 20,
    height: "calc(100vh - 40px)",
    overflowY: "auto",
    display: { xs: "none", sm: "block" }, // Hide on small screens, show on larger ones
  }}
>
  <Typography variant="h6" sx={{ color: "#fff", marginBottom: 2 }}>
    Table of Contents
  </Typography>
  {headings.map((heading) => (
    <Typography
      key={heading.id}
      sx={{
        color: "#f50057",
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
        },
        marginBottom: 1,
      }}
      onClick={() => handleHeadingClick(heading.id)}
    >
      {heading.text}
    </Typography>
  ))}
</Box>

          </Box>
        </Box>
      </ThemeProvider>
      <IconButton
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}
      >
        <ArrowUpwardIcon />
      </IconButton>
    </div>

  );
};

export default Documentation;
