import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Button, TextField , Grid, Paper} from '@mui/material';
import CreateSubscriptions from './CreateSubscriptions';
import AddSubSections from './AddSections';
import CreateSections from './CreateSections';
import CreateSpecializedFields from './CreateSpecializedFields';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import AdminVerification from './AdminVerification';
import UpdateSubscription from './UpdateSubscription';
import DeleteSubscription from './DeleteSubscription';
import CreateLanguage from './CreateLanguage';
import DeleteLanguage from './DeleteLanguage';
import UpdateSections from './UpdateSections';
import DeleteSection from './DeleteSection';
import InterviewPanel from './InterviewPanel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AdminFaqs from './AdminFaqs';
import AdminUpdatePosts from './AdminUpdatePosts';
import ReportedPosts from './ReportedPosts';
import GeneralEmail from '../Marketing/GeneralEmail';

const API_URL = process.env.REACT_APP_API_URL;

function AdminPortal({ userId, onLogout }) {
    const [activePage, setActivePage] = useState(null);
    const [password, setPassword] = useState('');  // State for entered password
    const [isAuthenticated, setIsAuthenticated] = useState(false);  // To check if the user is authenticated
    const navigate = useNavigate();

    // const location = useLocation();  // Track the current route location

    // useEffect(() => {
    //     // Check if a route is saved in localStorage
    //     const savedRoute = localStorage.getItem('currentRoute');
    //     console.log('savedRoute', savedRoute);

    //     if (savedRoute) {
    //         // Navigate to the saved route when the app starts
    //         navigate(savedRoute);
    //     }
    // }, [navigate]);

    // useEffect(() => {
    //     // Save the current route (pathname + search) in localStorage whenever the route changes
    //     const currentPath = location.pathname + location.search;  // includes any query parameters
    //     localStorage.setItem('currentRoute', currentPath);
    //     console.log('Route saved to localStorage:', currentPath);
    // }, [location]);

    const correctPassword = 'Watch@26012024'; // Store the correct password here (for demo purposes, store it in code)

    const handlePageChange = (page) => {
        setActivePage(page);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmitPassword = () => {
        if (password === correctPassword) {
            setIsAuthenticated(true); // Allow access to admin page if password is correct
        } else {
            alert('Incorrect password!'); // Show an alert for incorrect password
        }
    };

    const renderContent = () => {
        switch (activePage) {
            case 'create-subscriptions':
                return <CreateSubscriptions userId={userId} onLogout={onLogout} />;
            case 'general-email':
                return <GeneralEmail userId={userId} onLogout={onLogout} />;
            case 'create-sections':
                return <CreateSections userId={userId} onLogout={onLogout} />;
            case 'create-specialized-fields-roles':
                return <CreateSpecializedFields userId={userId} onLogout={onLogout} />;
            case 'add-sub-sections':
                return <AddSubSections userId={userId} onLogout={onLogout} />;
            case 'user-section-verification':
                return <AdminVerification userId={userId} onLogout={onLogout} />;
            case 'update-subscription':
                return <UpdateSubscription userId={userId} onLogout={onLogout} />;
            case 'delete-subscription':
                return <DeleteSubscription userId={userId} onLogout={onLogout} />;
            case 'create-language':
                return <CreateLanguage userId={userId} onLogout={onLogout} />;
            case 'update-posts':
                return <AdminUpdatePosts userId={userId} onLogout={onLogout} />;
            case 'delete-language':
                return <DeleteLanguage userId={userId} onLogout={onLogout} />;
            case 'update-sections':
                return <UpdateSections userId={userId} onLogout={onLogout} />;
            case 'delete-section':
                return <DeleteSection userId={userId} onLogout={onLogout} />;
            default:
                return (
                    <Typography variant="h6" sx={{ mt: 4, textAlign: 'center', color: '#555' }}>
                        Select an option to get started.
                    </Typography>
                );
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (!isAuthenticated) {
        return (
            <Box sx={{ textAlign: 'center', padding: '20px' }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Admin Portal
                </Typography>
                <TextField
                    label="Enter Password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitPassword}
                    sx={{ mb: 4 }}
                >
                    Submit
                </Button>
                <Typography variant="body2" sx={{ color: '#555' }}>
                    Please enter the password to access the Admin Portal.
                </Typography>
            </Box>
        );
    }

    return (
        <div>
            <Header1 userId={userId} onLogout={onLogout} />
            <Box
                sx={{
                    bgcolor: '#1111',
                    minHeight: '100vh',
                    mt: 10,
                    py: 5,
                    px: { xs: 2, sm: 10 },
                }}
            >
                {/* Header */}
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'bold',
                        mb: 4,
                        textAlign: 'center',
                        color: 'white'
                    }}
                >
                    Admin Portal
                </Typography>

                {/* Navigation Options */}
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Create Subscriptions
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('create-subscriptions')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                General Email
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('general-email')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Create Specialized Fields and Roles
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('create-specialized-fields-roles')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Add Subsections
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('add-sub-sections')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Create Sections
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('create-sections')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                User Sections Verification
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('user-section-verification')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Update Subscription
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('update-subscription')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Delete Subscription
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('delete-subscription')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Create Languages
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('create-language')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Delete Languages
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('delete-language')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Update Sections
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('update-sections')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Delete section
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('delete-section')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Reported Posts
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/reported-posts')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>


                    {/* <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Update Subsections
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => handlePageChange('update-subsections')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid> */}

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Customer Queries
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/Customer-queries-resolve')} // Navigate to customer queries page
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Interview Panel
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/interviw-panel')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                View FAQS
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/admin-faqs')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>



                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                View Careers
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/admin-careers')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                View Contact Us
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/admin-contact-us')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                User Payments
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/user-payments')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                Create Accounts
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/admin-create-accounts')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                update Accounts
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/admin-update-accounts')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>



                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                backgroundColor: '#1111',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    color: '#1E90FF',
                                    fontWeight: 'bold',
                                }}
                            >
                                update Posts
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/admin-update-posts')}
                                sx={{
                                    bgcolor: '#1E90FF',
                                    '&:hover': {
                                        bgcolor: '#0066cc',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        </Paper>
                    </Grid>
                    
                </Grid>
                

                {/* Dynamic Content Area */}
                <Box
                    sx={{
                        mt: 6,
                        p: 4,
                        backgroundColor: '#1111',
                        borderRadius: 2,
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {renderContent()}
                </Box>

            </Box>

            <Footer2 />
        </div>
    );
}

export default AdminPortal;
