// import './App.css';
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { IdProvider } from "./Components/Home/IdContext.js";
import Home from './Components/Home/Home';
import AboutUs from './Components/Footer/AboutUs.js';
// import ContactUs from './Components/Footer/ContactUs.js';
import Careers from './Components/Footer/Careers/Careers.js';
import Internship from './Components/Footer/Careers/Internship.js';
import Jobs from './Components/Footer/Careers/Jobs.js';
import Faqs from './Components/Footer/Faqs.js';
import TermsAndConditions from './Components/Footer/TermsAndConditions.js';
import AiContent from './Components/Home/AiContent.js';
import Recommendations from './Components/Diwakar_dev/Recommendations/Recommendations.js';
import UserProfile from './Components/UserProfile/UserProfile.js';
import Documentation from './Components/Diwakar_dev/Documentation.js';
import Demo from './Components/Diwakar_dev/Demo.js';
import DataAnalytics from './Components/Diwakar_dev/DataAnalytics.js';
import Login from './Components/Login/Login.js';
import SignUp from "./Components/SignUp/SignUp";
import CreatePost from './Components/Post/CreatePost.js';
import Platform from './Components/Platform/Platform.js';
import Posted from './Components/Post/Posted.js';
import FullSection from './Components/Post/FullSection.js';
import PostTabDetails from './Components/Post/PostTabDetails.js';
import MyPosts from './Components/Post/MyPosts.js';
import CreateSubscriptions from './Components/Admin/CreateSubscriptions.js';
import CreateSections from './Components/Admin/CreateSections.js';
import AddSubSections from './Components/Admin/AddSections.js';
import Subscriptions from './Components/Platform/Subscriptions.js';
import Categories from './Components/Platform/Categories.js';
import AdminPortal from './Components/Admin/AdminPortal.js';
import SubscribeDetails from './Components/Platform/SubscribeDetails.js';
import JitsiMeet from './Components/Diwakar_dev/JitsiMeet.js';
import { GoogleLogin } from '@react-oauth/google'; // Import Google Login
import Header from './Components/Header/Header.js';
import Header1 from './Components/Header/Header1.js';
import Header2 from './Components/Header/Header2.js';
import Header3 from './Components/Header/Header3.js';
import Payments from './Components/Payments/Payments.js';
import SubCategoriesPosts from './Components/Post/SubCategoriesPosts.js';
import MyBookings from './Components/Platform/MyBookings.js';
import FeedbackForm from './Components/Feedback/FeedbackForm.js';
import GuideFeedbackForm from './Components/Feedback/GuideFeedbackForm.js';
import AISync from './Components/AI Sync/AISync.js';
import GenerateMeetingLinkButton from './Components/GoogleMeet/GenerateMeetingLinkButton.js';
import CustomerSupport from './Components/CustomerSupport/CustomerSupport.js';
import Verification from './Components/Verification/Verification.js';
import AdminVerification from './Components/Admin/AdminVerification.js';
import DatabaseSizeDisplay from './Components/DataAnalytics/DatabaseSizeDisplay.js';
import RecommendationSetup from './Components/Recommendations/RecommendationSetup.js';
import PostRecommendations from './Components/Recommendations/PostRecommendations.js';
import PasswordReset from './Components/Login/PasswordReset.js';
import AdminLogin from './Components/Admin/AdminLogin.js';
import SubscriptionsPayment from './Components/Payments/SubscriptionsPayment.js';
import CustomerQueriesResolve from './Components/Admin/CustomerQueriesResolve.js';
import InterviewPanel from './Components/Admin/InterviewPanel.js';
import Community from './Components/Community/community.js';
import { CssBaseline, Box, Container } from '@mui/material';
import AdminFaqs from "./Components/Admin/AdminFaqs.js";
import AdminCareers from "./Components/Admin/AdminCareers.js";
import AdminContactUs from "./Components/Admin/AdminContactUs.js";
import CreateSpecializedFields from "./Components/Admin/CreateSpecializedFields.js";
import UserPayments from "./Components/Admin/UserPayments.js";
import MeetingInstructions from "./Components/Platform/MeetingInstructions.js";
import AdminCreateAccounts from "./Components/Admin/AdminCreateAccounts.js";
import AdminUpdateAccounts from "./Components/Admin/AdminUpdateAccounts.js";
import RefundsAndCancellationsPolicy from "./Components/Legal/RefundsAndCancellationsPolicy.js";
import PrivacyPolicy from "./Components/Legal/PrivacyPolicy.js";
import TermsOfService from "./Components/Legal/TermsOfService.js";
import CookiesPolicy from "./Components/Legal/CookiesPolicy.js";
import AdminUpdatePosts from "./Components/Admin/AdminUpdatePosts.js";
import ShowBookedUsers from "./Components/Post/ShowBookedUsers.js";
import ContactUs from "./Components/Legal/ContactUs.js";
import Shipping from "./Components/Legal/Shipping.js";
import VideoCreationGuidelinesPage from "./Components/Verification/VideoCreationGuidelinesPage.js";
import SlotBookingPage from "./Components/Verification/SlotBookingPage.js";
import PostingGuidelines from "./Components/Guidelines/PostingGuidelines.js";
import MeetingGuidelines from "./Components/Guidelines/MeetingGuidelines.js";
import PaymentsPolicy from "./Components/Legal/PaymentsPolicy.js";
import ReportedPosts from "./Components/Admin/ReportedPosts.js";


function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);

  // const [adminUser, setAdminUser] = useState(null);

    // const handleAdminLoginSuccess = (userData) => {
    //   setAdminUser(userData);  // Store user data after login
    // };

    // const handleAdminLogout = () => {
    //   setAdminUser(null);  // Clear user data on logout
    // };

  // Handle login
  const handleLogin = (userId) => {
    setLoggedIn(true);
    setUserId(userId);
    localStorage.setItem('userId', userId); // Save user ID in localStorage
  };

  // Handle logout
  const handleLogout = () => {
    setLoggedIn(false);
    setUserId(null);
    localStorage.removeItem('userId'); // Clear userId from localStorage
    localStorage.removeItem('authToken'); // Clear token if required
  };

  // Google login success handler
  const handleGoogleLoginSuccess = (response) => {
    const token = response.credential;
    fetch('/api/auth/google/', {
      method: 'POST',
      body: JSON.stringify({ token }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.user_id) {
          handleLogin(data.user_id); // Log the user in with the ID returned from backend
        }
      })
      .catch((error) => {
        console.error('Google login failed:', error);
      });
  };

  // Google login error handler
  const handleGoogleLoginFailure = (error) => {
    console.error('Google login failed:', error);
  };

  // Check login status from localStorage on initial render
  useEffect(() => {
    const savedUserId = localStorage.getItem('userId');
    if (savedUserId) {
      setLoggedIn(true);
      setUserId(savedUserId);
    }
  }, []);

  // ProtectedRoute component to restrict access to logged-in users only
  const ProtectedRoute = ({ children }) => {
    if (!loggedIn) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  return (
    <BrowserRouter>
      <IdProvider>
        {/* Global Styles */}
        <CssBaseline /> {/* Apply baseline CSS reset from Material-UI */}

        {/* Main Container with black background */}
        <Box
          sx={{
            backgroundColor: 'black',
          }}
        >
        <Routes>
          {/* Login Route */}
          <Route
            path="/login"
            element={
              loggedIn ? (
                <Navigate to={`/platform/${userId}`} />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />

          {/* Home Route */}
          <Route path="/" element={<Home loggedIn={loggedIn} userId={userId} onLogout={handleLogout} />} />

          {/* Footer Links */}
          <Route path="/about-us" element={<AboutUs loggedIn={loggedIn} userId={userId} onLogout={handleLogout} />} />
          {/* <Route path="/contact-us" element={<ContactUs />} /> */}
          <Route path="/careers" element={<Careers />} />
          <Route path="/community" element={<Community />} />
          {/* <Route path="/careers-internship" element={<Internship />} /> */}
          {/* <Route path="/careers-jobs" element={<Jobs />} /> */}
          <Route path="/faqs" element={<Faqs loggedIn={loggedIn} userId={userId} onLogout={handleLogout}/>} />
          {/* <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> */}
          <Route path="/generate-meeting-link" element={<ProtectedRoute> <GenerateMeetingLinkButton /> </ProtectedRoute>} />

          {/* <Route path="/meeting-instrcutions" element={<ProtectedRoute> <MeetingInstructions /> </ProtectedRoute>} /> */}

          <Route path="/refunds-and-cancellations-policy" element={<RefundsAndCancellationsPolicy userId={userId} loggedIn={loggedIn}   onLogout={handleLogout} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy userId={userId} loggedIn={loggedIn}  onLogout={handleLogout}/>} />
          <Route path="/terms-of-service" element={<TermsOfService loggedIn={loggedIn} userId={userId} onLogout={handleLogout}/>} />
          <Route path="/contact-us" element={<ContactUs loggedIn={loggedIn} userId={userId} onLogout={handleLogout}/>} />
          <Route path="/shipping-policy" element={<Shipping loggedIn={loggedIn} userId={userId} onLogout={handleLogout}/>} />
          <Route path="/cookies-policy" element={<CookiesPolicy userId={userId} loggedIn={loggedIn} onLogout={handleLogout}/>} />
          <Route path="/payments-policy" element={<PaymentsPolicy userId={userId} loggedIn={loggedIn}  onLogout={handleLogout}/>} />

          <Route path="/video-creation-guidelines" element={<VideoCreationGuidelinesPage userId={userId} loggedIn={loggedIn} onLogout={handleLogout}/>} />
          <Route path="/posting-guidelines" element={<PostingGuidelines userId={userId} loggedIn={loggedIn} onLogout={handleLogout}/>} />
          <Route path="/meeting-guidelines" element={<MeetingGuidelines userId={userId} loggedIn={loggedIn} onLogout={handleLogout}/>} />

          <Route path="/slot-booking-page" element={<SlotBookingPage userId={userId} loggedIn={loggedIn} onLogout={handleLogout}/>} />


          {/* Authentication Routes */}
          <Route path="/signup" element={<SignUp />} />

          <Route path="/customer-support"   element={ <CustomerSupport loggedIn={loggedIn} userId={userId} onLogout={handleLogout} /> } />

          {/* <Route path="/verification"   element={<Verification userId={userId} onLogout={handleLogout} />} /> */}
          <Route path="/verification" element={<ProtectedRoute> <Verification userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />

          <Route path="/admin-verification"   element={<ProtectedRoute> <AdminVerification userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />
          <Route path="/interviw-panel"   element={<ProtectedRoute> <InterviewPanel userId={userId} onLogout={handleLogout} /> </ProtectedRoute> } />
          <Route path="/admin-faqs"   element={<ProtectedRoute> <AdminFaqs userId={userId} onLogout={handleLogout} /> </ProtectedRoute> } />
          <Route path="/admin-careers"   element={<ProtectedRoute> <AdminCareers userId={userId} onLogout={handleLogout} /> </ProtectedRoute> } />
          <Route path="/admin-contact-us"   element={<ProtectedRoute> <AdminContactUs userId={userId} onLogout={handleLogout} /> </ProtectedRoute> } />
          <Route path="/user-payments"   element={<ProtectedRoute> <UserPayments userId={userId} onLogout={handleLogout} /> </ProtectedRoute> } />
          <Route path="/admin-create-accounts"   element={<ProtectedRoute> <AdminCreateAccounts userId={userId} onLogout={handleLogout} /> </ProtectedRoute> } />
          <Route path="/admin-update-accounts"   element={<ProtectedRoute> <AdminUpdateAccounts userId={userId} onLogout={handleLogout} /> </ProtectedRoute> } />
          <Route path="/admin-update-posts"   element={<ProtectedRoute> <AdminUpdatePosts userId={userId} onLogout={handleLogout} /> </ProtectedRoute> } />
          <Route path="/reported-posts"   element={<ProtectedRoute> <ReportedPosts userId={userId} onLogout={handleLogout} /> </ProtectedRoute> } />

          <Route path="/Customer-queries-resolve"   element={<ProtectedRoute> <CustomerQueriesResolve userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />
          <Route path="/create-specialized-fields"   element={<ProtectedRoute> <CreateSpecializedFields userId={userId}/> </ProtectedRoute>} />
          <Route path="/database-size" element={<ProtectedRoute><DatabaseSizeDisplay /> </ProtectedRoute>} />

          <Route path="/recommendation-setup" element={<ProtectedRoute> <RecommendationSetup userId={userId}/> </ProtectedRoute>} />

          <Route path="/reset-password/:uidb64/:token" element={<PasswordReset/>} />


          <Route path="/post-recommendations" element={<ProtectedRoute> <PostRecommendations /> </ProtectedRoute>} />

          {/* <Route
            path="/admin-login"
            element={<AdminLogin onLoginSuccess={handleAdminLoginSuccess} />}
          /> */}
          <Route
            path="/post/:id"
            element={
              <ProtectedRoute>
                <CreatePost userId={userId} onLogout={handleLogout} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/platform/:userId"
            element={
              <ProtectedRoute>
                <Platform userId={userId} onLogout={handleLogout} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sub-categories-posts/:id"
            element={
              <ProtectedRoute>
                <SubCategoriesPosts userId={userId} onLogout={handleLogout} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/full-section/:id"
            element={
              <ProtectedRoute>
                <FullSection userId={userId} onLogout={handleLogout} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/post-tab-details/:id/:userId"
            element={
              <ProtectedRoute>
                <PostTabDetails userId={userId} onLogout={handleLogout} />
              </ProtectedRoute>
            }
          />

          {/* UserProfile Route */}
          <Route
            path="/UserProfile/:userId"
            element={
              <ProtectedRoute>
                <UserProfile userId={userId} onLogout={handleLogout} />
              </ProtectedRoute>
            }
          />

<Route
            path="/MyBookings"
            element={
              <ProtectedRoute>
                <MyBookings userId={userId} onLogout={handleLogout} />
              </ProtectedRoute>
            }
          />

        

          
          <Route path='/payments/:type/:id' element={<ProtectedRoute><Payments userId={userId} onLogout={handleLogout}/> </ProtectedRoute> } />

          <Route path='/subscription-payments/:id' element={<ProtectedRoute> <SubscriptionsPayment userId={userId} onLogout={handleLogout}/>  </ProtectedRoute> } />

          {/* Subscriptions and Admin Routes */}
          <Route path='/subscribe/:id' element={<ProtectedRoute><SubscribeDetails /> </ProtectedRoute> } />
          {/* <Route path="/subscriptions" element={<Subscriptions userId={userId} onLogout={handleLogout} />} /> */}
          <Route path="/subscriptions" element={<ProtectedRoute> <Subscriptions userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />

          <Route path="/create-subscriptions" element={<ProtectedRoute> <CreateSubscriptions userId={userId} onLogout={handleLogout} />  </ProtectedRoute>} />
          <Route path="/create-sections" element={<ProtectedRoute> <CreateSections userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />
          <Route path="/add-subsections" element={ <ProtectedRoute> <AddSubSections userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />

          {/* Jitsi Meet Room Route */}
          {/* <Route path="/meet/:roomName" element={<JitsiMeet roomName={window.location.pathname.split('/').pop()}  userId={userId} onLogout={handleLogout} />} /> */}

          <Route path="/feedbackform/:id" element={<ProtectedRoute> <FeedbackForm userId={userId} onLogout={handleLogout}/> </ProtectedRoute>} />
          <Route path="/guide-feedbackform/:id" element={<ProtectedRoute> <GuideFeedbackForm userId={userId} onLogout={handleLogout}/> </ProtectedRoute>} />

          {/* Other Pages */}
          <Route path="/ai-content" element={<ProtectedRoute> <AiContent /> </ProtectedRoute>} />
          <Route path="/category" element={<ProtectedRoute> <Categories userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />
          <Route path="/Documentation" element={<ProtectedRoute> <Documentation userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />
          <Route path="/Demo" element={<ProtectedRoute> <Demo /> </ProtectedRoute>} />
          <Route path="/DataAnalytics" element={<ProtectedRoute> <DataAnalytics /> </ProtectedRoute>} />
          
          <Route path="/AISync" element={<ProtectedRoute> <AISync onLogout={handleLogout} /> </ProtectedRoute>} />

          <Route path="/admin-panel" element={<ProtectedRoute>  <AdminPortal userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />

          <Route path="/show-booked-users/:id" element={<ProtectedRoute>  <ShowBookedUsers userId={userId} onLogout={handleLogout} /> </ProtectedRoute>} />


          

          {/* Default Route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        </Box>
      </IdProvider>
    </BrowserRouter>
  );
}

export default App;
