import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Card, CardContent, Button, CardActions, IconButton } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Header1 from '../Header/Header1';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const API_URL = process.env.REACT_APP_API_URL;



function Subscriptions({ onLogout }) {
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [userSubscriptions, setUserSubscriptions] = useState(new Set());
    const [remaining_posts,setRemainingPosts] = useState([])
    const location = useLocation();
    const navigate = useNavigate();

    // Extract query parameter (userId)
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');

    // You can now use the userId in your component

    // useEffect(() => {
    //     // Check if a route is saved in localStorage
    //     const savedRoute = localStorage.getItem('currentRoute');
    //     console.log('savedRoute', savedRoute);

    //     if (savedRoute) {
    //         // Navigate to the saved route when the app starts
    //         navigate(savedRoute);
    //     }
    // }, [navigate]);

    // useEffect(() => {
    //     // Save the current route (pathname + search) in localStorage whenever the route changes
    //     const currentPath = location.pathname + location.search;  // includes any query parameters
    //     localStorage.setItem('currentRoute', currentPath);
    //     console.log('Route saved to localStorage:', currentPath);
    // }, [location]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${API_URL}/list_subscriptions_data/`);
                setSubscriptionData(response.data);
            } catch (error) {
                console.error('Error fetching subscription data:', error);
            }
        };

        const fetchUserSubscriptions = async () => {

            try {
                const response = await axios.get(`${API_URL}/list_user_subscription_mappings/?user_id=${userId}`);
                const subscriptions = new Set(response.data.map(sub => sub.subscription_id));
                setUserSubscriptions(subscriptions);
                setRemainingPosts(response.data[0].remaining_posts)
            } catch (error) {
                console.error('Error fetching user subscription mappings:', error);
            }
        };

        fetchUserData();
        fetchUserSubscriptions();
    }, [userId]);

    const handleSubscriptionSuccess = (subscriptionId) => {
        // After successful subscription, update the userSubscriptions to reflect the new subscription
        setUserSubscriptions((prevSubscriptions) => new Set(prevSubscriptions.add(subscriptionId)));
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <div>
            <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', background: 'black', color: '#f5f5f5' }}>
                {/* Header */}
                <Header1 userId={userId} onLogout={onLogout} />

                {/* Title */}
                <Box sx={{ textAlign: 'center', py: 4, mt: 10, mb: 10 }}>
                    <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'white' }}>
                        Subscription Plans
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'white', mt: 1 }}>
                        Unlock premium features and resources tailored for your needs
                    </Typography>
                </Box>

                {/* Subscription Plans */}
                <Box sx={{ flexGrow: 1, px: 6, py: 3, mb: 24 }}>
                    <Grid container spacing={4} justifyContent="center">
                        {subscriptionData.map((plan) => (
                            <Grid item xs={12} sm={6} md={4} key={plan.id}>
                                <Card sx={{
                                    background: '#1111', color: '#f5f5f5', borderRadius: '20px', boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
                                    borderColor: '#1E90FF',
                                    border: "3px solid #1E90FF",
                                    "&:hover": {
                                        transform: "translateY(-3py)",
                                        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
                                    },
                                }}>
                                    <CardContent sx={{ textAlign: 'center' }}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                                            {plan.plan_number}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ mb: 2, color: '#64b5f6' }}>
                                            {plan.plan_name} - {plan.plan_type}
                                        </Typography>
                                        <Box sx={{ mt: 2 }}>
                                            {/* <Typography>
                                            <ListAltIcon sx={{ verticalAlign: 'middle', color: '#81c784' }} /> <strong>Sections:</strong> {plan.sections_included}
                                        </Typography> */}
                                            <Typography>
                                                <PostAddIcon sx={{ verticalAlign: 'middle', color: '#ffb74d' }} /> <strong>Posts:</strong> {plan.post_count}
                                            </Typography>
                                            {userSubscriptions.has(plan.id) && (
                                                <Typography>
                                                    <PostAddIcon sx={{ verticalAlign: 'middle', color: '#ffb74d' }} /> <strong>Remaining Posts:</strong> {remaining_posts}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Typography variant="h4" sx={{ mt: 3, fontWeight: 'bold', color: '#66bb6a' }}>
                                            ₹{plan.price}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: 'center' }}>
                                        {userSubscriptions.has(plan.id) ? (
                                            <span className="active-label">Active</span>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component={Link}
                                                to={{
                                                    pathname: `/subscription-payments/${plan.id}`,  // Use backticks for string interpolation
                                                    search: `?userId=${userId}`,  // Use backticks for query string
                                                    // state: { plan },  // Pass plan as part of state
                                                }}
                                                sx={{
                                                    px: 4,
                                                    py: 1,
                                                    fontWeight: 'bold',
                                                    borderRadius: 30,
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                Subscribe Now
                                            </Button>

                                        )}
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

            

        </Box>
        <IconButton
                onClick={scrollToTop}
                sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}>
                <ArrowUpwardIcon />
            </IconButton>
        <Footer2/>
        </div>
    );
}

export default Subscriptions;