import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Link, Divider,List, ListItem, ListItemText, IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


const TermsOfService = ({userId,loggedIn, onLogout}) => {

    const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    const handleLogout = () => {
        onLogout(); // Call the passed logout function
        navigate('/login'); // Navigate to login page after logout
      };
  return (
    <div>
        {loggedIn ? (
        <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
      ) : (
        <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
      )}
    <Container maxWidth="md" sx={{ paddingTop: 4, mt:10 }}>
      {/* Title Section */}
      <Box textAlign="center">
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontSize: '26px', fontWeight: 'bold', color: '#ffffff' }}>
          TERMS OF SERVICE
        </Typography>
        <Typography variant="subtitle1" component="h2" sx={{ fontSize: '14px', color: '#595959' }}>
          <strong>Last updated</strong> January 26, 2025
        </Typography>
      </Box>

      {/* Agreement to Legal Terms */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 'bold', color: '#000000' }}>
          AGREEMENT TO OUR LEGAL TERMS
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          We are <strong>ANTHRASYNC SOLUTIONS PRIVATE LIMITED</strong> ('Company', 'we', 'us', or 'our'), a company registered in India at <strong>No 26, Laskar Hosur Road, Adugodi, Bangalore South</strong>, Karnataka 560030, India.
        </Typography>
      </Box>

      {/* Website and Services Description */}
      <Box sx={{ marginTop: 2 }}>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          We operate the website{' '}
          <Link href="https://www.anthrasync.com/" target="_blank" sx={{ color: '#3030F1' }}>
            https://www.anthrasync.com/
          </Link>{' '}
          (the 'Site'), as well as any other related products and services that refer or link to these legal terms (the 'Legal Terms') (collectively, the 'Services').
        </Typography>
      </Box>

      {/* Platform Information */}
      <Box sx={{ marginTop: 2 }}>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          Anthrasync is a knowledge exchange platform for everyone, irrespective of their languages and locations.
        </Typography>
      </Box>

      {/* Contact Information */}
      <Box sx={{ marginTop: 2 }}>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          You can contact us by email at{' '}
          <Link href="mailto:official@anthrasync.com" sx={{ color: '#3030F1' }}>
            official@anthrasync.com
          </Link>{' '}
          or by mail to{' '}
          <strong>No 26, Laskar Hosur Road, Adugodi, Bangalore South, Bangalore, Karnataka 560030, India</strong>.
        </Typography>
      </Box>

      {/* Legal Terms Acceptance */}
      <Box sx={{ marginTop: 3 }}>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          These Legal Terms constitute a legally binding agreement made between you ('you') and{' '}
          <strong>ANTHRASYNC SOLUTIONS PRIVATE LIMITED</strong>, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </Typography>
      </Box>

      {/* Changes to Services */}
      <Box sx={{ marginTop: 3 }}>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          We will provide you with prior notice of any scheduled changes to the Services you are using. Changes to Legal Terms will become effective thirty (30) days after the notice is given, except if the changes apply to new functionality, security updates, bug fixes, or a court order, in which case the changes will be effective immediately. By continuing to use the Services after the effective date of any changes, you agree to be bound by the modified terms. If you disagree with such changes, you may terminate Services as per the section{' '}
          <Link href="#term_and_termination" sx={{ color: '#3030F1' }}>
            TERM AND TERMINATION
          </Link>.
        </Typography>
      </Box>

      {/* Age Requirements */}
      <Box sx={{ marginTop: 3 }}>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          The Services are intended for users who are at least 13 years of age. All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor, you must have your parent or guardian read and agree to these Legal Terms prior to you using the Services.
        </Typography>
      </Box>

      {/* Recommendation to Save Terms */}
      <Box sx={{ marginTop: 3 }}>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          We recommend that you print a copy of these Legal Terms for your records.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Table of Contents Section */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h5" component="h2" sx={{ fontSize: '19px', fontWeight: 'bold', color: '#fff' }}>
          TABLE OF CONTENTS
        </Typography>
        <List sx={{ paddingLeft: 2, fontSize: '14px' }}>
  <ListItem>
    <Link href="#our_services" sx={{ color: '#3030F1' }}>
      <ListItemText primary="1. OUR SERVICES" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#intellectual_property_rights" sx={{ color: '#3030F1' }}>
      <ListItemText primary="2. INTELLECTUAL PROPERTY RIGHTS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#user_representations" sx={{ color: '#3030F1' }}>
      <ListItemText primary="3. USER REPRESENTATIONS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#user_registration" sx={{ color: '#3030F1' }}>
      <ListItemText primary="4. USER REGISTRATION" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#purchases_and_payment" sx={{ color: '#3030F1' }}>
      <ListItemText primary="5. PURCHASES AND PAYMENT" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#subscriptions" sx={{ color: '#3030F1' }}>
      <ListItemText primary="6. SUBSCRIPTIONS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#prohibited_activities" sx={{ color: '#3030F1' }}>
      <ListItemText primary="7. PROHIBITED ACTIVITIES" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#user_generated_contributions" sx={{ color: '#3030F1' }}>
      <ListItemText primary="8. USER GENERATED CONTRIBUTIONS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#contribution_licence" sx={{ color: '#3030F1' }}>
      <ListItemText primary="9. CONTRIBUTION LICENCE" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#guidelines_for_reviews" sx={{ color: '#3030F1' }}>
      <ListItemText primary="10. GUIDELINES FOR REVIEWS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#social_media" sx={{ color: '#3030F1' }}>
      <ListItemText primary="11. SOCIAL MEDIA" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#third_party_websites_and_content" sx={{ color: '#3030F1' }}>
      <ListItemText primary="12. THIRD-PARTY WEBSITES AND CONTENT" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#advertisers" sx={{ color: '#3030F1' }}>
      <ListItemText primary="13. ADVERTISERS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#services_management" sx={{ color: '#3030F1' }}>
      <ListItemText primary="14. SERVICES MANAGEMENT" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#privacy_policy" sx={{ color: '#3030F1' }}>
      <ListItemText primary="15. PRIVACY POLICY" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#copyright_infringements" sx={{ color: '#3030F1' }}>
      <ListItemText primary="16. COPYRIGHT INFRINGEMENTS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#term_and_termination" sx={{ color: '#3030F1' }}>
      <ListItemText primary="17. TERM AND TERMINATION" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#modifications_and_interruptions" sx={{ color: '#3030F1' }}>
      <ListItemText primary="18. MODIFICATIONS AND INTERRUPTIONS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#governing_law" sx={{ color: '#3030F1' }}>
      <ListItemText primary="19. GOVERNING LAW" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#dispute_resolution" sx={{ color: '#3030F1' }}>
      <ListItemText primary="20. DISPUTE RESOLUTION" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#corrections" sx={{ color: '#3030F1' }}>
      <ListItemText primary="21. CORRECTIONS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#disclaimer" sx={{ color: '#3030F1' }}>
      <ListItemText primary="22. DISCLAIMER" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#limitations_of_liability" sx={{ color: '#3030F1' }}>
      <ListItemText primary="23. LIMITATIONS OF LIABILITY" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#indemnification" sx={{ color: '#3030F1' }}>
      <ListItemText primary="24. INDEMNIFICATION" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#user_data" sx={{ color: '#3030F1' }}>
      <ListItemText primary="25. USER DATA" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#electronic_communications" sx={{ color: '#3030F1' }}>
      <ListItemText primary="26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#sms_text_messaging" sx={{ color: '#3030F1' }}>
      <ListItemText primary="27. SMS TEXT MESSAGING" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#miscellaneous" sx={{ color: '#3030F1' }}>
      <ListItemText primary="28. MISCELLANEOUS" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#dispute_resolution" sx={{ color: '#3030F1' }}>
      <ListItemText primary="29. DISPUTE RESOLUTION" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#service_interruptions" sx={{ color: '#3030F1' }}>
      <ListItemText primary="30. SERVICE INTERRUPTIONS OR GLITCHES" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#changes_to_terms_of_service" sx={{ color: '#3030F1' }}>
      <ListItemText primary="31. CHANGES TO TERMS OF SERVICE" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#misconduct_or_misuse" sx={{ color: '#3030F1' }}>
      <ListItemText primary="32. MISCONDUCT OR MISUSE OF PLATFORM" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#prohibition_of_fake_content" sx={{ color: '#3030F1' }}>
      <ListItemText primary="33. PROHIBITION OF FAKE OR HARMFUL CONTENT" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#no_defamation" sx={{ color: '#3030F1' }}>
      <ListItemText primary="34. NO DEFAMATION OR NEGATIVE CAMPAIGNING" />
    </Link>
  </ListItem>
  <ListItem>
    <Link href="#contact_us" sx={{ color: '#3030F1' }}>
      <ListItemText primary="35. CONTACT US" />
    </Link>
  </ListItem>
</List>

      </Box>

      {/* OUR SERVICES Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography id="our_services"  variant="h5" sx={{ fontSize: '19px', fontWeight: 'bold', color: '#fff' }}>
          1. OUR SERVICES
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* INTELLECTUAL PROPERTY RIGHTS Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography id="intellectual_property_rights" variant="h5" sx={{ fontSize: '19px', fontWeight: 'bold', color: '#FFFFFF' }}>
          2. INTELLECTUAL PROPERTY RIGHTS
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          <strong>Our intellectual property</strong><br />
          We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the 'Content'), as well as the trademarks, service marks, and logos contained therein (the 'Marks').
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties around the world.
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          The Content and Marks are provided in or through the Services 'AS IS' for your personal, non-commercial use or internal business purpose only.
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          <strong>Your use of our Services</strong><br />
          Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive, non-transferable, revocable licence to:
          <ul>
            <li>access the Services;</li>
            <li>download or print a copy of any portion of the Content to which you have properly gained access, solely for your personal, non-commercial use or internal business purpose.</li>
          </ul>
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: official@anthrasync.com. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

       {/* Your Submissions and Contributions Section */}
       <Box sx={{ marginTop: 4 }}>
        <Typography
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          Your Submissions and Contributions
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          Please review this section and the 'PROHIBITED ACTIVITIES' section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          <strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ('Submissions'), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          <strong>Contributions:</strong> The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality during which you may create, submit, post, display, transmit, publish, distribute, or broadcast content and materials to us or through the Services, including but not limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information, or other material ('Contributions'). Any Submission that is publicly posted shall also be treated as a Contribution.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          You understand that Contributions may be viewable by other users of the Services and possibly through third-party websites.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          <strong>When you post Contributions, you grant us a licence (including use of your name, trademarks, and logos):</strong> By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and licence to: use, copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat, translate, excerpt (in whole or in part), and exploit your Contributions (including, without limitation, your image, name, and voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other works, your Contributions, and to sublicence the licences granted in this section. Our use and distribution may occur in any media formats and through any media channels.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          This licence includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          <strong>You are responsible for what you post or upload:</strong> By sending us Submissions and/or posting Contributions through any part of the Services or making Contributions accessible through the Services by linking your account through the Services to any of your social networking accounts, you:
          <ul>
            <li>confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post, send, publish, upload, or transmit through the Services any Submission nor post any Contribution that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;</li>
            <li>to the extent permissible by applicable law, waive any and all moral rights to any such Submission and/or Contribution;</li>
            <li>warrant that any such Submission and/or Contributions are original to you or that you have the necessary rights and licences to submit such Submissions and/or Contributions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions and/or Contributions;</li>
            <li>warrant and represent that your Submissions and/or Contributions do not constitute confidential information.</li>
          </ul>
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          You are solely responsible for your Submissions and/or Contributions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual property rights, or (c) applicable law.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5 }}>
          We may remove or edit your Content: Although we have no obligation to monitor any Contributions, we shall have the right to remove or edit any Contributions at any time without notice if in our reasonable opinion we consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any such Contributions, we may also suspend or disable your account and report you to the authorities.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          <strong>Copyright infringement:</strong> We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately refer to the 'COPYRIGHT INFRINGEMENTS' section below.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* User Representations Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="user_representations"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          3. USER REPRESENTATIONS
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          By using the Services, you represent and warrant that:
        </Typography>
        <List sx={{ paddingLeft: 2, fontSize: '14px', color: '#595959' }}>
          <ListItem>
            <ListItemText primary="(1) All registration information you submit will be true, accurate, current, and complete;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="(2) You will maintain the accuracy of such information and promptly update such registration information as necessary;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="(3) You have the legal capacity and you agree to comply with these Legal Terms;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="(4) You are not under the age of 13;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="(5) You are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Services;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="(6) You will not access the Services through automated or non-human means, whether through a bot, script or otherwise;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="(7) You will not use the Services for any illegal or unauthorised purpose;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="(8) Your use of the Services will not violate any applicable law or regulation." />
          </ListItem>
        </List>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginTop: 2 }}>
          If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* User Registration Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="user_registration"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          4. USER REGISTRATION
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password.
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Purchases and Payment Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="purchases_and_payment"
          variant="h5"
          sx={{
            
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          5. PURCHASES AND PAYMENT
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          We accept the following forms of payment:
        </Typography>
        <List sx={{ paddingLeft: 2, fontSize: '14px', color: '#595959' }}>
          <ListItem>
            <ListItemText primary="- Visa" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Mastercard" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Razorpay" />
          </ListItem>
        </List>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginTop: 2 }}>
          You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginTop: 2 }}>
          Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments shall be in Indian Rupees.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginTop: 2 }}>
          You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorise us to charge your chosen payment provider for any such amounts upon placing your order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.
        </Typography>

        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginTop: 2 }}>
          We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgement, appear to be placed by dealers, resellers, or distributors.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Subscriptions Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="subscriptions"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          6. SUBSCRIPTIONS
        </Typography>

        {/* Billing and Renewal */}
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          Subscriptions will not be renewed automatically. Users have to subscribe again once the subscription period ends.
        </Typography>

        {/* Free Trial */}
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          We offer a 60-day free trial to new users who register with the Services. The account will not be charged and the subscription will be suspended until upgraded to a paid version at the end of the free trial.
        </Typography>

        {/* Cancellation */}
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          You can cancel your subscription at any time by logging into your account. Your cancellation will take effect at the end of the current paid term. If you have any questions or are unsatisfied with our Services, please email us at{' '}
          <a href="mailto:official@anthrasync.com" style={{ color: '#1E88E5' }}>
            official@anthrasync.com
          </a>.
        </Typography>

        {/* Fee Changes */}
        <Typography sx={{ fontSize: '14px', color: '#595959', lineHeight: 1.5, marginBottom: 2 }}>
          We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in accordance with applicable law.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />


        {/* Prohibited Activities Section */}
        <Box sx={{ marginTop: 4 }}>
        <Typography
        id="prohibited_activities"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          7. PROHIBITED ACTIVITIES
        </Typography>
        <Typography
          variant="h9"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
        As a user of the Services, you agree not to:
        </Typography>

        {/* Prohibited Activities List */}
        <List sx={{ color: '#595959', fontSize: '14px' }}>
          <ListItem>
            <ListItemText
              primary="You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavours except those that are specifically endorsed or approved by us."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Use any information obtained from the Services in order to harass, abuse, or harm another person."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Make improper use of our support services or submit false reports of abuse or misconduct."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Use the Services in a manner inconsistent with any applicable laws or regulations."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Engage in unauthorised framing of or linking to the Services."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Delete the copyright or other proprietary rights notice from any Content."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Attempt to impersonate another user or person or use the username of another user."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ('gifs'), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'pcms')."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any unauthorised script or other software."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Use a buying agent or purchasing agent to make purchases on the Services."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretences."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavour or commercial enterprise."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Use the Services to advertise or offer to sell goods and services."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Sell or otherwise transfer your profile."
            />
          </ListItem>
        </List>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

       {/* User Generated Contributions Section */}
       <Box sx={{ marginTop: 4 }}>
        <Typography
        id="user_generated_contributions"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          8. USER GENERATED CONTRIBUTIONS
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, 'Contributions'). Contributions may be viewable by other users of the Services and through third-party websites. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          When you create or make available any Contributions, you thereby represent and warrant that:
        </Typography>

        {/* List of user contribution representations */}
        <List sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <ListItem>
            <ListItemText
              primary="The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="You are the creator and owner of or have the necessary licences, rights, consents, releases, and permissions to use and to authorise us, the Services, and other users of the Services to use your Contributions in any manner contemplated by the Services and these Legal Terms."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions are not false, inaccurate, or misleading."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions are not unsolicited or unauthorised advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or otherwise objectionable (as determined by us)."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions do not violate any applicable law, regulation, or rule."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions do not violate the privacy or publicity rights of any third party."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation."
            />
          </ListItem>
        </List>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination or suspension of your rights to use the Services.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

       {/* Contribution License Section */}
       <Box sx={{ marginTop: 4 }}>
        <Typography
        id="contribution_licence"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          9. CONTRIBUTION LICENCE
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          By posting your Contributions to any part of the Services or making Contributions accessible to the Services by linking your account from the Services to any of your social networking accounts, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and licence to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Contributions, and grant and authorise sublicences of the foregoing. The use and distribution may occur in any media formats and through any media channels.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          This licence will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorise any Contributions to place them in more appropriate locations on the Services; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Guidelines for Reviews Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="guidelines_for_reviews"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          10. GUIDELINES FOR REVIEWS
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply with the following criteria:
        </Typography>

        <List sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <ListItem>
            <ListItemText
              primary="You should have firsthand experience with the person/entity being reviewed."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your reviews should not contain offensive profanity, or abusive, racist, offensive, or hateful language."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Your reviews should not contain references to illegal activity."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="You should not be affiliated with competitors if posting negative reviews."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="You should not make any conclusions as to the legality of conduct."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="You may not post any false or misleading statements."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="You may not organise a campaign encouraging others to post reviews, whether positive or negative."
            />
          </ListItem>
        </List>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable right and licence to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to review.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Social Media Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="social_media"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          11. SOCIAL MEDIA
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          As part of the functionality of the Services, you may link your account with online accounts you have with third-party service providers (each such account, a 'Third-Party Account') by either:
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          (1) providing your Third-Party Account login information through the Services; or
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          (2) allowing us to access your Third-Party Account, as is permitted under the applicable terms and conditions that govern your use of each Third-Party Account.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          You represent and warrant that you are entitled to disclose your Third-Party Account login information to us and/or grant us access to your Third-Party Account, without breach by you of any of the terms and conditions that govern your use of the applicable Third-Party Account, and without obligating us to pay any fees or making us subject to any usage limitations imposed by the third-party service provider of the Third-Party Account.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          By granting us access to any Third-Party Accounts, you understand that:
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          (1) we may access, make available, and store (if applicable) any content that you have provided to and stored in your Third-Party Account (the 'Social Network Content') so that it is available on and through the Services via your account, including without limitation any friend lists and
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          (2) we may submit to and receive from your Third-Party Account additional information to the extent you are notified when you link your account with the Third-Party Account.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          Depending on the Third-Party Accounts you choose and subject to the privacy settings that you have set in such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts may be available on and through your account on the Services. Please note that if a Third-Party Account or associated service becomes unavailable or our access to such Third-Party Account is terminated by the third-party service provider, then Social Network Content may no longer be available on and through the Services.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          You will have the ability to disable the connection between your account on the Services and your Third-Party Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We make no effort to review any Social Network Content for any purpose, including but not limited to, for accuracy, legality, or non-infringement, and we are not responsible for any Social Network Content. You acknowledge and agree that we may access your email address book associated with a Third-Party Account and your contacts list stored on your mobile device or tablet computer solely for purposes of identifying and informing you of those contacts who have also registered to use the Services.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          You can deactivate the connection between the Services and your Third-Party Account by contacting us using the contact information below or through your account settings (if applicable). We will attempt to delete any information stored on our servers that was obtained through such Third-Party Account, except the username and profile picture that become associated with your account.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

     {/* Third-Party Websites and Content Section */}
     <Box sx={{ marginTop: 4 }}>
        <Typography
        id="third_party_websites_and_content"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          12. THIRD-PARTY WEBSITES AND CONTENT
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          The Services may contain (or you may be sent via the Site) links to other websites ('Third-Party Websites') as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ('Third-Party Content').
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Services or any Third-Party Content posted on, available through, or installed from the Services, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Legal Terms no longer govern.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Services or relating to any applications you use or install from the Services. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm caused by your purchase of such products or services. Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} /> 

      {/* Advertisers Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="advertisers"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          13. ADVERTISERS
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We allow advertisers to display their advertisements and other information in certain areas of the Services, such as sidebar advertisements or banner advertisements. We simply provide the space to place such advertisements, and we have no other relationship with advertisers.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Services Management Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id ="services_management"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          14. SERVICES MANAGEMENT
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We reserve the right, but not the obligation, to:
        </Typography>
        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1, marginLeft: 2 }}>
          (1) Monitor the Services for violations of these Legal Terms;
        </Typography>
        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1, marginLeft: 2 }}>
          (2) Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities;
        </Typography>
        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1, marginLeft: 2 }}>
          (3) In our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;
        </Typography>
        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1, marginLeft: 2 }}>
          (4) In our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;
        </Typography>
        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1, marginLeft: 2 }}>
          (5) Otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Privacy Policy Section */}
      <Box sx={{ marginTop: 4 }}>
      {/* <Link to="/privacy-policy" > */}
        <Typography
        id="privacy_policy"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          15. PRIVACY POLICY
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We care about data privacy and security. Please review our Privacy Policy:  
          <Link href="/privacy-policy" >
            Privacy policy</Link>
          
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          Please be advised the Services are hosted in India. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in India, then through your continued use of the Services, you are transferring your data to India, and you expressly consent to have your data transferred to and processed in India.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Copyright Infringements Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="copyright_infringements"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          16. COPYRIGHT INFRINGEMENTS
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a 'Notification'). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to applicable law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Services infringes your copyright, you should consider first contacting an attorney.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Term and Termination Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography 
        id="term_and_termination"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          17. TERM AND TERMINATION
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Modifications and Interruptions Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="modifications_and_interruptions"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          18. MODIFICATIONS AND INTERRUPTIONS
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 1 }}>
          You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Governing Law Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="governing_law"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          19. GOVERNING LAW
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          These Legal Terms shall be governed by and defined following the laws of India. ANTHRASYNC SOLUTIONS PRIVATE LIMITED and yourself irrevocably consent that the courts of India shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these Legal Terms.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Dispute Resolution Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="dispute_resolution"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          20. DISPUTE RESOLUTION
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <strong>Informal Negotiations:</strong> To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a 'Dispute' and collectively, the 'Disputes') brought by either you or us (individually, a 'Party' and collectively, the 'Parties'), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <strong>Binding Arbitration:</strong> Any dispute arising out of or in connection with these Legal Terms, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by the International Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring to it, is considered as the part of this clause. The number of arbitrators shall be three (3). The seat, or legal place, or arbitration shall be Bengaluru, India. The language of the proceedings shall be English. The governing law of these Legal Terms shall be substantive law of India.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <strong>Restrictions:</strong> The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilise class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <strong>Exceptions to Informal Negotiations and Arbitration:</strong> The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorised use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Corrections Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="corrections"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          21. CORRECTIONS
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Disclaimer Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="disclaimer"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          22. DISCLAIMER
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Limitations of Liability Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="limitations_of_liability"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          23. LIMITATIONS OF LIABILITY
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />
      {/* Indemnification Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="indemnification"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          24. INDEMNIFICATION
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach of your representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Services with whom you connected via the Services.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* User Data Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="user_data"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          25. USER DATA
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Electronic Communications, Transactions, and Signatures Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="electronic_communications"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

       {/* SMS Text Messaging Section */}
       <Box sx={{ marginTop: 4 }}>
        <Typography
        id="sms_text_messaging"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          27. SMS TEXT MESSAGING
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <strong>Opting Out:</strong> If at any time you wish to stop receiving SMS messages from us, simply reply to the text with "STOP.” You may receive an SMS message confirming your opt-out.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <strong>Message and Data Rates:</strong> Please be aware that message and data rates may apply to any SMS messages sent or received. The rates are determined by your carrier and the specifics of your mobile plan.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <strong>Support:</strong> If you have any questions or need assistance regarding our SMS communications, please email us at official@anthrasync.com.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Miscellaneous Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="miscellaneous"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          28. MISCELLANEOUS
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          There is no joint venture, partnership, employment, or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them.
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          You hereby waive any and all defences you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Dispute Resolution Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="dispute_resolution"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          29. DISPUTE RESOLUTION
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          In the event of any dispute between the user and the company, the final decision will be made by the company. Users agree to abide by the company’s decision as final and binding.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Service Interruptions or Glitches Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="service_interruptions"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          30. SERVICE INTERRUPTIONS OR GLITCHES
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          The company does not guarantee uninterrupted or error-free service. In case of any interruption, downtime, or software glitches, users agree not to hold the company liable for any damages or disruptions caused. The company will make reasonable efforts to resolve any issues promptly.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Changes to Terms of Service Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="changes_to_terms_of_service"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          31. CHANGES TO TERMS OF SERVICE
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          The company reserves the right to modify, update, or change these Terms of Service at any time. Users will be notified of any significant changes, and continued use of the platform after such changes indicates acceptance of the new terms.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Misconduct or Misuse of Platform Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="misconduct_or_misuse"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          32. MISCONDUCT OR MISUSE OF PLATFORM
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          The company has the right to take appropriate action, including suspension or termination of access, against any user who engages in misconduct, violates these terms, or misuses the platform and services. Such actions may include, but are not limited to, illegal activities, abuse of platform features, or harmful behavior.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Prohibition of Fake or Harmful Content Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="prohibition_of_fake_content"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          33. PROHIBITION OF FAKE OR HARMFUL CONTENT
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          Users agree not to provide false or misleading information, post fake content, or share harmful content such as explicit, offensive, or sexually inappropriate material. Users are also prohibited from using the platform for personal purposes such as dating or engaging in any activity not intended for the platform’s designated use. Any such misuse may result in suspension or termination of the user's account.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* No Defamation or Negative Campaigning Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="no_defamation"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          34. NO DEFAMATION OR NEGATIVE CAMPAIGNING
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          Users agree not to blame, defame, or spread false or misleading information about the company, its services, or its employees. Users will refrain from engaging in any activities, whether online or offline, that are intended to harm the company’s reputation or operations. Any such actions may result in legal consequences and the suspension or termination of the user’s account.
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Contact Us Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography
        id="contact_us"
          variant="h5"
          sx={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#FFFFFF', // White text color for black background
          }}
        >
          35. CONTACT US
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <strong>ANTHRASYNC SOLUTIONS PRIVATE LIMITED</strong>
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          No 26, Laskar Hosur Road, Adugodi, Bangalore South
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          Bangalore, Karnataka 560030
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          India
        </Typography>

        <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
          <strong>Email: </strong> official@anthrasync.com
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />
    
    </Container>
    <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
   <Footer2/></div>
  );
};

export default TermsOfService;
