import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Card, CardContent, Button } from "@mui/material";
import { useNavigate, Link} from "react-router-dom";
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';  // Import date-fns for date formatting
import { enGB } from 'date-fns/locale';  // Locale for English (Great Britain) formatting


const API_URL = process.env.REACT_APP_API_URL;

function RecentActivity({ userId, searchResults }) {
  const navigate = useNavigate();
  const [recentTabs, setRecentTabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchRecentActivity = async () => {
      try {
        const response = await axios.get(`${API_URL}/list_recent_activity/`, {
          params: { user_id: userId },
        });
        setRecentTabs(response.data);
      } catch (err) {
        setError("Failed to load recent activities");
      } finally {
        setLoading(false);
      }
    };

    fetchRecentActivity();
  }, [userId]);

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setRecentTabs(searchResults);
    }
  }, [searchResults]);

  if (loading) {
    return <Typography variant="h6" align="center" sx={{ color: "#f5f5f5" }}>Loading recent activities...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" align="center" sx={{ color: "#f44336" }}>{error}</Typography>;
  }

  return (
    <Box
  sx={{
    padding: "2rem",
    backgroundColor: "#000000",
    minHeight: "100vh",
    // Adding responsive left spacing (margin-left) for small screens
    "@media (max-width: 600px)": {
      marginLeft: "2rem", // This will apply left margin of 2rem for small screens (xs, sm)
    },
  }}
>
      <Grid container spacing={5}>
        {recentTabs.length > 0 ? (
          recentTabs.map((tab) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={tab.id}>

              <Link to={`/post-tab-details/${tab.post_id}/${userId}`} style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    backgroundColor: "#000000",
                    color: "white",
                    borderRadius: "16px",
                    overflow: "hidden",
                    boxShadow: "0 4px 10px rgba(0,0,0,0.3)",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    border: '1px solid #1E90FF',
                    maxHeight: "450px",
                    maxWidth: "350px",
                    minHeight: "290px",
                    minWidth: "220px",
                    height: "auto",
                    width: "100%",
                    "&:hover": {
                      borderColor: '#1E90FF',
                      transform: "scale(1.05)",
                      boxShadow: "0 6px 15px rgba(0,0,0,0.5)",
                    },
                  }}
                >
                  {/* Display formatted date and time */}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#b0bec5",
                      fontSize: "0.875rem",
                      padding: "0.5rem",
                      textAlign: "center",
                      marginBottom: "1rem",
                      fontStyle: "italic",
                    }}
                  >
                    {formatDistanceToNow(new Date(tab.date_time), { addSuffix: true, locale: enGB })}
                  </Typography>

                  <Box sx={{ flexGrow: 1, padding: "1rem" }}>
                    <Typography
                      variant="h8"
                      sx={{
                        fontWeight: "bold",
                        marginBottom: "1rem",
                        color: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "1.4rem",
                      }}
                    >
                      {tab.topic_name}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: "#b0bec5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1rem",
                        textAlign: "center",
                        padding: "1rem",
                      }}
                    >
                      {tab.description}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
          ))
        ) : (
          <Typography
            variant="body1"
            align="center"
            sx={{
              color: "#bdbdbd",
              width: "100%",
              fontSize: "1.4rem",
              padding: "1rem",
            }}
          >
            No recent activity found.
          </Typography>
        )}
      </Grid>
    </Box>
  );
}

export default RecentActivity;
