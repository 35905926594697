import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header1 from '../Header/Header1';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { Box, Typography, Button, Select, MenuItem, FormControl, InputLabel, Paper, Chip } from '@mui/material';


const API_URL = process.env.REACT_APP_API_URL;

function SubscribeDetails({ onLogout, handleSubscriptionSuccess }) {
  const location = useLocation();
  // const { plan } = location.state || {}; // Safely extract 'plan' with fallback to an empty object
  const { id } = useParams();
  const [sections, setSections] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [plan, setPlan] = useState(null);
  const userId = new URLSearchParams(location.search).get('userId');

  useEffect(() => {

    const fetchPlanDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/get_subscription_plan_details/?subscription_id=${id}`);
        setPlan(response.data);
      } catch (error) {
        console.error('Error fetching plan details:', error);
      }
    };

    const fetchSectionDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/get_subscription_section_details/`);
        setSections(response.data);
      } catch (error) {
        console.error('Error fetching section details:', error);
      }
    };
    fetchPlanDetails();
    fetchSectionDetails();
  }, []);

  // Handle case where 'plan' is undefined or missing
  if (!plan) {
    return <Typography variant="h6">Invalid subscription plan. Please try again.</Typography>;
  }

  const handleSectionChange = (event) => {
    const value = event.target.value;
    setSelectedSections(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSubscribe = async () => {
    const remaining_days = plan.plan_type === 'Monthly' ? 30 : plan.plan_type === 'Half_yearly' ? 182 : 365;
    const payload = {
      user_id: userId,
      subscription_id: plan.id,
      section_ids: selectedSections.join(','),
      remaining_posts: plan.post_count,
      remaining_requests: plan.request_count,
      plan_type: plan.plan_type,
      remaining_days,
    };

    try {
      await axios.post(`${API_URL}/create_subscription_mapping/`, payload);
      handleSubscriptionSuccess(plan.id);  // Update the active subscription state
    } catch (error) {
      console.error('Error during subscription:', error);
    }
  };

  return (
    <Box>
      <Header1 userId={userId} onLogout={onLogout} />
      <Box sx={{ maxWidth: 600, mx: 'auto', mt: 5, px: 3 }}>
        <Paper elevation={4} sx={{ p: 4, borderRadius: 3 }}>
          <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold', textAlign: 'center' }}>
            Subscription Details
          </Typography>

          <Typography variant="h5" sx={{ mb: 2 }}>
            {plan.plan_name}
          </Typography>
          {/* <Typography sx={{ mb: 1 }}>Sections Allowed: {plan.sections_included}</Typography> */}
          <Typography sx={{ mb: 1 }}>Posts Allowed: {plan.post_count}</Typography>
          {/* <Typography sx={{ mb: 1 }}>Requests Allowed: {plan.request_count}</Typography> */}
          <Typography sx={{ mb: 3 }}>Cost: ₹{plan.price}</Typography>

          {/* <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Select Sections</InputLabel>
            <Select multiple value={selectedSections} onChange={handleSectionChange}>
              {sections.map((section) => (
                <MenuItem key={section.id} value={section.id}>
                  {section.profession_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <Button
            variant="contained"
            fullWidth
            onClick={handleSubscribe}
            disabled={selectedSections.length > plan.sections_included}
            sx={{
              background: 'linear-gradient(90deg, #43a047, #1b5e20)',
              color: 'white',
              py: 1.5,
              '&:hover': {
                background: 'linear-gradient(90deg, #388e3c, #2e7d32)',
              },
            }}
          >
            Confirm Subscription
          </Button>
        </Paper>
      </Box>
      <Footer />
    </Box>
  );
}

export default SubscribeDetails;