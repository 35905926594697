import React, { useState } from 'react';
import { TextField, Button, Grid, Paper, Typography, Box } from '@mui/material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function CreateSections({ userId }) {
  const [sectionName, setSectionName] = useState('');
  const [numSubsections, setNumSubsections] = useState(0);
  const [subsections, setSubsections] = useState([]);
  const [sectionColor, setSectionColor] = useState('#FFFFFF'); // Default color white

  const handleNumSubsectionsChange = (e) => {
    const value = e.target.value;
    setNumSubsections(value);
    setSubsections(Array.from({ length: value }, (_, i) => subsections[i] || { name: '', description: '' }));
  };

  const handleSubsectionChange = (index, field, value) => {
    const newSubsections = [...subsections];
    newSubsections[index] = { ...newSubsections[index], [field]: value };
    setSubsections(newSubsections);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      sectionName,
      subsections,
      userId,
      color: sectionColor, // Send the section color
    };

    try {
      const response = await axios.post(`${API_URL}/create_sections/`, data);
      showAlert()
      window.location.reload();
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  const showAlert = () => {
    const alertDiv = document.createElement('div');
    alertDiv.textContent = 'Subscription created successfully';
    alertDiv.style.position = 'fixed';
    alertDiv.style.top = '10px';
    alertDiv.style.left = '50%';
    alertDiv.style.transform = 'translateX(-50%)';
    alertDiv.style.padding = '10px 20px';
    alertDiv.style.backgroundColor = 'green';
    alertDiv.style.color = 'white';
    alertDiv.style.borderRadius = '5px';
    alertDiv.style.zIndex = '9999';
    alertDiv.style.fontSize = '16px';

    document.body.appendChild(alertDiv);

    // Auto remove the alert after 3 seconds
    setTimeout(() => {
        alertDiv.remove();
    }, 3000);
};

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'black' }}>
      <Box sx={{ flexGrow: 1, marginTop: '80px' }}>
        <Paper
          sx={{
            maxWidth: 600,
            margin: '20px auto',
            padding: 4,
            background: 'linear-gradient(45deg, #6a1b9a, #2196f3)',
            borderRadius: 2,
            boxShadow: 8,
          }}
        >
          <Typography variant="h4" align="center" color="white" fontWeight="bold" gutterBottom>
            Create Sections
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Section Name */}
              <Grid item xs={12}>
                <TextField
                  label="Section Name"
                  variant="outlined"
                  fullWidth
                  value={sectionName}
                  onChange={(e) => setSectionName(e.target.value)}
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Number of Subsections */}
              <Grid item xs={12}>
                <TextField
                  label="Number of Subsections"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={numSubsections}
                  onChange={handleNumSubsectionsChange}
                  min="0"
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Section Color */}
              <Grid item xs={12}>
                <TextField
                  label="Section Color"
                  type="color"
                  value={sectionColor}
                  onChange={(e) => setSectionColor(e.target.value)}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Subsections */}
              {Array.from({ length: numSubsections }).map((_, index) => (
                <Grid item xs={12} key={index}>
                  <TextField
                    label={`Subsection Name ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    value={subsections[index]?.name || ''}
                    onChange={(e) => handleSubsectionChange(index, 'name', e.target.value)}
                    required
                    InputLabelProps={{
                      style: { color: '#e1bee7' },
                    }}
                    InputProps={{
                      style: { color: 'white' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#ba68c8',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#8e24aa',
                        },
                      },
                    }}
                  />
                  <TextField
                    label={`Subsection Description ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    value={subsections[index]?.description || ''}
                    onChange={(e) => handleSubsectionChange(index, 'description', e.target.value)}
                    required
                    InputLabelProps={{
                      style: { color: '#e1bee7' },
                    }}
                    InputProps={{
                      style: { color: 'white' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#ba68c8',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#8e24aa',
                        },
                      },
                    }}
                  />
                </Grid>
              ))}

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    background: 'linear-gradient(45deg, #8e24aa, #2196f3)',
                    padding: '12px',
                    fontSize: '16px',
                    color: 'white',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #7b1fa2, #1976d2)',
                      boxShadow: 8,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default CreateSections;
