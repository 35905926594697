import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Divider, IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Shipping = ({userId, loggedIn, onLogout}) => {

    const navigate = useNavigate();
    
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleLogout = () => {
        onLogout(); // Call the passed logout function
        navigate('/login'); // Navigate to login page after logout
    };

  return (
    <div>
        {loggedIn ? (
        <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
      ) : (
        <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
      )}
    
    <Container maxWidth="md" sx={{ paddingTop: 4, mt:10 }}>
      {/* Title Section */}
      <Box textAlign="center">
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontSize: '26px', fontWeight: 'bold', color: '#ffffff' }}>
          Shipping Policy
        </Typography>
        <Typography variant="subtitle1" component="h2" sx={{ fontSize: '14px', color: '#595959' }}>
          <strong>Last updated:</strong> January 26, 2025
        </Typography>
      </Box>

      <Divider sx={{ marginTop: 3 }} />

      {/* Shipping Policy Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>Shipping Information</Typography>

        <Typography variant="body1" sx={{ fontSize: '16px', color: '#595959' }}>
          Thank you for visiting our website. We regret to inform you that shipping is not applicable for our business at this time. 
          All purchases made through our site are not eligible for delivery or shipping services. We apologize for any inconvenience this may cause.
        </Typography>
        
        <Typography variant="body1" sx={{ fontSize: '16px', color: '#595959', marginTop: 2 }}>
          If you have any further questions, feel free to reach out to our support team at <strong>official@anthrasync.com</strong>.
        </Typography>
      </Box>
      
    </Container>

    {/* Scroll to Top Button */}
    <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>

    <Footer2 />
  </div>
  );
};

export default Shipping;
