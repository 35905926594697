import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Typography, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function MyBookings({ userId, searchResults }) {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);  // Start with page 1
  const [allFetched, setAllFetched] = useState(false);
  const navigate = useNavigate();

  // Fetch data from the backend API
  const fetchBookings = async (page) => {
    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/list_my_bookings/?userId=${userId}&page=${page}`);
      if (!response.ok) {
        throw new Error('Failed to fetch bookings');
      }

      const data = await response.json();

      // If no data is returned, we stop fetching
      if (data.length === 0) {
        setAllFetched(true);  // Mark as all data fetched
      } else {
        setCourses((prevCourses) => {
          // Avoid adding duplicate courses
          const newCourses = data.filter(course => !prevCourses.some(existingCourse => existingCourse.id === course.id));
          return [...prevCourses, ...newCourses];  // Append new data
        });
      }

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Fetch data when the page changes (triggered by scroll)
  useEffect(() => {
    if (!allFetched) {
      fetchBookings(page);
    }
  }, [page]);

  // Handle scroll to trigger next page fetch
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (windowHeight + scrollPosition >= documentHeight - 1 && !loading && !allFetched) {
      setPage((prevPage) => prevPage + 1);  // Increment page to load the next set of data
    }
  };

  // Add event listener for scroll on component mount and cleanup on unmount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, allFetched]); // Only re-run the effect when `loading` or `allFetched` changes

  // UseEffect for handling `searchResults` updates
  useEffect(() => {
    if (searchResults) {
      setCourses(searchResults);  // Reset courses to searchResults if provided
      setPage(1);  // Reset page to 1 on new search
      setAllFetched(false);  // Allow further fetches if new search is performed
    }
  }, [searchResults]);

  // Format start time
  const formatStartTime = (time) => {
    const [hours, minutes] = time.split(':');
    const dateObj = new Date();
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);

    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit', minute: '2-digit', hour12: true
    });

    return formatter.format(dateObj);
  };

  // Format duration
  const formatDuration = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;

    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }

    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  };

  // Loading and error states
  if (loading && page === 1) {
    return <Typography variant="h6" sx={{ textAlign: 'center', padding: '2rem' }}>Loading your bookings...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" sx={{ textAlign: 'center', padding: '2rem' }}>Error: {error}</Typography>;
  }

  if (courses.length === 0) {
    return <Typography variant="h6" sx={{ textAlign: 'center', padding: '2rem' }}>Bookings Not Available</Typography>;
  }


    const handleRedirect = () => {
        navigate('/meeting-guidelines');  // Redirect to meeting guidelines page
    };

  return (
    <Box sx={{ padding: '2rem' }}>
      <Grid container spacing={5} direction="column">
        {courses.map((course) => {
          const fullDateTimeString = `${course.selectedDate}T${course.startTime}`;
          const sessionStartTime = new Date(fullDateTimeString);
          const sessionEndTime = new Date(course.session_end_time);
          const currentTime = new Date();
          const isSessionEnded = currentTime.getTime() >= sessionEndTime.getTime();
          const isSessionAboutToStart = currentTime.getTime() >= sessionStartTime.getTime() - 10 * 60 * 1000; // 10 minutes before start
          const isSessionOngoing = currentTime.getTime() >= sessionStartTime.getTime() && currentTime.getTime() <= sessionEndTime.getTime();

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={course.id}>
              <Box sx={{
                padding: {
                  xs: '0.5rem',   // Less padding on small screens (mobile)
                  sm: '1rem',     // Standard padding for medium screens (tablets)
                },
                backgroundColor: '#2222',
                color: '#ecf0f1',
                borderRadius: '16px',
                boxShadow: {
                  xs: 1,        // Lighter shadow for small screens
                  sm: 3,        // Standard shadow for medium screens
                  md: 6,        // Heavier shadow for larger screens
                },
                height: 'auto',
                maxHeight: {
                  xs: '500px',  // Smaller maxHeight on mobile
                  sm: '500px',  // Standard maxHeight for medium screens
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                '&:hover': {
                  boxShadow: 6,               // Larger shadow on hover for all screens
                  backgroundColor: '#1111',
                  borderColor: '#1E90FF',
                  border: `2px solid #1E90FF`,
                },
                transition: 'box-shadow 0.3s ease-in-out',
                overflow: 'hidden',
                marginLeft: {
                  xs: '1rem',  // Add margin-left of 2rem for small screens
                  sm: '0',     // No margin-left for larger screens
                },
              }}>
                <Box
  sx={{
    display: 'flex',
    flexDirection: {
      xs: 'column',  // Stack items vertically on small screens
      sm: 'row',     // Align items horizontally on medium screens and larger
    },
    justifyContent: {
      xs: 'center',  // Center items horizontally on small screens
      sm: 'space-between',  // Align items with space between on larger screens
    },
    alignItems: {
      xs: 'center',  // Center items vertically on small screens
      sm: 'stretch', // Stretch items on larger screens
    },
    marginBottom: '0.5rem',
    gap: {
      xs: '0.5rem',  // Add some gap between the elements on small screens
      sm: 0,         // No gap on larger screens
    },
  }}
>

                  <Typography
                    variant="body2"
                    sx={{
                      color: '#ecf0f1',
                      fontWeight: 'bold',
                      fontSize: {
                        xs: '0.875rem',  // Smaller font size on mobile
                        sm: '1rem',      // Standard font size on larger screens
                      },
                    }}
                  >
                    {course.subsection_name}
                  </Typography>

                  <Typography
                    variant="caption"
                    sx={{
                      color: '#95a5a6',
                      fontSize: {
                        xs: '0.75rem',  // Smaller font size for mobile
                        sm: '0.875rem', // Standard font size for larger screens
                      },
                    }}
                  >
                    Posted on: {course.created_at}
                  </Typography>
                </Box>


                <Typography
                  variant="h6"
                  sx={{
                    textAlign: 'center', // Keep text centered for all screen sizes
                    fontWeight: 'bold',
                    color: '#ecf0f1',
                    marginBottom: '1rem',
                    fontSize: {
                      xs: '1.25rem',   // Font size for small screens (xs)
                      sm: '1.5rem',    // Font size for medium screens (sm)
                      md: '1.75rem',   // Font size for large screens (md)
                      lg: '2rem',      // Font size for extra large screens (lg)
                    },
                    '&:hover': {
                      color: '#1E90FF', // Change text color on hover
                    },
                  }}
                >
                  {course.topic_name}
                </Typography>


                <Box
  sx={{
    display: 'flex',
    flexDirection: {
      xs: 'column', // For small screens, stack the items vertically
      sm: 'row',    // For medium screens, arrange items in a row
    },
    justifyContent: {
      xs: 'center', // Center content horizontally on small screens
      sm: 'space-between', // For larger screens, align items with space between
    },
    alignItems: {
      xs: 'center', // Center content vertically on small screens
      sm: 'stretch', // Stretch items on larger screens to fill container
    },
    marginTop: 'auto',
    gap: '1rem',  // Add space between elements in the column layout
    padding: {
      xs: '1rem', // Padding for small screens
      sm: '2rem', // Padding for larger screens
    },
  }}
>
                  <Typography variant="body2" sx={{ color: '#bdc3c7' }}>
                    Guide: {course.guide_name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#bdc3c7' }}>
                    Language: {course.language}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#bdc3c7' }}>
                    Date: {course.selectedDate}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#bdc3c7' }}>
                    Time: {formatStartTime(course.startTime)}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#bdc3c7' }}>
                    Duration: {formatDuration(course.duration)}
                  </Typography>
                </Box>


                <Box sx={{ marginTop: '1rem', textAlign: 'center' }}>
                  {course.comments ? (
                    <Typography
                    variant="h6"
                    sx={{
                      color: '#2ecc71',
                      fontSize: {
                        xs: '1.2rem',  // Smaller font size for extra small screens
                        sm: '1.4rem',  // Larger font size for small screens
                        md: '1.6rem',  // Medium font size for medium screens (tablets)
                        lg: '1.8rem',    // Larger font size for large screens (desktops)
                      },
                      textAlign: 'center',  // Ensures the text is center-aligned
                      padding: {
                        xs: '0.5rem',  // Padding for small screens
                        sm: '1rem',    // Padding for medium and larger screens
                      },
                      fontStyle: 'italic',  // Italic font style
                    }}
                  >
                    Thank you for your feedback!
                  </Typography>
                  

                  ) : isSessionEnded ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        width: {
                          xs: '100%',  // Full width on mobile
                          sm: 'auto',  // Auto width on tablets and larger screens
                        },
                        padding: {
                          xs: '0.8rem',  // Smaller padding on mobile
                          sm: '1rem',    // Default padding on larger screens
                        },
                        fontSize: {
                          xs: '0.9rem',  // Smaller font size on mobile
                          sm: '1rem',    // Default font size on small screens
                          md: '1.2rem',  // Medium font size for medium screens
                          lg: '1.4rem',  // Larger font size for larger screens
                        },
                      }}
                      onClick={() => navigate(`/feedbackform/${course.id}`)}
                    >
                      Give Feedback
                    </Button>

                  ) : isSessionAboutToStart ? (
                    <a href={course.meeting_link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          width: {
                            xs: '100%',  // Full width on extra small screens (mobile)
                            sm: 'auto',  // Auto width on small screens and up (tablet and desktop)
                          },
                          padding: {
                            xs: '0.8rem',  // Smaller padding on mobile
                            sm: '1rem',    // Default padding on small screens
                          },
                          fontSize: {
                            xs: '0.9rem',  // Smaller font size on mobile
                            sm: '1rem',    // Default font size on small screens
                            md: '1.2rem',  // Medium font size for medium screens
                            lg: '1.4rem',  // Larger font size for larger screens
                          },
                        }}
                      >
                        Join Now
                      </Button>
                      <Typography
  sx={{
    mt: { xs: 1, sm: 2 }, // Adjust margin top for different screen sizes
    textAlign: 'center',
    color:"#1E90FF",
    fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }, // Adjust font size for responsiveness
  }}
>
  By clicking <strong>Join Now</strong>, you will be redirected to an external meeting platform. By doing so, you agree to that platform's Terms of Service and Privacy Policy.
</Typography>


                    </a>
                  ) : (
                    <Button
                      variant="contained"
                      color="info"
                      onClick={handleRedirect}
                      sx={{
                        width: {
                          xs: '100%',  // Full width on extra small screens (mobile)
                          sm: 'auto',  // Auto width on small screens and up (tablet and desktop)
                        },
                        padding: {
                          xs: '0.8rem', // Adjust padding for smaller screens
                          sm: '1rem',   // Larger padding for larger screens
                        },
                        fontSize: {
                          xs: '0.8rem',  // Smaller font size on mobile
                          sm: '1rem',    // Default font size on small screens
                          md: '1.2rem',  // Medium font size for medium screens
                          lg: '1.4rem',  // Larger font size for larger screens
                        },
                      }}
                    >
                      Meeting Instructions
                    </Button>

                  )}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default MyBookings;
