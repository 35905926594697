import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system';
import {
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    CircularProgress,
    createTheme,
    ThemeProvider,
    Card,
    CardContent,
    Snackbar,
    Input, FormHelperText
} from '@mui/material';
import axios from 'axios';
import PdfViewer from './PdfViewer';

const API_URL = process.env.REACT_APP_API_URL;

const SectionContainer = styled(Box)({
    background: '#000000',
    borderRadius: '20px',
    padding: '20px', // Default padding for larger screens
    marginBottom: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    color: '#ffffff',

    // Mobile first approach
    '@media (max-width: 600px)': {
        padding: '16px', // Smaller padding for mobile screens
        marginBottom: '15px', // Adjust margin for mobile
    },

    '@media (min-width: 600px) and (max-width: 960px)': {
        padding: '24px', // Slightly larger padding for tablets or medium screens
    },

    '@media (min-width: 960px)': {
        padding: '30px', // Larger padding for desktops and larger screens
    },
});

const CustomInputLabel = styled(InputLabel)({
    color: 'rgba(255, 255, 255, 0.7)',
});

const CustomButton = styled(Button)({
    backgroundColor: '#E90FF',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '6px',
    '&:hover': {
        backgroundColor: '#E90FF',
    },
});


function SubmittedData({ userId, onLogout, formData, submittedData }) {

    const [editMode, setEditMode] = useState({
        bankAccount: false,
        ifsc: false,
        bankFile: false
    });
    const [bankAccount, setBankAccount] = useState(submittedData.bank_account);
    const [ifsc, setIfsc] = useState(submittedData.ifsc);
    const [bankFile, setBankFile] = useState(submittedData.bank_account_file);

    const [sectionId, setSectionId] = useState('');
    const [subsectionId, setSubsectionId] = useState('');
    const [sections, setSections] = useState([]);
    const [subsections, setSubsections] = useState([]);
    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false);

    const [selectedDate, setSelectedDate] = useState('');
    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState('');

    // State for custom input fields when "Other" is selected
    const [customSectionName, setCustomSectionName] = useState('');
    const [customSubsectionName, setCustomSubsectionName] = useState('');


    // Handle changes for Custom Section Input
    const handleCustomSectionChange = (event) => {
        setCustomSectionName(event.target.value);
    };

    // Handle changes for Custom Subsection Input
    const handleCustomSubsectionChange = (event) => {
        setCustomSubsectionName(event.target.value);
    };

    const [videoLink, setVideoLink] = useState('');
    //   const [error, setError] = useState('');
    const [isValid, setIsValid] = useState(true); // Track if the link is valid

    // Validate video link to check if it's a YouTube or Google Drive link
    const validateLink = (link) => {
        const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/.+$/;
        const googleDrivePattern = /^(https?:\/\/)?(drive\.google\.com\/).*$/;
        return youtubePattern.test(link) || googleDrivePattern.test(link);
    };

    // Handle input change and validation on the fly
    const handleInputChange = (event) => {
        const value = event.target.value;
        setVideoLink(value);

        // Validate the link immediately after change
        if (validateLink(value)) {
            setIsValid(true);
            setError('');
        } else {
            setIsValid(false);
            setError('Please enter a valid YouTube or Google Drive link.');
        }
    };

    // Handle button click to navigate to guidelines page
    const handleRedirect = () => {
        window.location.href = '/video-creation-guidelines'; // Use window.location for redirection
    };


    // const handleFileChange = (event) => {
    //     setBankAccountFile(event.target.files[0]);
    // };

    const handleEditToggle = (field) => {
        setEditMode((prev) => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const handleSave = () => {
        const formData = new FormData();

        // Add regular fields (such as IFSC, Bank Account, etc.)
        if (ifsc !== submittedData.ifsc) {
            formData.append('ifsc', ifsc);  // Append IFSC only if it's changed
        }

        if (bankAccount !== submittedData.bank_account) {
            formData.append('bank_account', bankAccount);  // Append Bank Account only if it's changed
        }

        // Check if bankFile was selected and is different from the previous one
        if (bankFile && bankFile !== submittedData.bank_account_file) {
            formData.append('bank_account_file', bankFile);  // Append file if it's a new selection
        }

        // If no changes are made, we avoid sending any request
        if (formData.entries().next().done) {
            alert("No changes were made to the bank details.");
            return;
        }

        updateUserVerification(userId, formData);
    }

    // Call the API with userId and formData
    const updateUserVerification = async (userId, formData) => {
        try {
            const response = await axios.patch(
                `${API_URL}/update_user_verification/${userId}/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Ensure this header is set for file uploads
                    },
                }
            );
            alert('Bank Details Updated successfully!')
            window.location.reload()
        } catch (error) {
            console.error('Error updating bank details:', error);
        }
    };



    const handleSectionChange = (event) => {
        const selectedSectionId = event.target.value;
        setSectionId(selectedSectionId);


        const selectedSection = sections.find(section => section.sectionId === selectedSectionId);
        setSubsections(selectedSection ? selectedSection.subsections : []);
        setSubsectionId('');
    };

    const handleSubsectionChange = (event) => {
        setSubsectionId(event.target.value);
    };


    useEffect(() => {
        // Fetch sections and subsections data from the backend
        axios.get(`${API_URL}/list_sections_and_subsections/?user_id=${userId}&available_sections_is_true=${true}`)
            .then((response) => {

                if (response.data && response.data.sections) {
                    const fetchedSections = Object.entries(response.data.sections).map(([sectionName, sectionData]) => ({
                        sectionName,
                        sectionId: sectionData.section_id,
                        subsections: sectionData.subsections,
                    }));


                    setSections(fetchedSections);
                } else {
                    console.error("Sections data is missing in the response!");
                }
            })
            .catch((error) => {
                console.error('Error fetching sections:', error);
            });
    }, []);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
        fetchAvailableSlots(event.target.value);
    };

    const fetchAvailableSlots = async (date) => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_URL}/get_available_slots/?date=${date}`);
            setAvailableSlots(response.data.slots);
        } catch (error) {
            console.error("Error fetching available slots:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSlotChange = (event) => {
        setSelectedSlot(event.target.value);
    };

    const formatInterviewSlot = (selectedDate, selectedTime) => {
        // Combine the selected date and time into a single string (e.g. "2025-02-07 06:00")
        const combinedDateTime = `${selectedDate} ${selectedTime}`;

        // Parse the combined string into a Date object (without any timezone offset)
        const date = new Date(combinedDateTime + ' UTC');

        // Set the timezone to IST (Indian Standard Time, UTC +5:30)
        const istDate = new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));

        // Format the date to the required format (YYYY-MM-DD HH:mm:ss)
        const formattedDate = istDate.toISOString().replace('T', ' ').split('.')[0]; // YYYY-MM-DD HH:mm:ss

        // Return the final interview_slot in the correct format with +05:30 timezone offset
        return `${formattedDate.replace(' ', 'T')}+05:30`;
    };






    const handlePost = () => {
        const selectedSection = sections.find(section => section.sectionId === sectionId);
        const sectionName = selectedSection ? selectedSection.sectionName : '';


        const selectedSubsection = subsections.find(subsection => subsection.id === subsectionId);
        const subsectionName = selectedSubsection ? selectedSubsection.name : '';





        const postData = {
            user_id: userId,
            subsection_id: subsectionId,
            is_active: true,
            status: 'pending',
            selected_date: selectedDate,
            // interview_slot: formatInterviewSlot(selectedDate, selectedSlot)
            video_link : "https://www.youtube.com/"
            // section_id: sectionId,
            // section_name: sectionName,
            // subsection_name: subsectionName,
        };



        axios.post(`${API_URL}/create_user_section_verification/`, postData).then((response) => {
            showAlert()
            window.location.reload();
        })
            .catch((error) => {
                setError('An error occurred while applying. Please try again later.');
                // setOpenSnackbar(true);
            });
    };

    const handleBankFileChange = (e) => {
        setBankFile(e.target.files[0]);
    };

    const handleDiscard = () => {
        // Reset all fields back to their original values from submittedData
        setBankAccount(submittedData.bank_account);
        setIfsc(submittedData.ifsc);
        setBankFile(null); // Reset bank file if needed (or set it back to the previous file)

        // Exit edit mode for all fields
        setEditMode({
            ...editMode,
            bankAccount: false,
            ifsc: false,
            bankFile: false,
        });
    };


    const showAlert = () => {
        const alertDiv = document.createElement('div');
        alertDiv.textContent = 'Applied for section verification successfully!';
        alertDiv.style.position = 'fixed';
        alertDiv.style.top = '10px';
        alertDiv.style.left = '50%';
        alertDiv.style.transform = 'translateX(-50%)';
        alertDiv.style.padding = '10px 20px';
        alertDiv.style.backgroundColor = 'green';
        alertDiv.style.color = 'white';
        alertDiv.style.borderRadius = '5px';
        alertDiv.style.zIndex = '9999';
        alertDiv.style.fontSize = '16px';

        document.body.appendChild(alertDiv);

        // Auto remove the alert after 3 seconds
        setTimeout(() => {
            alertDiv.remove();
        }, 3000);
    };


    return (
        <div>
            {/* Title */}
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                    Stage 3: Apply for Posting Access
                </Typography>
                {/* <Typography variant="subtitle1" sx={{ color: 'white', mt: 1 }}>
                                                        Unlock premium features and resources tailored for your needs
                                                    </Typography> */}
            </Box>
            <SectionContainer>
                

            <Box
  sx={{
    marginBottom: '2rem',
    padding: { xs: '1rem', sm: '1.5rem', md: '2rem' }, // Responsive padding
    backgroundColor: '#2222', // Fixed background color (adjusted hex color for visibility)
    borderRadius: '12px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem', // Spacing between each item
    maxWidth: '900px', // Maximum width for the box
    width: '100%', // Full width on smaller screens
    marginX: 'auto', // Center the box horizontally
  }}
>
<Typography
                    variant="h5"
                    color="text.primary"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem', lg: '2rem' }, // Responsive font size
                        marginBottom: { xs: '0.8rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
                        textAlign: 'center', // Center align the text
                    }}
                >
                    Submitted Data
                </Typography>

                    <Typography
                        variant="body1"
                        color="text.primary"
                        paragraph
                        sx={{
                            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }, // Adjust font size for different screen sizes
                            marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
                            lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjust line-height for readability on smaller screens
                        }}
                    >
                        <strong>Aadhar:</strong> {submittedData.aadhar || "Not Provided"}
                    </Typography>

                    <Typography
                        variant="body1"
                        color="text.primary"
                        paragraph
                        sx={{
                            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }, // Adjust font size for different screen sizes
                            marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
                            lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjust line-height for readability on smaller screens
                        }}
                    >
                        <strong>Pan:</strong> {submittedData.pan || "Not Provided"}
                    </Typography>


                    {/* <Typography variant="h6" color="text.primary" gutterBottom>
                        Bank Details
                    </Typography> */}

                    {/* Bank Account Number */}
                    <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="body1" color="text.primary">
                            <strong>Bank Account Number:</strong>{' '}
                            {editMode.bankAccount ? (
                                <TextField
                                    value={bankAccount}
                                    onChange={(e) => setBankAccount(e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    autoFocus
                                />
                            ) : (
                                <>
                                    {bankAccount || 'Not Provided'}
                                    <Button
  sx={{
    marginLeft: 2,
    borderColor: '#1E90FF',  // Custom border color (Tomato color)
    color: '#1E90FF', // Custom text color
    '&:hover': {
      borderColor: '#187bcd', // Darker shade for border on hover
      color: '#187bcd', // Darker text color on hover
    },
  }}
  variant="outlined"
  size="small"
  onClick={() => handleEditToggle('bankAccount')}
>
  Edit
</Button>

                                </>
                            )}
                        </Typography>
                    </Box>

                    {/* IFSC Code */}
                    <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="body1" color="text.primary">
                            <strong>IFSC Code:</strong>{' '}
                            {editMode.ifsc ? (
                                <TextField
                                    value={ifsc}
                                    onChange={(e) => setIfsc(e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    autoFocus
                                />
                            ) : (
                                <>
                                    {ifsc || 'Not Provided'}
                                    <Button
  sx={{
    marginLeft: 2,
    borderColor: '#1E90FF', // Custom border color
    color: '#1E90FF', // Custom text color
    '&:hover': {
      borderColor: '#187bcd', // Darker shade for border on hover
      color: '#187bcd', // Darker text color on hover
    },
  }}
  variant="outlined"
  size="small"
  onClick={() => handleEditToggle('ifsc')}
>
  Edit
</Button>

                                </>
                            )}
                        </Typography>
                    </Box>

                    {/* Bank Account File */}
                    {/* <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="body1" color="text.primary">
                            <strong>Bank Account File:</strong>{' '}
                            {submittedData.bank_account_file ? (
                                <>
                                    <Typography variant="body2" color="text.secondary">
                                        {submittedData.bank_account_file.name}
                                    </Typography>

                                    <a
                                        href={`${API_URL}${submittedData.bank_account_file}`} // Constructing the full URL
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            textDecoration: 'underline',
                                            color: '#1a73e8',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        View Bank Account File
                                    </a>

                                    <Button
                                        sx={{ ml: 2 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleEditToggle('bankFile')} // Toggle bankFile edit mode
                                    >
                                        {editMode.bankFile ? 'Cancel' : 'Edit'}
                                    </Button>

                                    {editMode.bankFile && (
                                        <>
                                            <input
                                                type="file"
                                                accept=".pdf, .jpg, .jpeg, .png"
                                                onChange={handleBankFileChange}
                                                style={{ marginTop: '1rem' }}
                                            />
                                            
                                        </>
                                    )}
                                </>
                            ) : (
                                'No File Attached'
                            )}
                        </Typography>
                    </Box> */}


                    {/* Save Changes Button */}
                    {(editMode.bankAccount || editMode.ifsc || editMode.bankFile) && (
                        <Grid container justifyContent="center" sx={{ marginTop: '2rem' }}>
                            {/* Save Changes Button */}
                            <Box
  sx={{
    display: 'flex',
    justifyContent: 'center', // Center the button horizontally
    alignItems: 'center', // Optionally, center the button vertically (if needed)
    width: '100%', // Ensure the container takes up full width
    mb: 2, // Margin-bottom to add space between button and other elements
  }}
>
  <Button
    variant="contained"
    onClick={handleSave}
    sx={{
      backgroundColor: '#1E90FF', // Custom blue color for the button
      '&:hover': {
        backgroundColor: '#187bcd', // Darker shade of blue for hover effect
      },
      marginRight: 2, // Spacing on the right (can be adjusted or removed if unnecessary)
    }}
  >
    Save Changes
  </Button>
  {/* Discard Changes Button */}
  <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleDiscard}
                            >
                                Discard Changes
                            </Button>
</Box>



                            
                        </Grid>
                    )}

                    


                </Box>

                <Box
  sx={{
    backgroundColor: '#2222', // Dark background color for contrast (adjusted from '#2222')
    borderRadius: 2, // Rounded corners for a soft look
    boxShadow: 2, // Subtle shadow to make it pop out a bit
    padding: { xs: '1rem', sm: '1.5rem', md: '2rem' }, // Responsive padding
    marginBottom: 2, // Space below the container
    width: '100%', // Ensure it takes full width
    maxWidth: '900px', // Limit the maximum width for readability
    marginX: 'auto', // Center the container horizontally
    display: 'flex', // Ensure flexbox layout (optional if you want to add child items)
    flexDirection: 'column', // Organize child items in a column layout (if needed)
  }}
>
<Typography
  variant="body1"
  color="text.primary"
  paragraph
  sx={{
    fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }, // Adjust font size for different screen sizes
    marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
    lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjust line-height for readability on smaller screens
    fontWeight: 'bold', // Make the headings stand out
  }}
>
  <strong>Approved Subcategories:</strong> 
  {/* Check if there are approved sections and handle different types */}
  {submittedData.approved_sections ? (
    Array.isArray(submittedData.approved_sections) ? (
      // If it's an array, map over the elements
      submittedData.approved_sections.map((section, index) => (
        <div key={index}>{section}</div> // Render each section in a new line
      ))
    ) : typeof submittedData.approved_sections === 'string' ? (
      // If it's a string, split by space or comma and map over the values
      submittedData.approved_sections.split(/[\s,]+/).map((section, index) => (
        <div key={index}>{section}</div> // Render each section in a new line
      ))
    ) : (
      "Invalid Data Type" // If the type is neither string nor array
    )
  ) : (
    "Not Provided" // Fallback message if no approved sections
  )}
</Typography>


  <Typography
    variant="body1"
    color="text.primary"
    paragraph
    sx={{
      fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' },
      marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' },
      lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 },
    }}
  >
    <strong>Applied Subcategories:</strong> {submittedData.pending_sections || "Not Provided"}
  </Typography>
</Box>

                {submittedData.pending_sections.length === 0 ? (
                    <Box
                    sx={{
                      width: '100%', // Take full width of the container
                      maxWidth: '900px', // Set a maximum width for the box
                      marginX: 'auto', // Center the box horizontally
                      padding: { xs: '1rem', sm: '1.5rem', md: '2rem' }, // Responsive padding
                    }}
                  >
                  
                        <Typography
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '1.7rem' },  // Adjust font size for different screen sizes
                                lineHeight: 1.5,  // Optional: You can adjust the line height to ensure text is readable
                                padding: { xs: '10px', sm: '15px', md: '20px' },  // Adjust padding for responsiveness
                                textAlign: 'center'  // Optional: Center the text on all screens (if desired)
                            }}
                        >
                            Please select one category and subcategory for which posting access is required.
                        </Typography>


                        {/* Section Selector */}
                        <FormControl fullWidth sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
                            <CustomInputLabel>Select Category</CustomInputLabel>
                            <Select
                                value={sectionId}
                                onChange={handleSectionChange}
                                label="Select Category"
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    border: '2px solid #9999',
                                }}
                            >
                                {sections.map((section) => (
                                    <MenuItem key={section.sectionId} value={section.sectionId}>
                                        {section.sectionName}
                                    </MenuItem>
                                ))}
                                {/* Add an "Other" option */}
                                {/* <MenuItem value="other">Other</MenuItem> */}
                            </Select>
                        </FormControl>

                        {sectionId === 'other' && (
                            <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                                <CustomInputLabel>Enter Custom Category</CustomInputLabel>
                                <Input
                                    value={customSectionName}
                                    onChange={handleCustomSectionChange}
                                    sx={{
                                        color: 'rgba(255, 255, 255, 0.7)',
                                        border: '2px solid #9999',
                                        width: '100%', // Make it fill the width of its container
                                        padding: '0.8rem', // Adjust padding for better spacing
                                        fontSize: '1rem', // Set default font size
                                        '@media (max-width: 600px)': {
                                            fontSize: '0.875rem', // Smaller font size on small screens
                                            padding: '0.5rem', // Less padding on small screens
                                        },
                                        '@media (min-width: 600px) and (max-width: 900px)': {
                                            fontSize: '0.95rem', // Medium size font for tablets
                                            padding: '0.7rem',
                                        },
                                        '@media (min-width: 900px)': {
                                            fontSize: '1rem', // Larger font for desktops
                                            padding: '0.8rem',
                                        },
                                    }}
                                />

                            </FormControl>
                        )}


                        {/* Subsection Selector */}
                        {subsections.length > 0 && (
                            <Box sx={{ marginBottom: '1.5rem' }}>
                                <FormControl fullWidth sx={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <CustomInputLabel>Select Subcategory</CustomInputLabel>
                                    <Select
                                        value={subsectionId}
                                        onChange={handleSubsectionChange}
                                        label="Select Subcategory"
                                        sx={{
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            border: '2px solid #9999',
                                        }}
                                    >
                                        {subsections.map((subsection) => (
                                            <MenuItem key={subsection.id} value={subsection.id}>
                                                {subsection.name}
                                            </MenuItem>
                                        ))}
                                        {/* Add an "Other" option for Subsection */}
                                        {/* <MenuItem value="other">Other</MenuItem> */}
                                    </Select>
                                </FormControl>

                                {/* If 'Other' is selected in Subsection, show an input field */}
                                {subsectionId === 'other' && (
                                    <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                                        <CustomInputLabel>Enter Custom Subcategory</CustomInputLabel>
                                        <Input
                                            value={customSubsectionName}
                                            onChange={handleCustomSubsectionChange}
                                            sx={{
                                                color: 'rgba(255, 255, 255, 0.7)',
                                                border: '2px solid #9999',
                                                width: '100%', // Make it fill the container
                                                padding: '0.8rem', // Default padding
                                                fontSize: '1rem', // Default font size
                                                '@media (max-width: 600px)': {
                                                    fontSize: '0.875rem', // Smaller font size for small screens (mobile)
                                                    padding: '0.5rem', // Less padding for small screens
                                                },
                                                '@media (min-width: 600px) and (max-width: 900px)': {
                                                    fontSize: '0.95rem', // Medium font size for tablets
                                                    padding: '0.7rem', // Medium padding for tablets
                                                },
                                                '@media (min-width: 900px)': {
                                                    fontSize: '1rem', // Default or larger font size for desktops
                                                    padding: '0.8rem', // Default or larger padding for desktops
                                                },
                                            }}
                                        />

                                    </FormControl>)}





                                {/* <Typography
                                    sx={{
                                        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '1.7rem' },  // Adjust font size for different screen sizes
                                        lineHeight: 1.5,  // Optional: You can adjust the line height to ensure text is readable
                                        padding: { xs: '10px', sm: '15px', md: '20px' },  // Adjust padding for responsiveness
                                        textAlign: 'center'  // Optional: Center the text on all screens (if desired)
                                    }}
                                >
                                    Please select any three available date and time slots for a short online meeting for verification.
                                </Typography> */}

                                {/* <TextField
                                    type="date"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    fullWidth
                                    margin="normal"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#808080',
                                            color: '#ffffff',
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#ffffff' },
                                    }}
                                    InputProps={{
                                        style: { color: '#ffffff' },
                                    }}
                                    // Allow dates only within the next 6 months
                                    inputProps={{
                                        min: new Date().toISOString().split('T')[0],
                                        max: new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString().split('T')[0]
                                    }}
                                /> */}

                                {/* Loading Spinner
                                {loading && (
                                    <Grid container justifyContent="center" sx={{ marginTop: '2rem' }}>
                                        <CircularProgress size={24} color="inherit" />
                                    </Grid>
                                )} */}

                                {/* Time Slot Selector */}
                                {/* {availableSlots.length > 0 && !loading && (
                                    <FormControl fullWidth sx={{ marginTop: '1.5rem' }}>
                                        <InputLabel sx={{ color: '#ffffff' }}>Select Time Slot</InputLabel>
                                        <Select
                                            value={selectedSlot}
                                            onChange={handleSlotChange}
                                            label="Select Time Slot"
                                            sx={{
                                                color: 'rgba(255, 255, 255, 0.7)',
                                                border: '2px solid #9999',
                                            }}
                                        >
                                            {availableSlots.map((slot, index) => (
                                                <MenuItem key={index} value={slot}>
                                                    {slot}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )} */}

                                {/* Button to navigate to video creation guidelines page */}
                                {/* <Button
    variant="contained"
    onClick={handleRedirect}
    sx={{
        marginBottom: '1rem', // Default margin for spacing
        padding: '0.8rem 2rem', // Default padding for larger screens
        fontSize: '1rem', // Default font size for larger screens
        borderRadius: '30px', // Rounded corners for a modern look
        background: 'linear-gradient(45deg, #4e6ef2, #6a8eff)', // Blue gradient background
        color: 'white', // White text for contrast
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
        transition: 'all 0.3s ease', // Smooth transition for hover effects
        '&:hover': {
            transform: 'scale(1.05)', // Slight scale effect on hover
            boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)', // Deeper shadow on hover
            background: 'linear-gradient(45deg, #6a8eff, #4e6ef2)', // Reversed blue gradient on hover
            cursor: 'pointer', // Pointer cursor for interactivity
        },
        '&:active': {
            transform: 'scale(0.98)', // Slight shrink effect on click
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Lighter shadow on click
        },
        '@media (max-width: 600px)': {
            fontSize: '0.875rem', // Smaller font size for mobile screens
            padding: '0.6rem 1.5rem', // Smaller padding for mobile screens
        },
        '@media (min-width: 600px) and (max-width: 900px)': {
            fontSize: '0.95rem', // Medium font size for tablets
            padding: '0.7rem 1.8rem', // Medium padding for tablets
        },
        '@media (min-width: 900px)': {
            fontSize: '1rem', // Default font size for desktop screens
            padding: '0.8rem 2rem', // Default padding for desktop screens
        },
    }}
>
    Video Creation Guidelines
</Button> */}




                                {/* Input field for demo video link */}
                                {/* <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}> */}
                                    {/* <Input
                                        value={videoLink}
                                        onChange={handleInputChange} // On change, validate the link immediately
                                        placeholder="Enter demo video link"
                                        sx={{
                                            border: '2px solid #9999',
                                            width: '100%', // Ensure input takes full width of the container
                                            padding: '0.8rem', // Default padding for larger screens
                                            fontSize: '1rem', // Default font size for larger screens
                                            '@media (max-width: 600px)': {
                                                fontSize: '0.875rem', // Smaller font size for mobile screens
                                                padding: '0.6rem', // Smaller padding for mobile screens
                                            },
                                            '@media (min-width: 600px) and (max-width: 900px)': {
                                                fontSize: '0.95rem', // Medium font size for tablets
                                                padding: '0.7rem', // Medium padding for tablets
                                            },
                                            '@media (min-width: 900px)': {
                                                fontSize: '1rem', // Default font size for desktop screens
                                                padding: '0.8rem', // Default padding for desktop screens
                                            },
                                        }}
                                    /> */}

                                    {/* {!isValid && <FormHelperText error>{error}</FormHelperText>} Show validation error */}
                                {/* </FormControl> */}


                            </Box>
                        )}


                        {/* Submit Button */}
                        <Grid container justifyContent="center">
                            <CustomButton
                                onClick={handlePost}
                                disabled={loading}
                                sx={{
                                    backgroundColor: '#1976d2',
                                    '&:hover': {
                                        backgroundColor: '#1565c0',
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: '#90caf9',
                                    },
                                }}
                            >
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Apply'}
                            </CustomButton>
                        </Grid>

                    </Box>
                ) : (
                    <Box>
                        {submittedData.interview_details && submittedData.interview_details.length > 0 ? (
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Interview Date:</strong> {new Date(submittedData.interview_details[0].interview_slot).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })}
                                    </Typography>


                                    {/* Interview Time (AM/PM format) */}
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Interview Time:</strong> {new Date(submittedData.interview_details[0].interview_slot).toLocaleTimeString('en-US', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true  // Ensures AM/PM format
                                        })}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        href={submittedData.interview_details[0].meeting_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Join Now
                                    </Button>
                                </CardContent>
                            </Card>
                        ) : (
                            ''
                        )}
                        <Typography
    variant="h6"
    color="warning.main"
    gutterBottom
    sx={{
        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' }, // Adjusts font size for better readability across devices
        lineHeight: { xs: 1.5, sm: 1.6, md: 1.8 }, // Adjusts line height to improve readability
        paddingX: { xs: 3, sm: 4, md: 5 }, // Adds responsive horizontal padding
        textAlign: 'center', // Always center-aligns text
        background: 'linear-gradient(45deg, #ff6f00, #ff9800)', // Gradient background for a modern touch
        color: 'transparent', // Keeps the text color transparent so the gradient can show
        backgroundClip: 'text', // Clips the background to the text
        fontWeight: 'bold', // Makes the font bold for emphasis
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow to make it pop
        borderRadius: 2, // Rounded corners for a modern design
        transition: 'all 0.3s ease', // Smooth transition for hover effect
        maxWidth: '800px', // Limits the max width for better text alignment
        margin: '0 auto', // Centers the text horizontally
        '&:hover': {
            transform: 'scale(1.05)', // Slight zoom-in effect on hover
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Increases shadow on hover
            // Keep gradient on hover
            background: 'linear-gradient(45deg, #ff6f00, #ff9800)',
            color: '#ffffff', // Change text color to white on hover for readability
        },
    }}
>
Thank you for applying. We will review your request and get back to you as soon as possible. Please keep visiting this page to know the status.
</Typography>


                    </Box>
                )
                }
            </SectionContainer>

        </div>
    )
}

export default SubmittedData
