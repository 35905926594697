import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import How_to_make_post from './How_to_book.mp4';

const DemoVideoSection3 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#000000',
        padding: 2,
      }}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: 800,
          borderRadius: 3,
          overflow: 'hidden',
          boxShadow: '0px 10px 30px #000000',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundColor:"#000000",
          }}
        >
          <Typography 
  variant="h4" 
  sx={{ 
    fontWeight: 'bold', 
    marginBottom: 2,
    textAlign: 'center', 
    fontSize: {
      xs: '1.5rem',  // Extra small screens
      sm: '2rem',    // Small screens
      md: '2rem',  // Medium screens
      lg: '2rem'     // Large screens
    }
  }}
>
  How to Book a Post
</Typography>

<Typography 
  variant="body1" 
  sx={{ 
    marginBottom: 3, 
    textAlign: 'center',
    fontSize: {
      xs: '0.875rem',  // Extra small screens
      sm: '1rem',      // Small screens
      md: '1.125rem',  // Medium screens
      lg: '1.25rem'    // Large screens
    }
  }}
>
  Watch this demo to learn how to book a post step-by-step.
</Typography>

          {/* Video Player */}
          <video
            src={How_to_make_post}
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
              borderRadius: '10px',
            }}
          />
          
          {/* Overlay Text */}
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              textAlign: 'center',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default DemoVideoSection3;
