import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper, Link, List, ListItem, ListItemText,Divider,IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const PrivacyPolicy = ({userId,loggedIn, onLogout}) => {

    const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    const handleLogout = () => {
        onLogout(); // Call the passed logout function
        navigate('/login'); // Navigate to login page after logout
      };
    return (
        <div>
            {loggedIn ? (
        <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
      ) : (
        <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
      )}
        <Container maxWidth="md" sx={{ paddingTop: 4, mt:10 }}>
            {/* Title Section */}
            <Box textAlign="center" mb={4}>
                <Typography variant="h3" component="h1" gutterBottom>
                    PRIVACY POLICY
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    Last updated January 26, 2025
                </Typography>
            </Box>

            {/* Privacy Notice Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="body1" paragraph>
                    This Privacy Notice for <strong>ANTHRASYNC SOLUTIONS PRIVATE LIMITED</strong> ("we", "us", or "our"), describes how and why we might access, collect, store, use, and/or share ("process") your personal information when you use our services ("Services"), including when you:
                </Typography>

                <List>
                    <ListItem>
                        <Typography variant="body1">
                            Visit our website at{' '}
                            <Link href="https://www.anthrasync.com" target="_blank" color="primary">
                                https://www.anthrasync.com
                            </Link>{' '}
                            or any website of ours that links to this Privacy Notice.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            Use Knowledge exchange platform. Our platform is designed to be a global knowledge exchange space, accessible to all users regardless of their language or geographical location. We aim to foster an inclusive environment where individuals from diverse backgrounds can share, learn, and collaborate freely. Our goal is to bridge gaps in knowledge by providing a space for everyone to contribute and benefit from a wide range of perspectives.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            Engage with us in other related ways, including any sales, marketing, or events.
                        </Typography>
                    </ListItem>
                </List>

                <Typography variant="body1" paragraph>
                    <strong>Questions or concerns?</strong> Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at{' '}
                    <Link href="mailto:official@anthrasync.com" color="primary">
                        official@anthrasync.com
                    </Link>.
                </Typography>
            </Paper>

           
            <Box mb={4}>
                <Typography variant="h5" component="h2" gutterBottom>
                    SUMMARY OF KEY POINTS
                </Typography>
                <Typography variant="body1" paragraph>
                    This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="What personal information do we process?"
                            secondary="When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. "
                        />
                        <Link href="#section-0" color="primary">
                            Learn more
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Do we process any sensitive personal information?"
                            secondary="Some of the information may be considered 'special' or 'sensitive' in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs."
                        />
                        <Link href="#sensitive-info" color="primary">
                            Learn more
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Do we collect any information from third parties?"
                            secondary="We may collect information from public databases, marketing partners, social media platforms, and other outside sources."
                        />
                        <Link href="#third-party-info" color="primary">
                            Learn more
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="How do we process your information?"
                            secondary="We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law."
                        />
                        <Link href="#section-1" color="primary">
                            Learn more
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="In what situations and with which parties do we share personal information?"
                            secondary="We may share information in specific situations and with specific third parties."
                        />
                        <Link href="#section-2" color="primary">
                            Learn more
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="How do we keep your information safe?"
                            secondary="We have adequate organisational and technical processes and procedures in place to protect your personal information."
                        />
                        <Link href="#section-8" color="primary">
                            Learn more
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="What are your rights?"
                            secondary="Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information."
                        />
                        <Link href="#section-9" color="primary">
                            Learn more
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="How do you exercise your rights?"
                            secondary="The easiest way to exercise your rights is by visiting https://www.anthrasync.com/UserProfile/, or by contacting us."
                        />
                        {/* <Link href="/privacy-policy#exercise-rights" color="primary">
                            Learn more
                        </Link> */}
                    </ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    Want to learn more about what we do with any information we collect? Review the Privacy Notice in full.
                </Typography>
            </Box>
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Table of Contents
                </Typography>
                <List>
                    {[
                        "1. What Information Do We Collect?",
                        "2. How Do We Process Your Information?",
                        "3. When And With Whom Do We Share Your Personal Information?",
                        "4. Do We Use Cookies And Other Tracking Technologies?",
                        "5. Do We Offer Artificial Intelligence-Based Products?",
                        "6. How Do We Handle Your Social Logins?",
                        "7. How Long Do We Keep Your Information?",
                        "8. How Do We Keep Your Information Safe?",
                        "9. What Are Your Privacy Rights?",
                        "10. Controls For Do-Not-Track Features",
                        "11. Data Loss, Hack, Or Breach",
                        "12. Hacking Or Personal Information Leaks",
                        "13. Sharing Of Personal Or Sensitive Information",
                        "14. Fake Data Or Content",
                        "15. Protection Of Other Users' Or Company's Sensitive Data",
                        "16. No Defamation Or Negative Campaigning",
                        "17. Do We Make Updates To This Notice?",
                        "18. How Can You Contact Us About This Notice?",
                        "19. How Can You Review, Update, Or Delete The Data We Collect From You?"
                    ].map((item, index) => (
                        <ListItem key={index}>
                            <Link href={`#section-${index}`} color="primary">
                                <ListItemText primary={item} />
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Paper>

            {/* Information Collection Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-0" gutterBottom>
                    1. WHAT INFORMATION DO WE COLLECT?
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>Personal Information You Disclose to Us:</strong> 
                    <br />
                    We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>Personal Information Provided by You:</strong> 
                    <br />
                    The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemText primary="Names" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Phone numbers" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Email addresses" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Mailing addresses" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Job titles" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Usernames" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Passwords" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Contact preferences" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Billing addresses" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Debit/credit card numbers" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Language preferences" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Social media profile links" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Aadhar number" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="PAN number" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Bank account details" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Date of birth" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Gender" />
                    </ListItem>
                </List>

                <Typography id="sensitive-info" variant="body1" paragraph>
                    <strong>Sensitive Information:</strong> 
                    <br />
                    When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemText primary="Financial data" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Social security numbers or other government identifiers" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Aadhar number" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="PAN number" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Bank account number" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Bank account IFSC code" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Date of birth" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Gender" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Student data" />
                    </ListItem>
                </List>

                <Typography variant="body1" paragraph>
                    <strong>Payment Data:</strong>
                    <br />
                    We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Razorpay. You may find their privacy notice link(s) here:{' '}
                    <Link href="https://razorpay.com/privacy/" target="_blank" color="primary">
                        https://razorpay.com/privacy/
                    </Link>.
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>Social Media Login Data:</strong>
                    <br />
                    We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect certain profile information about you from the social media provider, as described in the section called "How Do We Handle Your Social Logins?" below.
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</strong>
                </Typography>

                <Divider sx={{ marginY: 3 }} />

                <Typography variant="body1" paragraph>
  <strong>Google API:</strong>
  <br />
  Our use of information received from Google APIs will adhere to <a
  href="https://developers.google.com/terms/api-services-user-data-policy"
  target="_blank"
  rel="noopener noreferrer"
  style={{
    color: '#00bcd4', // Bright cyan or any color that contrasts well with dark backgrounds
    textDecoration: 'underline', // Optional: underline the link
  }}
>
  Google API Services User Data Policy
</a>
, including the <a
  href="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
  target="_blank"
  rel="noopener noreferrer"
  style={{
    color: '#00bcd4',  // Bright cyan color for visibility on dark background
    textDecoration: 'underline',  // Optional: underline the link
  }}
>
  Limited Use requirements
</a>
.
</Typography>


                <Typography id="third-party-info"  variant="body1" paragraph>
                    <strong>Information Collected from Other Sources:</strong> 
                    <br />
                    In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, social media platforms, and from other third parties.
                </Typography>

                <List>
                    <ListItem>
                        <ListItemText primary="Mailing addresses" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Job titles" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Email addresses" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Phone numbers" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Intent data (or user behaviour data)" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Internet Protocol (IP) addresses" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Social media profiles" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Custom profiles" />
                    </ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    <br />
                    If you interact with us on a social media platform using your social media account (e.g. Facebook or X), we receive personal information about you from such platforms such as your name, email address, and gender. You may have the right to withdraw your consent to processing your personal information. Learn more about withdrawing your consent. Any personal information that we collect from your social media account depends on your social media account's privacy settings. Please note that their own use of your information is not governed by this Privacy Notice.
                </Typography>
            </Paper>

             {/* How Do We Process Your Information Section */}
             <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-1" gutterBottom>
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                </Typography>

                <Typography variant="body1" paragraph>
                    We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemText primary="To facilitate account creation and authentication and otherwise manage user accountsWe may process your information so you can create and log in to your account, as well as keep your account in working order.." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To deliver and facilitate delivery of services to the user.We may process your information to provide you with the requested service." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To respond to user inquiries/offer support to users.We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To send administrative information to you.We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To fulfil and manage your orders. We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To enable user-to-user communications.We may process your information if you choose to use any of our offerings that allow for communication with another user." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To request feedback.We may process your information when necessary to request feedback and to contact you about your use of our Services." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To send you marketing and promotional communications.We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see 'WHAT ARE YOUR PRIVACY RIGHTS?' below." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To deliver targeted advertising to you.We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more. For more information see our Cookie Notice: https://www.anthrasync.com/Cookies_policy.html." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To post testimonials.We post testimonials on our Services that may contain personal information." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To protect our Services.We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To evaluate and improve our Services, products, marketing, and your experience.We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To identify usage trends.We may process information about how you use our Services to better understand how they are being used so we can improve them." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To determine the effectiveness of our marketing and promotional campaigns.We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="To comply with our legal obligations.We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights." />
                    </ListItem>
                </List>

                <Typography variant="body1" paragraph>
                    For more information on how we use your data, you can refer to the Cookie Policy here: 
                    <Link href="/cookies-policy" target="_blank" color="primary">
                        Cookie Policy
                    </Link>
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>
             {/* When And With Whom Do We Share Your Personal Information Section */}
             <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography  variant="h5" component="h2" id="section-2" gutterBottom>
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.
                </Typography>

                <Typography variant="body1" paragraph>
                    We may need to share your personal information in the following situations:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemText primary="Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="When we use Google Maps Platform APIs: We may share your information with certain Google Maps Platform APIs (e.g. Google Maps API, Places API). Google Maps uses GPS, Wi-Fi, and cell towers to estimate your location. GPS is accurate to about 20 meters, while Wi-Fi and cell towers help improve accuracy when GPS signals are weak, like indoors. This data helps Google Maps provide directions, but it is not always perfectly precise." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Other Users: When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile." />
                    </ListItem>
                </List>

                <Divider sx={{ marginY: 3 }} />
            </Paper>
             {/* Do We Use Cookies and Other Tracking Technologies Section */}
             <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-4" gutterBottom>
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.
                </Typography>

                <Typography variant="body1" paragraph>
                    We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
                </Typography>

                <Typography variant="body1" paragraph>
                    We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests, which may appear either on our Services or on other websites.
                </Typography>

                <Typography variant="body1" paragraph>
                    Specific information about how we use such technologies and how you can refuse certain cookies is set out in our <Link href="/cookies-policy" target="_blank" color="primary">Cookie Notice</Link>.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* Do We Offer Artificial Intelligence-Based Products Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-5" gutterBottom>
                    5. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>In Short:</strong> We offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies.
                </Typography>

                <Typography variant="body1" paragraph>
                    As part of our Services, we offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies (collectively, 'AI Products'). These tools are designed to enhance your experience and provide you with innovative solutions. The terms in this Privacy Notice govern your use of the AI Products within our Services.
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>Our AI Products</strong>
                </Typography>
                <List>
                    {[
                        "AI applications",
                        "AI bots",
                        "AI translation",
                        "Machine learning models",
                        "Natural language processing",
                        "AI automation",
                        "Text analysis",
                        "AI insights",
                        "AI deployment",
                        "AI development",
                        "AI research",
                        "AI search",
                        "AI predictive analytics",
                        "AI document generation"
                    ].map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={item} />
                        </ListItem>
                    ))}
                </List>

                <Typography variant="body1" paragraph>
                    <strong>How We Process Your Data Using AI</strong>
                </Typography>
                <Typography variant="body1" paragraph>
                    All personal information processed using our AI Products is handled in line with our Privacy Notice and our agreement with third parties. This ensures high security and safeguards your personal information throughout the process, giving you peace of mind about your data's safety.
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>How to Opt Out</strong>
                </Typography>
                <Typography variant="body1" paragraph>
                    We believe in giving you the power to decide how your data is used. To opt out, you can:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Log in to your account settings and update your user account" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Contact us using the contact information provided" />
                    </ListItem>
                </List>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* How Do We Handle Your Social Logins Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-6" gutterBottom>
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>In Short:</strong> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.
                </Typography>

                <Typography variant="body1" paragraph>
                    Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
                </Typography>

                <Typography variant="body1" paragraph>
                    We will use the information we receive only for the purposes that are described in this Privacy Notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

             {/* How Long Do We Keep Your Information Section */}
             <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-7" gutterBottom>
                    7. HOW LONG DO WE KEEP YOUR INFORMATION?
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>In Short:</strong> We keep your information for as long as necessary to fulfil the purposes outlined in this Privacy Notice unless otherwise required by law.
                </Typography>

                <Typography variant="body1" paragraph>
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than three (3) months past the termination of the user's account.
                </Typography>

                <Typography variant="body1" paragraph>
                    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* How Do We Keep Your Information Safe Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-8" gutterBottom>
                    8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>In Short:</strong> We aim to protect your personal information through a system of organisational and technical security measures.
                </Typography>

                <Typography variant="body1" paragraph>
                    We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* What Are Your Privacy Rights Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-9" gutterBottom>
                    9. WHAT ARE YOUR PRIVACY RIGHTS?
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>In Short:</strong> You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the contact details provided in the section <strong>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' </strong> below.
                </Typography>

                <Typography variant="body1" paragraph>
                    However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>Opting out of marketing and promotional communications:</strong> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying 'STOP' or 'UNSUBSCRIBE' to the SMS messages that we send, or by contacting us using the details provided in the section <strong>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' </strong> below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>Account Information:</strong> If you would at any time like to review or change the information in your account or terminate your account, you can:
                </Typography>

                <Typography variant="body1" paragraph>
                    - Log in to your account settings and update your user account.
                </Typography>
                <Typography variant="body1" paragraph>
                    - Contact us using the contact information provided.
                </Typography>

                <Typography variant="body1" paragraph>
                    Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                </Typography>

                <Typography variant="body1" paragraph>
                    <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. For further information, please see our <a
  href="/cookies-policy"
  target="_blank"
  rel="noopener noreferrer"
  style={{
    color: '#00bcd4',  // Bright cyan color for visibility on dark background
    textDecoration: 'underline',  // Optional: underline the link
  }}
>
  Cookie Notice
</a>
.
                </Typography>

                <Typography variant="body1" paragraph>
                    If you have questions or comments about your privacy rights, you may email us at <a
  href="mailto:official@anthrasync.com"
  style={{
    color: '#00bcd4', // A bright color like cyan
    textDecoration: 'underline', // Optional: underline the link
  }}
>
official@anthrasync.com
</a>
.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

             {/* 10. CONTROLS FOR DO-NOT-TRACK FEATURES Section */}
             <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-10" gutterBottom>
                    10. CONTROLS FOR DO-NOT-TRACK FEATURES
                </Typography>

                <Typography variant="body1" paragraph>
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. 
                </Typography>

                <Typography variant="body1" paragraph>
                    At this stage, no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* 11. DATA LOSS, HACK, OR BREACH Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-11" gutterBottom>
                    11. DATA LOSS, HACK, OR BREACH
                </Typography>

                <Typography variant="body1" paragraph>
                    In the event of a data loss, hack, or breach caused by severe or critical factors beyond the company’s control, the company will not be held responsible or liable for any damage or loss of user data. While the company will take reasonable steps to protect user information, users acknowledge and accept that no system is entirely immune to such incidents.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* 12. HACKING OR PERSONAL INFORMATION LEAKS Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-12" gutterBottom>
                    12. HACKING OR PERSONAL INFORMATION LEAKS
                </Typography>

                <Typography variant="body1" paragraph>
                    The company is not responsible for any unauthorized access to a user’s account, nor for any personal or sensitive information that may be leaked due to unavoidable circumstances, including but not limited to hacking, phishing, or other forms of cyberattacks. Users are encouraged to take necessary precautions to protect their login credentials and personal information.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>


            {/* 13. SHARING OF PERSONAL OR SENSITIVE INFORMATION Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-13" gutterBottom>
                    13. SHARING OF PERSONAL OR SENSITIVE INFORMATION
                </Typography>

                <Typography variant="body1" paragraph>
                    The company will not be held responsible for any personal or sensitive information that a user voluntarily shares with other users through the platform. Users acknowledge that sharing such information is done at their own risk.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* 14. FAKE DATA OR CONTENT Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-14" gutterBottom>
                    14. FAKE DATA OR CONTENT
                </Typography>

                <Typography variant="body1" paragraph>
                    Users agree not to submit fake data or post false or misleading content on the platform. The company reserves the right to take appropriate actions against users who violate this policy, including but not limited to account suspension or termination.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* 15. PROTECTION OF OTHER USERS' OR COMPANY'S SENSITIVE DATA Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-15" gutterBottom>
                    15. PROTECTION OF OTHER USERS' OR COMPANY'S SENSITIVE DATA
                </Typography>

                <Typography variant="body1" paragraph>
                    Users must respect the privacy of other users and the company by not leaking or disclosing personal, sensitive, or confidential information about other users or the company’s internal data. Any violation of this policy may lead to immediate account suspension and potential legal action.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* 16. NO DEFAMATION OR NEGATIVE CAMPAIGNING Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-16" gutterBottom>
                    16. NO DEFAMATION OR NEGATIVE CAMPAIGNING
                </Typography>

                <Typography variant="body1" paragraph>
                    Users agree not to blame, defame, or spread false or misleading information about the company, its services, or its employees. Users will refrain from engaging in any activities, whether online or offline, that are intended to harm the company’s reputation or operations. Any such actions may result in legal consequences and the suspension or termination of the user’s account.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

             {/* 17. DO WE MAKE UPDATES TO THIS NOTICE Section */}
             <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-17" gutterBottom>
                    17. DO WE MAKE UPDATES TO THIS NOTICE?
                </Typography>

                <Typography variant="body1" paragraph>
                    In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </Typography>

                <Typography variant="body1" paragraph>
                    We may update this Privacy Notice from time to time. The updated version will be indicated by an updated 'Revised' date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.
                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* 18. HOW CAN YOU CONTACT US ABOUT THIS NOTICE Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-18" gutterBottom>
                    18. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </Typography>

                <Typography variant="body1" paragraph>
                    If you have questions or comments about this notice, you may email us at <a
  href="mailto:official@anthrasync.com"
  style={{
    color: '#00bcd4', // A bright color like cyan
    textDecoration: 'underline', // Optional: underline the link
  }}
>
  official@anthrasync.com
</a>
 or contact us by post at:
                </Typography>

                <Box sx={{ paddingLeft: 2 }}>
                    <Typography variant="body1" paragraph>
                        ANTHRASYNC SOLUTIONS PRIVATE LIMITED
                    </Typography>
                    <Typography variant="body1" paragraph>
                        No 26, Laskar Hosur Road, Adugodi, Bangalore South
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Bengaluru, Karnataka 560030
                    </Typography>
                    <Typography variant="body1" paragraph>
                        India
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 3 }} />
            </Paper>

            {/* 19. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU Section */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                <Typography variant="h5" component="h2" id="section-19" gutterBottom>
                    19. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </Typography>

                <Typography variant="body1" paragraph>
                    Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please visit: 
                    <a
  href={`/UserProfile/${userId}`}
  target="_blank"
  rel="noopener noreferrer"
  style={{
    color: '#00bcd4', // A bright color like cyan or any color that stands out
    textDecoration: 'underline', // Optional: underline the link
  }}
>
  https://www.anthrasync.com/UserProfile/
</a>

                </Typography>

                <Divider sx={{ marginY: 3 }} />
            </Paper>
        </Container>
        <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
        <Footer2/>

        </div>
    );
};

export default PrivacyPolicy;
