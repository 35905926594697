import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, List, ListItem, ListItemText, Divider, IconButton } from '@mui/material';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


function PaymentsPolicy({userId,loggedIn, onLogout}) {
   const navigate = useNavigate();
      const scrollToTop = () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        };
  
      const handleLogout = () => {
          onLogout(); // Call the passed logout function
          navigate('/login'); // Navigate to login page after logout
        };
  return (
    <div>{loggedIn ? (
      <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
    ) : (
      <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
    )}
    <Container maxWidth="md" sx={{ padding: '20px', mt: 10 }}>
  <Typography variant="h3" textAlign={'center'} gutterBottom>
    Payments Policy for Verified Users
  </Typography>

  <Typography variant="body1" paragraph>
    This Payments Policy outlines the payment procedure and terms for verified users. Please read the guidelines carefully. By using the platform and earning payments, you agree to these terms.
  </Typography>

  <Typography variant="h5" gutterBottom>
    Payment Guidelines:
  </Typography>

  <List>
    <ListItem>
      <ListItemText primary="1. Amount earned after conducting a knowledge sharing session will be displayed in the Analytics section of the user portal." />
    </ListItem>
    <ListItem>
      <ListItemText primary="2. Amount earned from conducting multiple sessions will be added and displayed in the Analytics section of the user portal." />
    </ListItem>
    <ListItem>
      <ListItemText primary="3. After the amount is credited for a particular user, the amount payable will be reset to zero." />
    </ListItem>
    <ListItem>
      <ListItemText primary="4. All the amount earned by verified user will be credited to his/her account in the latest billing and payment cycle." />
    </ListItem>
    <ListItem>
      <ListItemText primary="5. The billing and payment cycle will occur between the 21st and the last date of every month." />
    </ListItem>
    <ListItem>
      <ListItemText primary="6. The company holds the right to delay payments due to unavoidable situations. The user will not take any action against the company." />
    </ListItem>
    <ListItem>
      <ListItemText primary="7. In case of any disputes, the final decision will be taken by the company. Disputes must be reported within 30 days of the issue." />
    </ListItem>
    <ListItem>
      <ListItemText primary="8. The amount will be directly credited to the user's bank account that he/she has submitted during the verification." />
    </ListItem>
    <ListItem>
      <ListItemText primary="9. The user can contact us if they have any queries related to payments. We encourage prompt communication for swift resolutions." />
    </ListItem>
    <ListItem>
      <ListItemText primary="10. If a user’s bank details are found to be incorrect, the payment will be delayed until the user provides the correct information." />
    </ListItem>
    <ListItem>
      <ListItemText primary="11. In the event that the payment date falls on a weekend or public holiday, payments will be processed on the next business day." />
    </ListItem>
    <ListItem>
      <ListItemText primary="12. The company reserves the right to perform audits on the earnings and payments of the users to ensure compliance with the platform's guidelines." />
    </ListItem>
  </List>

  <Divider sx={{ margin: '20px 0' }} />
  {/* New Section: Service Charges */}
 
  <Typography variant="h5" gutterBottom>
    Service Charges and Deductions:
  </Typography>
  <Typography variant="body1" paragraph>
    The company will deduct standard service charges from every payment earned by the verified user. The amount payable to the verified user will be the earnings after deducting these service charges. These charges are subject to change from time to time based on various factors such as the company's profit and market conditions.
  </Typography>
  <Typography variant="body1" paragraph>
    By using the platform and services, you acknowledge and agree that the company has the right to adjust and vary the service charges as deemed necessary. The user understands that these service charges are a part of the platform's operational costs and that the amount received will always be the net amount after deduction of such charges. 
  </Typography>
  <Typography variant="body1" paragraph>
    The user agrees to these deductions and waives any claims or grievances regarding the service charges. The company will not be held liable for any dissatisfaction regarding the deductions. These service charges are designed to cover the costs of providing the platform's services and will vary as per the business requirements and profit considerations of the company.
  </Typography>

  <Divider sx={{ margin: '20px 0' }} />

  <Typography variant="body1" paragraph>
    We encourage all users to reach out if they have any questions or concerns regarding payments. Our support team is always available to assist with any payment-related issues. Transparency is key, and we are committed to ensuring that all users have a smooth experience when it comes to earnings and payouts.
  </Typography>

  <Typography variant="h5" gutterBottom>
    Frequently Asked Questions (FAQs):
  </Typography>

  <Typography variant="h6" sx={{ marginTop: 2 }}>
    1. How are payments calculated?
  </Typography>
  <Typography variant="body1">
    Payments are based on the earnings generated by users through conducting knowledge-sharing sessions. The total amount earned from these sessions is accumulated and displayed in the user portal's Analytics section. Payments are calculated based on this accumulated amount and are credited according to the scheduled payout date.
  </Typography>

  <Typography variant="h6" sx={{ marginTop: 2 }}>
    2. What happens if the payment is delayed?
  </Typography>
  <Typography variant="body1">
    In certain unavoidable situations, such as technical issues or external payment service delays, payments may be delayed. In such cases, the company will inform the user in advance and ensure that the payment is processed as soon as possible. Please note that the user agrees not to take any legal action against the company in the event of payment delays.
  </Typography>

  <Typography variant="h6" sx={{ marginTop: 2 }}>
    3. Can I change my bank account details after verification?
  </Typography>
  <Typography variant="body1">
    If you need to update your bank account information after verification, you will need to submit a request to our support team. They will guide you through the process, and your new details will be used for future payments.
  </Typography>

  <Typography variant="h6" sx={{ marginTop: 2 }}>
    4. What happens if I don’t receive my payment on time?
  </Typography>
  <Typography variant="body1">
    If you haven't received your payment by the expected date, please contact us immediately. We will investigate the issue and ensure the payment is processed. Please keep in mind that payments might be delayed due to external factors such as bank processing times or weekends.
  </Typography>

  <Typography variant="body1" sx={{ marginTop: 2 }} paragraph>
    By continuing to use our platform and services, you acknowledge that you understand and agree to comply with all the policies outlined above. We encourage all users to reach out if they have any questions or concerns regarding payments.
  </Typography>

  <Divider sx={{ margin: '20px 0' }} />


  <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 'bold', color: '#FFFFFF' }}>
    CONTACT US
  </Typography>

  <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
    In you have any questions or queries, please contact us at:
  </Typography>

  <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
    <strong>ANTHRASYNC SOLUTIONS PRIVATE LIMITED</strong>
  </Typography>

  <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
    No 26, Laskar Hosur Road, Adugodi, Bangalore South
  </Typography>

  <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
    Bangalore, Karnataka 560030
  </Typography>

  <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
    India
  </Typography>

  <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
    <strong>Email: </strong> official@anthrasync.com
  </Typography>

  <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
    <strong>Phone: </strong> +91-XXXXXXXXXX
  </Typography>
  
  <Typography sx={{ color: '#595959', fontSize: '14px', marginTop: 2 }}>
    <strong>Business Hours: </strong> Monday to Friday, 10 AM - 7 PM IST
  </Typography>

</Container>

    <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
   <Footer2/>

    </div>
  );
}

export default PaymentsPolicy;
