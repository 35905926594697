import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Typography, Container, Box, Alert } from "@mui/material";
import Footer2 from '../Footer/Footer2';
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';

const API_URL = process.env.REACT_APP_API_URL;
const PasswordReset = () => {
    const { uidb64, token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // You can validate the token here by hitting an API endpoint to check if it’s valid
        axios.post(`${API_URL}/check_token/`, { uidb64, token })
            .then(response => {
                if (!response.data.valid) {
                    setErrorMessage('Invalid or expired token.');
                }
            })
            .catch(error => {
                setErrorMessage('Error validating token.');
            });
    }, [uidb64, token]);

    const handleResetPassword = () => {
        if (password === confirmPassword) {
            axios.post(`${API_URL}/reset_password/`, { uidb64, token, password })
                .then(response => {
                    // Handle successful password reset
                    window.location.href = '/login';  // Redirect to login after reset
                })
                .catch(error => {
                    setErrorMessage('Error resetting password.');
                });
        } else {
            setErrorMessage('Passwords do not match.');
        }
    };

    return (
        <div>
            <Link to="/home">
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',  // Center the logo horizontally
                alignItems: 'center',      // Center the logo vertically (optional)
                width: '100%',
                height: '100%',            // Ensure it takes up full height of the container (optional)
                padding: 2,                // Optional: adds padding around the logo
            }}
        >
            <img
                src={Logo}
                alt="Logo"
                style={{
                    width: '100%',           // Makes the logo responsive to container width
                    maxWidth: '400px',       // Limits the maximum size of the logo
                    height: 'auto',          // Maintains aspect ratio of the image
                }}
            />
        </Box>
              </Link>
            <Container maxWidth="xs">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "#2222",  // Dark background
                    marginTop: 10,
                    marginBottom: 10,
                    color: "#fff",  // White text color for dark theme
                }}
            >
                <Typography
    variant="h4"
    sx={{
        fontWeight: "bold",
        marginBottom: 2,
        fontSize: {
            xs: '1.5rem', // Font size for extra small screens (phones)
            sm: '2rem',   // Font size for small screens (tablets)
            md: '2rem', // Font size for medium screens (small laptops)
            lg: '2rem',   // Font size for large screens (desktops)
        },
    }}
>
    Reset Your Password
</Typography>


                {errorMessage && (
                    <Alert severity="error" sx={{ marginBottom: 2, backgroundColor: "#d32f2f", color: "#fff" }}>
                        {errorMessage}
                    </Alert>
                )}

                <TextField
                    label="New Password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    sx={{
                        marginBottom: 2,
                        "& .MuiInputBase-root": {
                            backgroundColor: "#3333",  // Dark background for inputs
                        },
                        "& .MuiInputLabel-root": {
                            color: "#fff",  // White label color
                        },
                        "& .MuiOutlinedInput-root": {
                            color: "#fff",  // White text inside input
                            "& fieldset": {
                                borderColor: "#fff",  // White border color
                            },
                            "&:hover fieldset": {
                                borderColor: "#90caf9",  // Light blue hover effect
                            },
                        },
                    }}
                    required
                />

                <TextField
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    sx={{
                        marginBottom: 3,
                        "& .MuiInputBase-root": {
                            backgroundColor: "#3333",  // Dark background for inputs
                        },
                        "& .MuiInputLabel-root": {
                            color: "#fff",  // White label color
                        },
                        "& .MuiOutlinedInput-root": {
                            color: "#fff",  // White text inside input
                            "& fieldset": {
                                borderColor: "#fff",  // White border color
                            },
                            "&:hover fieldset": {
                                borderColor: "#90caf9",  // Light blue hover effect
                            },
                        },
                    }}
                    required
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleResetPassword}
                    fullWidth
                    sx={{
                        padding: "10px 0",
                        fontWeight: "bold",
                        letterSpacing: 1.2,
                        borderRadius: 20,
                        backgroundColor: "#90caf9",  // Light blue button color
                        "&:hover": {
                            backgroundColor: "#1E90FF",  // Lighter blue on hover
                        },
                    }}
                >
                    Reset Password
                </Button>
            </Box>
        </Container>
        <Footer2/>
        </div>
    );
};

export default PasswordReset;
