import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper, Typography, Button, Divider, Avatar, Grid, CircularProgress } from '@mui/material';
import { ArrowBack, PersonOutline } from '@mui/icons-material';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';

function ShowBookedUsers({ userId, onLogout }) {
  const location = useLocation(); // Access location object
  const { bookedUsers } = location.state || {}; // Retrieve the bookedUsers from state
  const navigate = useNavigate(); 

  // If no booked users are present, show a message and a button to go back
  if (!bookedUsers || bookedUsers.length === 0) {
    return (
      <Paper sx={{ padding: '2rem', textAlign: 'center', backgroundColor: '#000000', height: '100vh' }}>
        <Typography variant="h5" color="error" sx={{ marginBottom: '1rem', color: '#FF6347' }}>
          No booked users found.
        </Typography>
        <Button variant="contained" color="primary" href="/dashboard" sx={{ borderRadius: '30px' }}>
          Back to Dashboard
        </Button>
      </Paper>
    );
  }


  const handleClick = () => {
    navigate(`/platform/${userId}`)
  }

  return (
    <Paper sx={{ padding: '2rem', backgroundColor: '#000000', minHeight: '100vh' }}>
      <Header1 userId={userId} onLogout={onLogout} />

      <Paper
        sx={{
          padding: '2rem',
          borderRadius: 4,
          boxShadow: 3,
          backgroundColor: '#1c1c1c',
          maxWidth: '1200px',
          margin: '2rem auto', // Margin added for spacing from top
          minHeight: '70vh',
        }}
      >
        <Button
          variant="text"
          color="primary"
          onClick={handleClick}
          sx={{
            marginBottom: '1.5rem',
            color: '#ffffff',
            textTransform: 'none',
            fontWeight: '500',
            '&:hover': { backgroundColor: 'transparent', color: '#90caf9' }, // Hover effect
          }}
        >
          <ArrowBack sx={{ marginRight: 1, color: '#ffffff' }} />
          Back to Dashboard
        </Button>

        <Typography
          variant="h4"
          sx={{
            marginBottom: '1.5rem',
            fontWeight: 700,
            color: '#ffffff',
            fontSize: { xs: '1.8rem', sm: '2.4rem' },
          }}
        >
          Booked Users
        </Typography>

        <Divider sx={{ marginBottom: '1.5rem', backgroundColor: '#333333' }} />

        {/* Grid Layout for User Cards */}
        <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
          {bookedUsers.map((user, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '1rem',
                  borderRadius: '12px',
                  backgroundColor: '#2c2c2c',
                  boxShadow: 2,
                  transition: 'all 0.3s ease', // Smooth transition on hover
                  '&:hover': {
                    backgroundColor: '#444444', // Lighten on hover
                    boxShadow: 6,
                    transform: 'scale(1.02)', // Slight scale effect on hover
                  },
                }}
              >
                <Avatar sx={{ width: 60, height: 60, marginRight: 1 }}>
                  <PersonOutline sx={{ color: '#ffffff' }} />
                </Avatar>

                <Typography
                  variant="body1"
                  sx={{
                    color: '#e0e0e0',
                    fontSize: '1.2rem',
                    fontWeight: '500',
                    letterSpacing: '0.5px',
                    overflowWrap: 'break-word', // To handle long names gracefully
                  }}
                >
                  {user}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Footer2 />
    </Paper>
  );
}

export default ShowBookedUsers;
